import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { PcSpecsExclusionListEditDialogService } from './pc-specs-exclusion-list-edit-dialog.service';

@Component({
  selector: 'app-pc-specs-exclusion-list-edit-dialog',
  templateUrl: './pc-specs-exclusion-list-edit-dialog.component.html',
  styleUrls: ['./pc-specs-exclusion-list-edit-dialog.component.css']
})
export class PcSpecsExclusionListEditDialogComponent implements OnInit {

  [x: string]: any;
  //to main page
  message = "Records updated successfully";
  errorMessage = "";

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //ngModelValues
  ePcSpecPosition: any;
  eHikiateStatus: any;
  eEffectiveFrom: any;
  eEffectiveTo: any;
  eComments: any;
  epc_spec_excl_set_id:any;
  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<PcSpecsExclusionListEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public PcSpecsExclusionListEditDialogService: PcSpecsExclusionListEditDialogService,
    private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.info("data inside edit", this.data);
    if (this.data.hikiateStatus==='N') {
      this.eHikiateStatus = false
    }
    if (this.data.hikiateStatus==='Y') {
      this.eHikiateStatus = true
    }

    this.ePcSpecPosition = this.data.pcSpecPosition;
    this.eEffectiveFrom = this.data.effectiveFrom;
    this.eEffectiveTo = this.data.effectiveTo;
    this.eComments = this.data.comments;
    this.epc_spec_excl_set_id=this.data.pc_spec_excl_set_id;
  }
  closeAlert() {
    this.errorMessage = "";
    this.successMessage = "";
  }
  save() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId');
    console.info(this.eEffectiveFrom);

    if (this.eEffectiveFrom && this.eEffectiveTo &&
      this.pipe.transform(this.eEffectiveFrom, 'yyyy-MM-dd') >= this.pipe.transform(this.eEffectiveTo, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective from Date should be greater than or equal to Effective to Date";
    }
    else {
      if (this.eEffectiveTo==="") {
        this.eEffectiveTo = "9999-12-31"
      }
      if(this.eComments===""){
        this.eComments =" "
      }
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.eEffectiveFrom !=="") {
        const d1 = moment(this.eEffectiveFrom).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveFrom = date
      }
      if (this.eEffectiveTo !=="") {
        const d1 = moment(this.eEffectiveTo).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eEffectiveTo = date
      }
      let cmts;
    if (this.eComments!==undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
      const body = {
        'effDt': indateFormat,
        'exprDt': outdateFormat,
        'rmrkTxt': cmts,
        'pcSpecExclSetId': this.epc_spec_excl_set_id,
        "screenNo":"NBPC_2271",
        "fileName" :fileName
       
      }
      this.SpinnerService.show();
      this.PcSpecsExclusionListEditDialogService.editPcSpecsExclusion(body).subscribe(data => {
        console.info(data);
        this.response = data.payload;
        this.SpinnerService.hide();
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          // this.dialogRef.close(this.response[0].result);
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        console.info(err);
        this.SpinnerService.hide();
      });
    }

  }
  cancel() {

    this.message = "";

    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='eEffectiveFrom') {
      this.eEffectiveFrom = "";
    } else if (name==='eEffectiveTo') {
      this.eEffectiveTo = "";
    }
  }
}
