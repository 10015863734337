import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders,HttpParams } from '@angular/common/http';
import { BehaviorSubject,Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})

export class MngDataTransSapService {

  
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

 namc = 'getNamc'
 ebomToSAPTransmissionType = 'getEbomToSAPTransmissionType'
 gridData = '/getMngDataTrnsfrGrid'
 
  constructor(private http: HttpClient) { }

  //namc
  getNAMC(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdownUrlApi}/${this.namc}`+'?screenNo=NBPC_9997'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //grid data
  getMngDataTrnsfrGrid(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.MastergridapiUrl + this.gridData + '?namcLglEntityId=' + gridData.namc
    + '&transTyp=' + gridData.ebomToSAPTransmissionType + '&strtDtTime=' + gridData.sendToSAPStartDate
    + '&endDtTime=' + gridData.sendToSAPEndDate + '&comments=' + gridData.comments+'&screenNo=NBPC_9997'+'&fileName=' +fileName
    + '&option=' + gridData.option ;
    return this.http.get(API_URL,httpOptions)
      .pipe(catchError(this.error)
      )
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }

}