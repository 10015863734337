import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ProdProcStatusService {

  BussinessDropdownApi = environment.BusinesssearchdropdownapiUrl;
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  modelNumber = '/getModelNumber';
  option = '/getOption';
  versionNm = 'getVersionNameBpPs';
  // repVersionNm='getRepVersionName';
  namc = 'getNamcBpPs';
  family = '/getFamilyBpPs';
  katashiki = '/getPpsKatashiki';
  katashikionchange = '/getPpsKatashikionchange';
  partnumonchange = '/getPpspartnumonchange';
  approvalStatus = 'getApprovalStatus';
  processedSapStat = 'getProcessedInSapStatus';
  manAdjsmntStatus = 'getManualAdjStatus';
  prodProcStatGrid = '/getProdPrcsgStsGrid';
  processingStatus = '/getProcessingStatus'
  updactProdPriority = '/updateActProdPriority'
  getsystemParam = '/getsystemParamData'

  constructor(private http: HttpClient) { }

  //grid data
  getGridDetails(grid): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.prodProcStatGrid
    return this.http.post(API_URL, grid,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //model number
  getModelNumber(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let SearchParams = new HttpParams().set('screen', 'vehicle')
    const API_URL = this.searchdropdownUrlApi + this.modelNumber + '?screen=' + 'vehicle'+'&screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //option
  getOption(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let SearchParams = new HttpParams().set('screen', 'vehicle')
    const API_URL = this.searchdropdownUrlApi + this.option + '?screen=' + 'vehicle'+'&screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //versionNm
  getVersionNm(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.BussinessDropdownApi}/${this.versionNm}`+'?screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }



  //namc
  getNamc(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.BussinessDropdownApi}/${this.namc}`+'?screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family
  getFamily(namc): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namc);
    const API_URL = this.BussinessDropdownApi + this.family + '?namcId=' + namc+'&screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //katashiki
  getKatashiki(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.katashiki+'?screenNo=NBPC_10115'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  monthlyDataDownload(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.post(environment.BussinessppsvapiUrl + '/getmonthlyRepProd', body, httpOptions)
      .map((response: any) => response).catch(this.error)
  }

  //katashiki-onchange
  getKatashikionchange(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.katashikionchange
    return this.http.post(API_URL, body,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //partnum-onchange
  getPartnumonchange(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.partnumonchange
    return this.http.post(API_URL, body,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  versioncnt(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/' + 'getCount'
    return this.http.post(API_URL, {}, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //approvalStat
  // getApprovalStat(): Observable<any> {
  //   const API_URL = `${this.BussinessDropdownApi}/${this.approvalStatus}`;
  //   return this.http.get(API_URL,httpOptions)
  //     .pipe(
  //       catchError(this.error)
  //     )
  // }

  getApprovalStat(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.BussinessDropdownApi + '/' + this.approvalStatus + '?repBomVerId=' + ''+'&screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //procSapStatus
  getProcSapStatus(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.BussinessDropdownApi}/${this.processedSapStat}`+'?screenNo=NBPC_10115'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  getRepVerDetail(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = '/assets/generateRepDetail.json';
    return this.http.get(API_URL)
      .pipe(
        catchError(this.error)
      )
  }

  //procSapStatus
  getProcessingStatus(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.processingStatus+'?screenNo=NBPC_10115'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  updateActProdPriority(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.updactProdPriority
    return this.http.post(API_URL, body,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  getsysParam(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.getsystemParam+'?screenNo=NBPC_10115'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  getIdeDeptProdLst(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + '/getIdeDeptProdts';
    return this.http.post(API_URL, body,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  fileUpldData(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole'+'?screenNo=NBPC_10115'+'&fileName=' +fileName, httpOptions)
      .map((response: any) => response).catch(this.error)
  }

  
  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any;
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }

}
