import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { GlobalService } from '../global.service';
import { ProdProcStatusActionService } from './prod-proc-status-action.service';

@Component({
  selector: 'app-prod-proc-status-action',
  templateUrl: './prod-proc-status-action.component.html',
  styleUrls: ['./prod-proc-status-action.component.css']
})
export class ProdProcStatusActionComponent implements OnInit {
  buttonName: any;
  response: any
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ProductWithinVersion,
    public dialogRef: MatDialogRef<ProdProcStatusActionComponent>,
    private readonly SpinnerService: NgxSpinnerService,
    private prodprocstatsctService: ProdProcStatusActionService, public globalService: GlobalService) { }

  ngOnInit(): void {
    console.info("data", this.dialogData);

    this.buttonName = this.dialogData.buttonName
    if (this.buttonName==='sendtosap' || this.buttonName==='resetsendtosap') {
      this.chksapNamc();
    }
    else if (this.buttonName==='setHoldProc') {
      this.FetchSuspendedStatusCheck();
    }
    else if (this.buttonName==='genBomStruc') {
      this.fetchBomStruc();
      // this.trunctmptbl();
    }
  }



  chksapNamc() {
    const fileName= localStorage.getItem('Filename')
    this.SpinnerService.show();
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.checkSapNamc(body).subscribe(res => {
      console.info("res from sapnamc", res.payload);
      if (res.payload[0].response==='success') {
        this.verifyStatCheck()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function Failed');
      this.SpinnerService.hide()
    })
  }

  verifyStatCheck() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.verifyStatusCheck(body).subscribe(res => {
      console.info("res from verifystatus", res.payload);
      if (res.payload[0].response==='success') {
        if (this.buttonName==='sendtosap') {
          this.fetchsendtoSap()
        }
        if (this.buttonName==='resetsendtosap') {
          this.fetchresetSendtoSap()
        }

      }
      else {
        if (this.buttonName==='sendtosap') {
          this.dialogRef.close('Record status should not be Approved/Approved-Mfg/Mfg-Suspended');
        }
        if (this.buttonName==='resetsendtosap') {
          this.dialogRef.close('Record status should not be SendtoSap/SendtoSapWip/SendtoSapReady/SendtoSapVerify/DonotSendtoSap/SendtoSapReadyInv');
        }
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function Failed');
      this.SpinnerService.hide()
    })
  }

  fetchsendtoSap() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getSendtoSap(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].response==='success') {
        this.deleteRPExtn()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }
    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchresetSendtoSap() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,      
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getresetSendtoSap(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].response==='success') {
        this.deleteRPExtn()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }
    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  deleteRPExtn() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,     
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.delRptExtn(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].result==='success') {
        this.deleteRP()
      }
      else {
        this.deleteRP()
        // this.dialogRef.close(res.payload[0].result);
        // this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  deleteRP() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.delRpt(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].result==='success') {
        if (this.buttonName==='sendtosap') {
          this.updatesendtoSap()
        }
        if (this.buttonName==='resetsendtosap') {
          this.updateresetSendtoSap()
        }
      }
      else {
        if (this.buttonName==='sendtosap') {
          this.updatesendtoSap()
        }
        if (this.buttonName==='resetsendtosap') {
          this.updateresetSendtoSap()
        }
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  updatesendtoSap() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.updSendtoSap(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].response==='success') {
        this.dialogRef.close('success');
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  updateresetSendtoSap() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId')
    const body = {
      "mfrdProdId": this.dialogData.mfrdProdId,
      "screenNo":"NBPC_10115",
      "fileName" :fileName

    }
    this.prodprocstatsctService.updResetSendtoSap(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].response==='success') {
        this.dialogRef.close('success');
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  ///// Set to Hold Processing

  FetchSuspendedStatusCheck() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      "screenNo":"NBPC_10115",
      "fileName" :fileName

    }
    this.SpinnerService.show()
    this.prodprocstatsctService.getSuspendedStatusCheck(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].result==='success') {
        this.UpdateSetToHold();
      }
      else {
        this.dialogRef.close(res.payload[0].result);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  UpdateSetToHold() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.dialogData.bom_typ_cd)
    const user = localStorage.getItem('workDayId')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      'bom_typ_cd': this.dialogData.bom_typ_cd,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
      
    }
    this.prodprocstatsctService.editSetToHold(body).subscribe(res => {
      console.info("res from fetch sap data", res.payload);
      if (res.payload[0].result==='success') {
        this.dialogRef.close(res.payload[0].result);
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].result);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  ////gen bom structure

  trunctmptbl() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "table_name": 'EBOM_ADM.tmp_gen_bom_struc',
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.SpinnerService.show()
    this.globalService.truncTempRepProd(body).subscribe(data => {
      console.info(data);
      this.response = data.payload;
      if (this.response[0].result==='success') {
        // this.updatetProcProcessStatus()
        this.fetchBomStruc()
      } else {
        this.SpinnerService.hide();
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
      this.dialogRef.close("Function Failed");
    });
  }
  fetchBomStruc() {
    const fileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    const user = localStorage.getItem('workDayId');
    const body = {
      'mailId': mail,
      
      "prod_bom_id": this.dialogData.prod_bom_id,
      "bom_typ_cd": this.dialogData.bom_typ_cd,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.SpinnerService.show()
    this.prodprocstatsctService.getBomStructure(body).subscribe(res => {
      console.info("res in bom struc", res.payload);
      if (res.payload[0].response==='success') {
        this.dialogRef.close(res.payload[0].response);
        // this.fetchCostDetails();
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchCostDetails() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      "userId": user,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getCostDetails(body).subscribe(res=>{
      console.info("res in cost detail",res.payload);
      if (res.payload[0].response==='success') {
        this.fetchTmpDetails();
        // this.fetchStandardCost();
        // this.fetchTmpDetails()
        // this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchStandardCost() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getStandardCost(body).subscribe(res=>{
      console.info("res in standard cost",res.payload);
      if (res.payload[0].response==='success') {
        // this.dialogRef.close(res.payload[0].response);
        this.fetchActCostData();
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchActCostData() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getActCostData(body).subscribe(res => {
      console.info("res in actcost data", res.payload);
      if (res.payload[0].response==='success') {
        // this.dialogRef.close(res.payload[0].response);
        this.fetchTransferCost();
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchTransferCost() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "prod_bom_id": this.dialogData.prod_bom_id,
      "screenNo":"NBPC_10115",
      "fileName" :fileName

    }
    this.prodprocstatsctService.getTransferCost(body).subscribe(res=>{
      console.info("res in trnafer cost",res.payload);
      if (res.payload[0].response==='success') {
        // this.dialogRef.close(res.payload[0].response);
        this.fetchTmpDetails();
        this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  fetchTmpDetails(){
    const user = localStorage.getItem('workDayId')
    const fileName= localStorage.getItem('Filename')
    const body = {
      "userId": user,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.getTmpDetails(body).subscribe(res=>{
      console.info("res in fetch tmptbl",res.payload);
      if (res.payload) {
        this.dialogRef.close(res.payload);
        this.updateTmpTable();
        // this.SpinnerService.hide()
      }
      else {
        this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }

  updateTmpTable() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId')
    const body = {
      "userId": user,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    this.prodprocstatsctService.updTmpTable(body).subscribe(res=>{
      console.info("res in update tmptbl",res.payload);
      if (res.payload[0].response==='success') {
        // this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }
      else {
        // this.dialogRef.close(res.payload[0].response);
        this.SpinnerService.hide()
      }

    }, err => {
      this.dialogRef.close('Function failed');
      this.SpinnerService.hide()
    })
  }
}
