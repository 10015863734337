import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

import { FamilyProjectNamcAddDialogService } from './family-project-namc-add-dialog.service'



@Component({
  selector: 'app-family-project-namc-add-dialog',
  templateUrl: './family-project-namc-add-dialog.component.html',
  styleUrls: ['./family-project-namc-add-dialog.component.css']
})
export class FamilyProjectNamcAddDialogComponent implements OnInit {

  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Updated Successfully";
  index: any;
  errorMessage = "";


  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];

  //ngModelValues
  fFamily: any = '';
  fProject: any = '';
  fSsn: any = '';
  fNamc: any = '';
  fHikiate: any = '';
  fModelYear: any = '';
  fModelChangeType: any = '';
  fGeneration: any = '';
  fLineOffPlant: any = '';
  fProdDateIn = null;
  fProdDateOut = null;
  fEffDateIn = null;
  fEffDateOut = null;
  fComments: any = '';
  familyArray: any = []
  namcArray: any = []
  projectArray: any = []
  modelArray: any = []
  modelChngTypArray: any = []
  //response after insert in a record
  response: any;
  generationIdArray: any = []


  constructor(private famprojAddService: FamilyProjectNamcAddDialogService,
    private readonly SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<FamilyProjectNamcAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    console.info("inside ng oninit");

    this.getFamilyData();
    this.getProjectData();
    this.getModelData();
    this.getModelchngTypData();
    this.gethikiateData();
    this.getgenerationIdData();
  }
  gethikiateData() {
    this.famprojAddService.getHikiateStatusFpn().subscribe(data => {
      console.info(data);
      this.hikiateLstFromDB = data.payload;
    });
  }
  getNamcData() {
    if (this.fFamily===undefined) {
      this.fFamily = ""
    }
    this.famprojAddService.getNamcdropdown(this.fFamily).subscribe(res => {
      console.info("res from namc", res);
      this.SpinnerService.hide();
      this.namcArray = res.payload
    })
  }

  getFamilyData() {
    // console.info("namcId in fetch func", this.fNamc);

    this.famprojAddService.getFamilydropdown().subscribe(res => {
      console.info("res from family", res);
      this.familyArray = res.payload
    })
  }
  getProjectData() {
    this.famprojAddService.getProjectdropdown().subscribe(res => {
      console.info("res from project", res);
      this.projectArray = res.payload
    })
  }
  getModelData() {
    this.famprojAddService.getModeldropdown().subscribe(res => {
      console.info("res from model", res);
      this.modelArray = res.payload
    })
  }
  getModelchngTypData() {
    this.famprojAddService.getModelChngTypdropdown().subscribe(res => {
      console.info("res from modelchngTyp", res);
      this.modelChngTypArray = res.payload
    })
  }

  getgenerationIdData() {
    this.famprojAddService.getGenerationdropdown().subscribe(res => {
      console.info("res from generation", res);
      this.generationIdArray = res.payload
    })
  }





  //hikiate
  moveHikiForward(): void {
    const hikiatelst = this.hikiateLstFromDB
    console.info(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        if (hiki===hikiatelst[i].hikiate_stat_cd) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
    this.dummyHikiateLst = ""
    
  }
  moveHikiBackward(): void {
    this.dummyHikiateLstToDB.forEach(hiki => {
      const body = {
        "hikiate_stat_cd": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    console.info(this.hikiateLstFromDB)
    console.info(this.selectedHikiateLst);
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate_stat_cd > b.hikiate_stat_cd ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }




  updateFamProNamc() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.fFamily, this.fModelYear, this.fNamc, this.fEffDateIn);

    if (!this.fFamily || !this.fModelYear || !this.fNamc || !this.fEffDateIn) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.fEffDateIn && this.fEffDateOut &&
      this.pipe.transform(this.fEffDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.fEffDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else if (this.fProdDateIn && this.fProdDateOut && this.pipe.transform(this.fProdDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.fProdDateOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Prod Time In should be smaller than Prod Time Out";

    }
    else {
      const bodyexistscheck = {
        'famCd': this.fFamily,
        'mdlYr': this.fModelYear,
        'namcId': this.fNamc,
        "fileName" :fileName 
        
      }
      this.SpinnerService.show();
      this.famprojAddService.existsaddFamProNamc(bodyexistscheck).subscribe(data => {
        console.info(data);
        this.response = data.payload;

        if (this.response[0].result==='success') {
          this.familyprojnamcApi();
        }
        else if (this.response[0].result==='Data Already Exists') {
          this.SpinnerService.hide();
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide()
      });

    }
  }

  familyprojnamcApi() {
    const user = localStorage.getItem('workDayId');
    const fileName= localStorage.getItem('Filename')
    let indateFormat = ''; let outdateFormat = '';
    console.info(this.fProdDateIn, this.fProdDateOut)
    if (this.fEffDateOut==="" || this.fEffDateOut===null) {
      outdateFormat = "9999-12-31"
      console.info(this.fEffDateOut, outdateFormat)
    }
    if (this.fEffDateIn !=="") {
      const d1 = moment(this.fEffDateIn).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.fEffDateIn = date
    }
    if (this.fEffDateOut !=="" && this.fEffDateOut !==null) {
      const d1 = moment(this.fEffDateOut).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.fEffDateOut = date
    }
    let prodDateINFormat = ''; let prodDateOutFormat = '';
    if (this.fProdDateIn !=="" && this.fProdDateIn !==null) {
      const d1 = moment(this.fProdDateIn).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      prodDateINFormat = moment(date).format('YYYY-MM-DD')
      this.fProdDateIn = date

    }
    if (this.fProdDateOut !=="" && this.fProdDateOut !==null) {
      const d1 = moment(this.fProdDateOut).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      prodDateOutFormat = moment(date).format('YYYY-MM-DD')
      this.fProdDateOut = date
    }

    let hikiatelstArray: any;
    hikiatelstArray = this.selectedHikiateLst;
    console.info(hikiatelstArray.length, hikiatelstArray);
    let str = '';
    for (let i = 0; i < hikiatelstArray.length; i++) {
      if (str==='') {
        str = hikiatelstArray[i]
      } else {
        str += ',' + hikiatelstArray[i]
      }
      this.fHikiate = str;
    }
    console.info(this.fHikiate)
    this.errorMessage = "";
    let cmts;
    if (this.fComments!==undefined) {
      cmts = this.fComments.replaceAll("'", "''");
    } else {
      cmts = this.fComments
    }
    const body = {
      'famCd': this.fFamily,
      'projCd': this.fProject,
      'ssn': this.fSsn,
      'namcId': this.fNamc,
      'hikiateCd': this.fHikiate,
      'mdlYr': this.fModelYear,
      'mdlChgCd': this.fModelChangeType,
      'gnId': this.fGeneration,
      'lneOfPlnt': this.fLineOffPlant,
      'prodInCd': prodDateINFormat,
      'prodOutCd': prodDateOutFormat,
      'extInCd': indateFormat,
      'extOutCd': outdateFormat,
      'rmrkCd': cmts,
      "fileName" :fileName 
      
    }
    // this.SpinnerService.show();
    this.famprojAddService.addFamProNamc(body).subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      console.info(data);
      this.response = data.payload;
      if (this.response[0].result==='success') {
        this.dialogRef.close(this.response[0].result);
      } else if (this.response[0].result==='Data Already Exists') {
        this.errorMessage = 'Data Entered already Exists!';
        this.SpinnerService.hide();
      }
    }, err => {
      this.SpinnerService.hide()
    });
  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='fProdDateIn') {
      this.fProdDateIn = "";
    } else if (name==='fProdDateOut') {
      this.fProdDateOut = "";
    }
    else if (name==='fEffDateIn') {
      this.fEffDateIn = "";
    }
    else if (name==='fEffDateOut') {
      this.fEffDateOut = "";
    }
  }
}




