import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { EbomIsDashboardService } from './ebom-is-dashboard.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-ebom-is-dashboard',
  templateUrl: './ebom-is-dashboard.component.html',
  styleUrls: ['./ebom-is-dashboard.component.css']
})
export class EbomIsDashboardComponent implements OnInit {
  [x: string]: any;
  spinnerMessage = "Please Wait...";
  showdata: boolean = true;
  roleAccess: any = []
   accessEnable: boolean;
  Roledata: any = [];
  errorMessage: any = '';
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  constructor(private readonly SpinnerService: NgxSpinnerService, 
    private ebomIsDashboardService: EbomIsDashboardService, public globalrole: GlobalService,
    ) { }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('partSourceTable') table;
  limitSelected: any = 5;

  columns = [
    { name: 'srNo', prop: 'srNo' }, { name: 'typeOfValidation', prop: 'validation' }, { name: 'count', prop: 'cnt' },
    { name: 'lastRunTs', prop: 'last' }
  ];
  ColumnMode = ColumnMode;

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    this.userroleAccessfn();
    //grid


  }

  userroleAccessfn() {
    this.globalrole.Roledetails.subscribe(res => {
      console.info(res)
      this.Roledata = res;
    })
    console.info(this.Roledata);
    if (this.Roledata.length !==0) {
      this.Roledata.filter((res) => {
        if (res.function_nm==='NBPC_9999') {
          this.roleAccess.push(res.access_desc);
        }
      })
      this.accessEnable = true;
    } else if (this.Roledata.length===0) {
       let roleList: any;
        const data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
        }
        else {
        console.info(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
    }
    console.info(this.roleAccess);
    if (this.Roledata.length !==0) {
      this.getGridData();
    }

  }

  getGridData() {
    console.info((this.roleAccess.includes('Read')))
    if (this.roleAccess.includes('Read')) {
      console.info('1')
      this.ebomIsDashboardService.GridData().subscribe(data => {
        console.info(data)
        this.SpinnerService.hide();
        this.showdata = true;
        const datarow = data.payload;
        this.rows = datarow.map(row => ({
          validation: row['validation'],
          cnt: row['cnt'],
          last:(row['last']===null) ? '' : row['last'].replace('Z', '').replace('T', ' '),

        }))
      }, err => {
        console.info(err);
        this.showdata = false;
        console.info(this.showdata);
        this.rows = [];
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
      });
    } else {
      console.info('2')
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.SpinnerService.hide();
    }
  }
}
