
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { GenerateActProdStatusComponent } from '../generate-act-prod-status/generate-act-prod-status.component';
import { GenerateRepVerSumDialogComponent } from '../generate-rep-ver-sum-dialog/generate-rep-ver-sum-dialog.component';
import { ModifyVboDateComponent } from '../modify-vbo-date/modify-vbo-date.component';
import { ProdProcStatusService } from './prod-proc-status.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { ProdProcStatusActionComponent } from '../prod-proc-status-action/prod-proc-status-action.component'
import * as moment from 'moment';
import { ProdVersionActionsComponent } from '../prod-version-actions/prod-version-actions.component';
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';
import * as FileSaver from 'file-saver';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
declare var $: any;
@Component({
  selector: 'app-prod-proc-status',
  templateUrl: './prod-proc-status.component.html',
  styleUrls: ['./prod-proc-status.component.css']
})
export class ProdProcStatusComponent implements OnInit {

  //export to excel
  fileName = 'ProductProcessingStatus';
  exportData = [];
  sheetName = ["PROD_WITHIN_VER"];
  repData = [];
  errorSheetName = ['PROD_PROC_STAT_ERROR'];
  errorFileName = 'ProdProcStatError';

  resetdownloadData = []
  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  successResetbomMsg: any = '';
  errorResetbomMsg: any = ''
  successResetcostMsg = "";
  errorResetcostMsg = ""

  //ngModel values for search
  step = 0;
  products: any = 'All'
  versionNm: any = '';
  actProdPrior: any = "";
  modelNumber: any = "";
  option: any = "";
  sapMatId: any = "";
  ra: any = "R";
  bomEffFromDate: any = "";
  bomEffToDate: any = "";
  repVerNm: any = "";
  bomEffFromTime: any = "";
  bomEffToTime: any = "";
  procStat: any = "";
  buyOffFromDate: any = "";
  buyOffToDate: any = "";
  namc: any = "";
  family: any = "";
  vinSN: any = "";
  manAdjStat: any = "";
  katashiki: any = "";
  approvalStatus: any = "Open";
  sapProdNum: any = "";
  onHoldStatus: any = "";
  sapStatus: any = "";
  showdata: boolean = true;
  //list
  versionNmLst = [];
  modelNumberLst = [];
  optionLst = [];
  repVerNmLst = [];
  procStatLst = [];
  namcLst = [];
  familyLst = [];
  manAdjStatLst = [];
  katashikiLst = [];
  approvalStatusLst = [];
  sapStatusLst = [];
  // month 
  monthsArr = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
  approvalsts: boolean = false
  //ngx-datatable
  showItems: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('prodProcTable') table;
  limitSelected: any = 50;
  systemParmData: any;
  spinnerMessage = "Please wait...";
  hidedata: any = "false";
  Ahidedata: any = "false";
  response: any;
  hidecolumn: boolean = true
  columns = [{ name: 'copyStruc', prop: 'reuse_struc_flg' }, { name: 'sapProcStat', prop: 'sap_proc_stat_typ_nm' },
  { name: 'procStat', prop: 'bom_proc_stat_typ_desc' }, { name: "ra", prop: "bom_typ_cd" },
  { name: 'namc', prop: 'namc_lgl_entity_id' }, { name: "sapProdNum", prop: "sap_prod_ord_no" },
  { name: 'modelNumber', prop: 'mdl_no' },
  { name: 'option', prop: 'optn_cd' }, { name: 'sapMatId', prop: 'sap_mtrl_id' },
  { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' },
  { name: 'dest', prop: 'dest_ctry_id' }, { name: 'cust', prop: 'buyoff_cust_nm' },
  { name: 'sp', prop: 'base_optn_typ_cd' },
  { name: 'netSpec', prop: 'net_spec_cd_comb_txt' }, { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt' },
  { name: 'repVerNm', prop: 'prod_bom_desc' }, { name: 'vinSN', prop: 'vin' },
  { name: 'onHoldStatus', prop: 'on_hold_flg' }, { name: 'prodDefId', prop: 'prod_def_id' },
  { name: 'prodBomId', prop: 'prod_bom_id' }, { name: 'hikiate', prop: 'hikiate_stat_comb_cd' },
  { name: 'inclHundPerSpec', prop: 'hundred_pct_optn_flg' }, { name: 'bomEffDate', prop: 'bom_eff_dt_ts' },
  { name: 'vinBuyOff', prop: 'buyoff_dt' }, { name: 'procStartDate', prop: 'procsg_strt_dt' },
  { name: 'procSuspDate', prop: 'procsg_sspnsn_dt' }, { name: 'procCompDate', prop: 'procsg_cmplt_dt' },
  { name: 'procToReport', prop: 'procToReport' },
  { name: 'ManAdjsts', prop: 'ManAdjsts' }, { name: 'proctosap', prop: 'proctosap' }
  ];
  month: any = "";
  year: any = "";
  currentYear: any = new Date().getFullYear()
  allSelected: boolean = false;

  @ViewChild('mySel') skillSel: MatSelect;

  ColumnMode = ColumnMode;
  partnumLst: any;
  enablePartnum: boolean = false;
  enableKatashiki: boolean = true;
  onChgkatashikiLst: any = [];
  prod_bom_idlst: any[];
  bom_typ_cd: any;
  fileName1: any;
  roleAccess: any = []
  Roledata: any = [];
  value: any = [];
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any
  currentPage: any = 0;
  currentLimit: any;
  currentPageLimit: any
  versioncntval: any;
  limitexceed: boolean;
  ruleId: any;
  rulestatus: any;
  startDateMR: any;
  endDateMR: any;
  accessEnable: boolean;
  constructor(public dialog: MatDialog, private exportService: ExportToExcelService, private dialogData: ProductWithinVersion,
    private prodProcStatusService: ProdProcStatusService, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }

  async ngOnInit(): Promise<void> {
    const a = await this.userroleAccessfn();
    if (a) {
      this.onloadfn();
    }


  }
  onloadfn() {
    this.getBomVin();
    this.fetchsysParam();
    this.versioncnt()
    //to get version Name
    this.prodProcStatusService.getVersionNm().subscribe(data => {
      console.info('res from version name', data);
      this.versionNmLst = data.payload;
    });

    // to get model number
    this.prodProcStatusService.getModelNumber().subscribe(data => {
      console.info(data);
      this.modelNumberLst = data.payload;
    });
    // to get option
    this.prodProcStatusService.getOption().subscribe(data => {
      console.info(data);
      this.optionLst = data.payload;
    });

    //to get rep version
    this.prodProcStatusService.getVersionNm().subscribe(data => {
      console.info(data);
      this.repVerNmLst = data.payload;
    });

    //to get namc
    this.prodProcStatusService.getNamc().subscribe(data => {
      console.info(data);
      this.namcLst = data.payload;
    });

    //to get family

    this.getFamily();

    //to get katashiki

    // this.getKatashikiLoad();

    //to get ApprovalStatus

    this.prodProcStatusService.getApprovalStat().subscribe(data => {
      console.info(data);
      this.approvalStatusLst = data.payload;
    });

    // to get processed Sap Status

    this.prodProcStatusService.getProcSapStatus().subscribe(data => {
      console.info(data);
      this.sapStatusLst = data.payload;
    });

    //to get namc
    this.prodProcStatusService.getProcessingStatus().subscribe(data => {
      console.info(data);
      this.procStatLst = data.payload;
    });



  }

  versioncnt() {
    this.prodProcStatusService.versioncnt().subscribe(res => {
      console.info("res from version name", res.payload);
      this.versioncntval = res.payload[0].Count
      console.info(this.versioncntval)
    })
  }

  fetchsysParam() {
    this.prodProcStatusService.getsysParam().subscribe(res => {
      console.info("res from sys param", res.payload);
      this.systemParmData = res.payload[0]
      if (this.systemParmData.parm_val_txt==='Y') {
        this.actProdPrior = 'Normal'
      }
      else {
        this.actProdPrior = 'onHold'
      }
    })
  }
  //repversummary
  generateRepVerSummary(): void {
    this.errorMessage = '';
    if (this.versionNm.length <= 20) {
      let prodtyp = this.products==='' ? null : this.products
      this.dialogData.versionName = this.versionNm
      this.dialogData.productType = prodtyp
      const dialogRef = this.dialog.open(GenerateRepVerSumDialogComponent, {
        disableClose: true,
        width: '100%',
        data: this.dialogData
      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
      });
    }
    else if (this.versionNm.length > 20) {
      this.errorMessage = "Version Limit Exceeded";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = "Please select a version to Generate Rep Version Summary";
      this.globalrole.goToTop();
    }
  }

  //actProdStatus
  generateActProdStatus(): void {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = ""
    // if (this.versionNm) {
    let prodtyp = this.products==='' ? null : this.products
    this.dialogData.versionName = this.versionNm
    this.dialogData.productType = prodtyp
    const dialogRef = this.dialog.open(GenerateActProdStatusComponent, {
      disableClose: true,
      width: '100%', 
      data: this.dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result==='Data not found') {
        this.errorMessage = result;
        this.globalrole.goToTop();
      } else {
        this.errorMessage = result;
        this.globalrole.goToTop();
      }
    });
    // }

  }


  search() {
    this.showItems = true;
    this.rows = []
    this.selected = []
    this.value = []
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = ""
    this.getGridData("Initial");
  }

  resetAll(data) {
    this.selected = []
    this.modelNumber = '';
    this.option = '';
    this.sapMatId = '';
    this.ra = "R";
    this.bomEffFromDate = '';
    this.bomEffToDate = '';
    this.repVerNm = '';
    this.bomEffFromTime = '';
    this.bomEffToTime = '';
    this.procStat = '';
    this.buyOffFromDate = '';
    this.buyOffToDate = '';
    this.namc = '';
    this.family = '';
    this.vinSN = '';
    this.manAdjStat = '';
    this.katashiki = '';
    this.approvalStatus = 'Open';
    this.sapProdNum = '';
    this.onHoldStatus = '';
    this.sapStatus = '';
    this.versionNm = '';
    this.actProdPrior = '';
    this.selected = []
    this.value = []
    this.katashikiLst = []
    // if (data !=='upload' && data !=='') {
    //   this.getGridData(data);
    // } else {
    //   this.showItems = false;
    // }
    this.onloadfn();
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  modifyVboDate(): void {
    if (this.roleAccess.includes('Update')) {
      this.errorMessage = '';
      this.successMessage = '';
      this.errorResetbomMsg = '';
      this.successResetbomMsg = '';
      this.successResetcostMsg = "";
      this.errorResetcostMsg = ""
      const dialogRef = this.dialog.open(ModifyVboDateComponent, {
        disableClose: true,
        width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        if (result==='success') {
          // this.resetAll("upload");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result==='cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }

      });
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  //export function
  exportFile(): void {
    console.info(this.rows)
    this.exportData = [];

    this.rows.forEach(row => {
      let obj = {
        "Copy Structure": row.copyStruc,
        "SAP Processing Status": row.sap_proc_stat_typ_nm,
        "Processing Status": row.bom_proc_stat_typ_desc,
        "R/A": row.bom_typ_cd,
        "NAMC": row.namc_lgl_entity_id,
        "SAP Production Order Number": row.sap_prod_ord_no,
        "Model Number": row.mdl_no,
        "Option": row.optn_cd,
        "SAP Material Id": row.sap_mtrl_id,
        "Family": row.fam_cd,
        "Katashiki/Part": row.katashiki_cd,
        "Dest": row.dest_ctry_id,
        "Cust": row.buyoff_cust_nm,
        "S/P": row.base_optn_typ_cd,
        "Net Spec": row.net_spec_cd_comb_txt,
        "PC Spec": row.pc_spec_cd_comb_txt,
        "Rep Version Name": row.prod_bom_desc,
        "VIN/SN": row.vin,
        "On-Hold Status": row.on_hold_flg,
        "Prod Def Id": row.prod_def_id,
        "Prod Bom Id": row.prod_bom_id,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "Include 100% Mandatory Spec": row.hundred_pct_optn_flg,
        "BoM Effectivity/VIN I9/Unit Exit Date": row.bom_eff_dt_ts,
        "VIN Buyoff/Unit Ship Date": row.buyoff_dt,
        "Processing Start Date": row.procsg_strt_dt,
        "Processing Suspension Date": row.procSuspDate,
        "Processing Completion Date": row.procsg_cmplt_dt,
        "Processed to Reports": row.procToReport,
        "Processed to SAP":row.proctosap,
        "Manual Adjustment Status": row.ManAdjsts,
        "Manual Adjustment Rule Id(s)": row.manadjpopup

      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }

  getGridData(dataload) {
    this.selected = []
    if (dataload !=='Add') {
      this.errorMessage = '';
      this.successMessage = '';
      this.errorResetbomMsg = '';
      this.successResetbomMsg = '';
      this.successResetcostMsg = "";
      this.errorResetcostMsg = ""
    }
    console.info(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      
      let bom_proc_stat_typ_data: any;
      const arrayvalue = this.procStat;
      console.info(arrayvalue.length, arrayvalue);
      let str = '';
      for (let i = 0; i < arrayvalue.length; i++) {
        if (str==='') {
          str = "'" + arrayvalue[i] + "'"
        } else {
          str += ',' + "'" + arrayvalue[i] + "'"
        }
        bom_proc_stat_typ_data = str;
      }
      this.SpinnerService.show()
      let mdl_no = this.modelNumber==="" ? null : this.modelNumber
      let optn_cd = this.option==="" ? null : this.option
      let sap_mtrl_id = this.sapMatId==="" ? null : this.sapMatId
      let bom_typ_cd = this.ra==="" ? null : this.ra
      let bom_eff_dt_ts = this.bomEffFromDate==="" ? null : this.bomEffFromDate
      let bom_eff_from_time = this.bomEffFromTime==="" ? null : this.bomEffFromTime
      let bom_eff_to_time = this.bomEffToTime==="" ? null : this.bomEffToTime
      let bom_end_dt_ts = this.bomEffToDate==="" ? null : this.bomEffToDate
      let prod_bom_desc = this.repVerNm==="" ? null : this.repVerNm
      let bom_proc_stat_typ_desc = this.procStat==="" ? null : bom_proc_stat_typ_data
      let buyoff_from_dt = this.buyOffFromDate==="" ? null : this.buyOffFromDate
      let buyoff_to_dt = this.buyOffToDate==="" ? null : this.buyOffToDate
      let namc_lgl_entity_id = this.namc==="" ? null : this.namc
      let fam_cd = this.family==="" ? null : this.family
      let vin = this.vinSN==="" ? null : this.vinSN
      let man_adjsmt_proc_stat_flg = this.manAdjStat==="" ? null : this.manAdjStat
      let katashiki_cd = this.katashiki==="" ? null : this.katashiki
      let bom_ver_apprvl_stat_typ;
      if (bom_typ_cd==='A') {
        bom_ver_apprvl_stat_typ = null
      }
      else {
        bom_ver_apprvl_stat_typ = this.approvalStatus==="" ? 'Open' : this.approvalStatus
      }
      let sap_prod_ord_no = this.sapProdNum==="" ? null : this.sapProdNum
      let on_hold_flg = this.onHoldStatus==="" ? null : this.onHoldStatus
      let sap_proc_stat_typ_nm = this.sapStatus==="" ? null : this.sapStatus
      let newDate = new Date();
      const date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      const fileName = 'ProdProcStatus-grid/prodgridSrc' + date + '.json'
      let TfileName= localStorage.getItem('Filename')
      let hitoption;
      if (dataload==='pagination') {
        hitoption = 'initial'
      } else {
        hitoption = dataload
      }
      let indateFormat = ''; let outdateFormat = '';
      if (bom_eff_dt_ts !==null) {
        const d1 = moment(bom_eff_dt_ts).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        bom_eff_dt_ts = date
      } else {
        indateFormat = null
      }
      if (bom_end_dt_ts !==null) {
        const d1 = moment(bom_end_dt_ts).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        bom_end_dt_ts = date
      } else {
        outdateFormat = null
      }
      let buyinndateFormat = ''; let buyoffdateFormat = '';
      if (buyoff_from_dt !==null) {
        const d1 = moment(buyoff_from_dt).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        buyinndateFormat = moment(date).format('YYYY-MM-DD')
        buyoff_from_dt = date
      } else {
        buyinndateFormat = null
      }
      if (buyoff_to_dt !==null) {
        const d1 = moment(buyoff_to_dt).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        buyoffdateFormat = moment(date).format('YYYY-MM-DD')
        buyoff_to_dt = date
      } else {
        buyoffdateFormat = null
      }

      let data =
      {
        "mdl_no": mdl_no,
        "optn_cd": optn_cd,
        "sap_mtrl_id": sap_mtrl_id,
        "bom_typ_cd": bom_typ_cd,
        "bom_eff_dt_ts": indateFormat,
        "bom_end_dt_ts": outdateFormat,
        "bom_eff_dt_time": bom_eff_from_time,
        "bom_end_dt_time": bom_eff_to_time,
        "prod_bom_desc": prod_bom_desc,
        "bom_proc_stat_typ_desc": bom_proc_stat_typ_desc,
        "buyoff_from_dt": buyinndateFormat,
        "buyoff_to_dt": buyoffdateFormat,
        "namc_lgl_entity_id": namc_lgl_entity_id,
        "fam_cd": fam_cd,
        "vin": vin,
        "part_serl_no": vin,
        "man_adjsmt_proc_stat_flg": man_adjsmt_proc_stat_flg,
        "katashiki_cd": katashiki_cd,
        "prod_part_no": katashiki_cd,
        "bom_ver_apprvl_stat_typ": bom_ver_apprvl_stat_typ,
        "sap_prod_ord_no": sap_prod_ord_no,
        "on_hold_flg": on_hold_flg,
        "sap_proc_stat_typ_nm": sap_proc_stat_typ_nm,
        "option": hitoption,
        "pageSize": this.currentPage,
        "limit": this.limitSelected,
        "GfileName": fileName,
        "screenNo":"NBPC_10115",
        "fileName" :TfileName

      }
      console.info("body in grid", data);
      this.prodProcStatusService.getGridDetails(data).subscribe(data => {
        console.info('res from get grid data', data)
        const datarow = data.payload;
        this.showdata = true;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(fileName);
          }, 2000);
        } else {
          this.SpinnerService.hide()
          this.rows = datarow.map(row => ({
            prod_id: row['prod_id'],
            checkboxdata: (row['prod_stat_enable_flg']==='Y') ? true : false,
            copyStruc: row['copyStruc'],
            sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm'],
            bom_proc_stat_typ_desc: row['bom_proc_stat_typ_desc'],
            bom_typ_cd: row['bom_typ_cd'],
            namc_lgl_entity_id: row['namc_cd'],
            sap_prod_ord_no: row['sap_prod_ord_no'],
            mdl_no: row['mdl_no'],
            optn_cd: row['optn_cd'],
            sap_mtrl_id: row['sap_mtrl_id'],
            fam_cd: row['fam_cd'],
            katashiki_cd: (row['katashiki_cd'] !==null) ? row['katashiki_cd'] : row['prod_part_no'],
            dest_ctry_id: row['dest_ctry_id'],
            buyoff_cust_nm: row['buyoff_cust_nm'],
            base_optn_typ_cd: row['base_optn_typ_cd'],
            net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
            pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
            prod_bom_desc: row['prod_bom_desc'],
            on_hold_flg: row['on_hold_flg'],
            prod_def_id: row['prod_def_id'],
            prod_bom_id: row['prod_bom_id'],
            hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
            hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
            bom_eff_dt_ts: (row['bom_eff_dt_ts']===null) ? '' : row['bom_eff_dt_ts'].replace('Z', '').replace('T', ' '),
            buyoff_dt: (row['buyoff_dt']===null) ? '' : row['buyoff_dt'].replace('Z', '').replace('T', ' '),
            procsg_strt_dt: (row['procsg_strt_dt']===null) ? '' : row['procsg_strt_dt'].replace('Z', '').replace('T', ' '),
            procSuspDate: (row['procsg_sspnsn_dt']===null) ? '' : row['procsg_sspnsn_dt'].replace('Z', '').replace('T', ' '),
            procsg_cmplt_dt: (row['procsg_cmplt_dt']===null) ? '' :
              row['procsg_cmplt_dt'].replace('Z', '').replace('T', ' '),
            procToReport: (row['dm_load_ts']===null) ? '' :
              row['dm_load_ts'].replace('Z', '').replace('T', ' '),
            vin: row['vin'],
            ManAdjsts: row['man_adjsmt_proc_stat_flg'],
            manadjpopup: row['man_adj_rules_dtls'],
            proctosap: (row['sent_to_sap']===null) ? '' :
              row['sent_to_sap'].replace('Z', '').replace('T', ' ')
          })
          )
        }
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = ""
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthlyRep,
        "Comparitive BOM": row.compBom,
        "Model Number": row.mdlNo,
        "Option": row.option,
        "SAP Material ID": row.sapMatId,
        "NAMC": row.namc,
        "Family": row.family,
        "Katashiki/part": row.katashiki,
        "Customer": row.customer,
        "Destination": row.dest,
        "Hikiate Status": row.hikiate,
        "S/P": row.sp,
        "Net Spec": row.netspec,
        "PC Spec": row.pcSpec,
        "100% Spec": row.hundPerSpec,
        "Comp PC Spec": row.compPcSpec,
        "Comp 100% Spec": row.compHundPerSpec,
        "Extaction Time In": row.extTimeIn,
        "Extraction Time Out": row.extTimeOut,
        "Comments": row.comments,
        "Rep Prod Def Id": row.repProdDefId,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.info(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
    this.exportData = []
  }

  getFamily() {
    if (this.namc===undefined) {
      this.namc = "";
    }
    this.prodProcStatusService.getFamily(this.namc).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
      this.family = ""
      this.katashiki = ""
      this.katashikiLst = []
    });
  }

  getKatashikiLoad() {
    this.prodProcStatusService.getKatashiki().subscribe(data => {
      console.info(data);
      this.katashikiLst = data.payload;
      this.katashiki = ""
    });
  }


  async onchangecall() {
    this.katashikiLst = []
    this.partnumLst = []
    let a: any = await this.getPartnumOnChange();
    let b: any = await this.getKatashikionchg();
    console.info(a.length, b.length)
    if (b.length !==0 && a.length !==0) {
      function renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
      const arr = a;
      arr.forEach(obj => renameKey(obj,'prod_part_no','katashiki_cd'));
      const updatedJson = arr;
      console.info(updatedJson);
      this.katashikiLst = b.concat(updatedJson);
      console.info(this.katashikiLst)
      this.enableKatashiki = true;
      this.enablePartnum = false
    }
    else if (b.length !==0) {
      this.katashikiLst = b
      this.enableKatashiki = true;
      this.enablePartnum = false
    }
    else if (a.length !==0) {
      this.katashikiLst = a
      this.enableKatashiki = false;
      this.enablePartnum = true
    }

  }

  getPartnumOnChange() {
    let TfileName= localStorage.getItem('Filename')
    return new Promise(res => {
      const body = {
        "namc_lgl_entity_id": this.namc,
        "fam_cd": this.family,
        "screenNo":"NBPC_10115",
        "fileName" :TfileName
      }
      console.info("body", body);
      this.prodProcStatusService.getPartnumonchange(body).subscribe(data => {
        console.info(data);
        this.partnumLst = data.payload;
        this.katashiki = ""
        res(this.partnumLst)
      }, err => {
        this.partnumLst = []
        res(this.katashikiLst)
      });
    })

  }

  getKatashikionchg() {
    let TfileName= localStorage.getItem('Filename')
    return new Promise(res => {
      const body = {
        "namc_lgl_entity_id": this.namc,
        "bom_typ_cd": this.ra,
        "fam_cd": this.family,
        "screenNo":"NBPC_10115",
        "fileName" :TfileName
      }
      this.prodProcStatusService.getKatashikionchange(body).subscribe(data => {
        console.info(data);
        this.katashikiLst = data.payload;
        this.katashiki = ""
        res(this.katashikiLst)
        console.info(this.katashikiLst)
      }, err => {
        this.katashikiLst = []
        res(this.katashikiLst)
      });
    });
  }

  clearDate(event, name) {
    event.stopPropagation();
    console.info(name)
    if (name==='bomEffFromDate') {
      this.bomEffFromDate = "";
    } if (name==='bomEffToDate') {
      this.bomEffToDate = "";
    }
    if (name==='buyOffFromDate') {
      this.buyOffFromDate = "";
    } if (name==='buyOffToDate') {
      this.buyOffToDate = "";
    }
    this.dateFrom()
    this.dateTo()
  }

  getBomVin() {
    console.info(this.ra)
    if (this.ra==="R") {
      console.info(this.hidedata)
      this.hidedata = "true";
      this.Ahidedata = "false";
      this.namc = ""
      this.family = ""
      this.katashiki = ""
      this.bomEffFromTime = ""
      this.bomEffToTime = ""
      this.vinSN = ""
      this.approvalsts = false
      // this.buyOffToDateText = false;
      // this.vinSNText = false;
    } else if (this.ra==="A") {
      this.hidedata = "false";
      this.Ahidedata = "true";
      this.repVerNm = ""
      this.namc = ""
      this.family = ""
      this.katashiki = ""
      this.approvalStatus = ""
      this.approvalsts = true
      console.info(this.hidedata)
      // this.buyOffFromDateText = true;
      // this.buyOffToDateText = true;
      // this.vinSNText = true;
    }
  }

  chngActProdPriority() {
    // alert("Do you want to change?")
    let TfileName= localStorage.getItem('Filename')
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    var proceed = confirm("Are you sure you want to proceed?");
    if (proceed) {
      this.SpinnerService.show();
      const user = localStorage.getItem('workDayId')
      const body = {      
        "data_Sent": this.actProdPrior,
        "screenNo":"NBPC_10115",
        "fileName" :TfileName
      }
      this.prodProcStatusService.updateActProdPriority(body).subscribe(res => {
        console.info("res from update", res.payload);
        let response = res.payload
        this.SpinnerService.hide();
        if (response[0].result==="success") {
          this.successMessage = "Data updated successfully"
        }
        else {
          this.errorMessage = "Data not updated"
          this.globalrole.goToTop();
        }
      })
    } else {
      console.info("no update");

    }

  }

  onSelect({ selected }) {
    this.selected = selected;

    console.info('Select Event', selected, this.selected.length);
    // console.info('Select Event', selected, this.selected);
    // // this.selected = selected
    let data = []

    if (selected.length===this.rows.length) {
      data = this.selected.filter(function (el) {
        return el.checkboxdata !==false
      });
      this.value = data;
    } else {
      this.value = this.selected
    }

    console.info(this.value);

  }
  displayCheck(row) {
    return row.checkboxdata !==false;
  }

  sendtoSap() {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        if (this.value[0].bom_typ_cd==="A") {
          this.sendToDialogData(this.value, 'sendtosap')
          const dialogRef = this.dialog.open(ProdProcStatusActionComponent, {
            disableClose: true,
            data: this.dialogData,
            width: '0'
          });

          dialogRef.afterClosed().subscribe(result => {
            this.selected = []
            this.value = []
            console.info('The dialog was closed');
            if (result==='success') {
              this.successMessage = "Data Updated Successfully"
              this.getGridData("Add")
            }
            else {
              console.info("Data nt updated");
              this.errorMessage = result
              this.globalrole.goToTop();
              // this.errorMessage = 'Data not inserted/Valid'
            }
          });
        }
        else {
          this.errorMessage = "Please select the Actual records to process"
          this.globalrole.goToTop();
        }
      }
      else {
        this.errorMessage = "Please select a row to Send To SAP";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  resetSendtoSap() {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        if (this.value[0].bom_typ_cd==="A") {
          this.sendToDialogData(this.value, 'resetsendtosap')
          const dialogRef = this.dialog.open(ProdProcStatusActionComponent, {
            disableClose: true,
            data: this.dialogData
          });

          dialogRef.afterClosed().subscribe(result => {
            this.selected = []
            this.value = []
            console.info('The dialog was closed');
            if (result==='success') {
              this.successMessage = "Data Updated Successfully"
              this.getGridData("Add")
            }
            else {
              console.info("Data nt updated");
              this.errorMessage = result
              this.globalrole.goToTop();
              // this.errorMessage = 'Data not inserted/Valid'
            }
          });
        }
        else {
          this.errorMessage = "Please select the Actual records to process"
          this.globalrole.goToTop();
        }
      }
      else {
        this.errorMessage = "Please select a row to Reset to Send To SAP";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }
  setHoldProc() {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    if (this.roleAccess.includes('Update')) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.value, 'setHoldProc')
        const dialogRef = this.dialog.open(ProdProcStatusActionComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = []
          this.value = []
          console.info('The dialog was closed', result);
          if (result==='success') {
            this.successMessage = "Selected Products have been successfully held from processing "
            this.globalrole.goToTop();
            this.getGridData("Add")
          }
          else {
            console.info("Data nt updated");
            this.errorMessage = result
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to Set To Hold Processing";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }


  resetBomProc() {
    const datarow = [];
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    let text = "Are you sure you want to change the Processing Status to 'BoM Ready'?";
    if (confirm(text)===true) {
      if (this.roleAccess.includes('Update')) {
        this.value.filter(row => {
          console.info("row", row.bom_proc_stat_typ_desc);
          if (row.bom_proc_stat_typ_desc==='MFG Suspended' || row.bom_proc_stat_typ_desc==="Approved"
            || row.bom_proc_stat_typ_desc==="Approved-MFG") {
            console.info("inside if", row.bom_proc_stat_typ_desc);
            datarow.push(row)
          }
        })
        console.info("datarow", datarow);
        if (datarow.length===0) {
          if (this.value.length > 0) {
            this.errorMessage = '';
            this.sendToDialogData(this.value, 'resetBomProc')
            const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
              disableClose: true,
              data: this.dialogData
            });

          dialogRef.afterClosed().subscribe(result => {
            this.selected = [];
            this.value = []
            if (result==='ERROR') {
              this.errorMessage = 'ERROR in Procedure'
              this.globalrole.goToTop();
            } else if (result==="Already is in progress. Please Work Later") {
              this.errorMessage = "Already is Inprogress. Please Work Later";
              this.globalrole.goToTop();
            }
            else {
              let FailedRows = result[1].FailedRecords.rows
              let successData = result[0].CountData.rows[0]
              let records = result[2].ResetRecords.rows

                if (FailedRows.length===0) {
                  this.SpinnerService.show();
                  if (records.length !==0) {
                    this.resetdownloadData = records
                    this.successResetbomMsg = successData.count + " record(s) Processed to BoM_Ready successfully"
                    this.globalrole.goToTop();
                    this.SpinnerService.hide()
                  }
                  else {
                    this.rows = []
                    this.selected = []
                    this.value = []
                    this.errorResetbomMsg = successData.count + " record(s) Processed to BoM_Ready successfully and " +
                      FailedRows.length + " record(s) not processed to BoM_Ready"
                    this.globalrole.goToTop();
                    this.SpinnerService.hide()
                  }
                  // this.getGridData(true)
                }
                else {
                  this.errorMessage = 'Records are failed to processed'
                  this.globalrole.goToTop();
                }
              }
            });
          }
          else {
            this.errorMessage = "Please select a row to edit";
            this.globalrole.goToTop();
          }
        } else {
          this.errorMessage = 'Please unselect products with Processing Status of MFG Suspended,APPROVED,APPROVED-MFG'
          this.globalrole.goToTop();
        }
      } else {
        this.errorMessage = "User does not have permission to perform 'update' operation"
        this.globalrole.goToTop();
      }
    } else {
      console.info("cancel")
    }
  }

  resetCostProc() {
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    let text = "Are you sure you want to change the Processing Status to 'Cost Ready'?";
    if (confirm(text)===true) {
      if (this.roleAccess.includes('Update')) {
        const datarow = []
        this.value.filter(row => {
          console.info("row", row.bom_proc_stat_typ_desc);
          if (row.bom_proc_stat_typ_desc==='BoM Suspended TS Cost Processing' || row.bom_proc_stat_typ_desc==="BoM Suspended TS Exchange Rate"
            || row.bom_proc_stat_typ_desc==="Cost Suspended" || row.bom_proc_stat_typ_desc==="Incr BoM Suspended" || row.bom_proc_stat_typ_desc==="Ready for Review" || row.bom_proc_stat_typ_desc==="Verified") {
            console.info("inside if", row.bom_proc_stat_typ_desc);

            datarow.push(row)
          }
        })
        console.info("datarow", datarow);

        if (datarow.length > 0) {
          if (this.value.length > 0) {
            this.errorMessage = '';
            this.sendToDialogData(this.value, 'resetCostProc')
            const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
              disableClose: true,
              data: this.dialogData,
            });
            dialogRef.afterClosed().subscribe(result => {
              this.selected = [];
              this.value = [];
              let FailedRows;
              let successData;
              let records;
              if (result !=="Already is in progress. Please Work Later") {
                FailedRows = result[1].FailedRecords.rows
                successData = result[0].CountData.rows[0]
                records = result[2].ResetRecords.rows
              }
              if (result==="Already is in progress. Please Work Later") {
                this.errorMessage = "Already is Inprogress. Please Work Later";
                this.globalrole.goToTop();
              } else if (FailedRows.length===0) {
                this.SpinnerService.show();
                if (records.length !==0) {
                  this.resetdownloadData = records
                  this.successResetcostMsg = successData.count + " record(s) Processed to Cost Ready successfully"
                  this.globalrole.goToTop();
                  this.SpinnerService.hide()
                }
                else {
                  this.rows = []
                  this.selected = []
                  this.value = []
                  this.errorResetcostMsg = successData.count + " record(s) Processed to Cost Ready successfully and " +
                    FailedRows.length + " record(s) not processed to Cost Ready"
                  this.globalrole.goToTop();
                  this.SpinnerService.hide()
                }
                // this.getGridData(true)
              }
              else {
                this.errorMessage = 'No Dependent Products'
                this.globalrole.goToTop();
              }
            });
          }
          else {
            this.errorMessage = "Please select a row to edit";
            this.globalrole.goToTop();
          }
        } else {
          this.errorMessage = 'Please select only products with Processing Status of BoM Suspended TS Cost Processing,BoM Suspended TS Exchange Rate,Cost Suspended,Incr BoM Suspended,Ready for Review,Verified'
          this.globalrole.goToTop();
        }
      } else {
        this.errorMessage = "User does not have permission to perform 'update' operation"
        this.globalrole.goToTop();
      }
    } else {
      console.info("cancel")
    }
  }

  IdenDepProd() {
    const datarow = []
    this.value.filter(row => {
      console.info("row", row.base_optn_typ_cd);
      if (row.base_optn_typ_cd==='P' || row.base_optn_typ_cd==='S') {
        datarow.push(row)
      }
    })
    this.errorMessage = '';
    this.successMessage = '';
    this.errorResetbomMsg = '';
    this.successResetbomMsg = '';
    this.successResetcostMsg = "";
    this.errorResetcostMsg = "";
    console.info("value:datarow", this.value, datarow);
    if (datarow.length===this.value.length) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.value, 'IdenDepProd')
        const dialogRef = this.dialog.open(ProdVersionActionsComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          this.value = [];
          let FailedRows;
          let successData;
          let records;
          if (result !=="Already is in progress. Please Work Later") {
            FailedRows = result[1].FailedRecords.rows
            successData = result[0].CountData.rows[0]
            records = result[2].ResetRecords.rows
          }
          if (result==="Already is in progress. Please Work Later") {
            this.errorMessage = "Already is Inprogress. Please Work Later";
            this.globalrole.goToTop();
          }
          else if (FailedRows.length===0) {
            this.SpinnerService.show();
            if (successData.prod_bom_id_lst !=='') {
              this.getIdeDeptRecordsIngrid(successData.prod_bom_id_lst, 'IdenDepProd')
            }
            else {
              this.rows = []
              this.selected = []
              this.value = []
            }
            // this.getGridData(true)
          }
          else {
            this.errorMessage = 'No Dependent Products'
            this.globalrole.goToTop();
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "Please Select Representive vehicle(s/p)"
      this.globalrole.goToTop();
    }

  }

  getIdeDeptRecordsIngrid(ids, buttonName) {
    let TfileName= localStorage.getItem('Filename')
    console.info("ids fr depend records", ids);
    const body = {
      "screenNm": 'prodprocstatus',
      "prod_bom_id": ids,
      "screenNo":"NBPC_10115",
        "fileName" :TfileName
    }
    this.prodProcStatusService.getIdeDeptProdLst(body).subscribe(res => {
      console.info("res from idenDeptProd", res.payload);
      const datarow = res.payload;

      this.rows = datarow.map(row => ({
        prod_id: row['prod_id'],
        checkboxdata: (row['prod_stat_enable_flg']==='Y') ? true : false,
        copyStruc: row['copyStruc'],
        sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm'],
        bom_proc_stat_typ_desc: row['bom_proc_stat_typ_desc'],
        bom_typ_cd: row['bom_typ_cd'],
        namc_lgl_entity_id: row['namc_lgl_entity_id'],
        sap_prod_ord_no: row['sap_prod_ord_no'],
        mdl_no: row['mdl_no'],
        optn_cd: row['optn_cd'],
        sap_mtrl_id: row['sap_mtrl_id'],
        fam_cd: row['fam_cd'],
        katashiki_cd: (row['katashiki_cd'] !==null) ? row['katashiki_cd'] : row['prod_part_no'],
        dest_ctry_id: row['dest_ctry_id'],
        buyoff_cust_nm: row['buyoff_cust_nm'],
        base_optn_typ_cd: row['base_optn_typ_cd'],
        net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
        pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
        prod_bom_desc: row['prod_bom_desc'],
        on_hold_flg: row['on_hold_flg'],
        prod_def_id: row['prod_def_id'],
        prod_bom_id: row['prod_bom_id'],
        hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
        hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
        vin: row['vin'],
        bom_eff_dt_ts: (row['bom_eff_dt_ts']===null) ? '' : row['bom_eff_dt_ts'].split('T')[0],
        buyoff_dt: (row['buyoff_dt']===null) ? '' : row['buyoff_dt'].replace('Z', '').replace('T', ' '),
        procsg_strt_dt: (row['procsg_strt_dt']===null) ? '' : row['procsg_strt_dt'].replace('Z', '').replace('T', ' '),
        procSuspDate: (row['procsg_sspnsn_dt']===null) ? '' : row['procsg_sspnsn_dt'].replace('Z', '').replace('T', ' '),
        procsg_cmplt_dt: (row['procsg_cmplt_dt']===null) ? '' :
          row['procsg_cmplt_dt'].replace('Z', '').replace('T', ' '),
        procToReport: (row['dm_load_ts']===null) ? '' :
          row['dm_load_ts'].replace('Z', '').replace('T', ' '),
        ManAdjsts: row['man_adjsmt_proc_stat_flg'],
        manadjpopup: row['man_adj_rules_dtls'],
        proctosap: (row['sent_to_sap']===null) ? '' :
          row['sent_to_sap'].replace('Z', '').replace('T', ' ')
      }))
      this.SpinnerService.hide();


    }, err => {
      this.SpinnerService.hide()
      this.errorMessage = "Function failed to fetch data"
      this.globalrole.goToTop();
    })

  }

  genBomStructure() {
    this.successResetbomMsg = ''
    this.errorResetbomMsg = ''
    this.successMessage = ''
    this.errorMessage = ''
    this.successResetcostMsg = "";
    this.errorResetcostMsg = ""
    if (this.roleAccess.includes('Generate BoM Structure')) {
      if (this.value.length > 0) {
        this.errorMessage = '';
        this.sendToDialogData(this.value, 'genBomStruc')
        const dialogRef = this.dialog.open(ProdProcStatusActionComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = []
          this.value = []
          console.info('The dialog was closed', result);
          // if (result !=="Failed") {
          //   if (result.length > 0) {

          //     // this.successMessage = "Selected Products  "
          //     this.getGridData("Add")
          //     this.exportBomStruc(result);
          //   }
          // }
          // else 
          if (result==="success") {
            this.successMessage = "Generate BOM Structure Process Started, Report Details will be share via Mail"
            this.globalrole.goToTop();
          }
          else {
            console.info("Data nt updated");
            this.errorMessage = "Data not updated"
            this.globalrole.goToTop();
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row ";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'generate bom structure' operation"
      this.globalrole.goToTop();
    }
  }

  //data to dialog box
  sendToDialogData(selected, data): void {

    console.info(selected);
    const arrayvalue = [];
    let prod_id_lst: any
    for (let i = 0; i < selected.length; i++) {
      arrayvalue.push(selected[i].prod_id);
    }
    console.info(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str==='') {
        str = arrayvalue[i]
      } else {
        str += ',' + arrayvalue[i];
      }
      prod_id_lst = str;
    }
    let arrayvalue1 = [];
    let prod_bom_id_lst: any
    for (let i = 0; i < selected.length; i++) {
      arrayvalue1.push(selected[i].prod_bom_id);
    }
    console.info(arrayvalue1.length, arrayvalue1);
    let str1 = '';
    for (let i = 0; i < arrayvalue1.length; i++) {
      if (str1==='') {
        str1 = arrayvalue1[i]
      } else {
        str1 += ',' + arrayvalue1[i];
      }
      prod_bom_id_lst = str1;
    }
    console.info(this.value[0].bom_typ_cd)
    selected.forEach(row => {
      this.dialogData.mfrdProdId = prod_id_lst
      this.dialogData.prod_bom_id = prod_bom_id_lst
      this.dialogData.bom_typ_cd = this.value[0].bom_typ_cd
      this.dialogData.buttonName = data
    });
  }

  exportBomStruc(result) {
    this.exportData = [];
    this.fileName1 = "Gen_Bom_Structure"
    // this.sheetName1 = ["GEN_BOM_STRUCTURE"];
    let details = []
    console.info("result", result);

    details = result
    details.forEach(row => {
      let obj = {
        "Processing_status": row.processing_status,
        "Prod_Def_ID": row.prod_def_id,
        "Prod_Bom_ID": row.prod_bom_id,
        "Version": row.version,
        "NAMC": row.namc,
        "Family": row.family,
        "Dest": row.dest,
        "R/A": row.r_a,
        "SAP_Production_Order_Number": row.sap_production_order_number,
        "Model_Number": row.model_number,
        "Option": row.option,
        "SAP_Material_ID": row.sap_material_id,
        "Katashiki/Part": row.katashiki_part,
        "VIN_SN": row.vin_sn,
        "NetSpec": row.netspec,
        "GRPI": row.grpi,
        "Level": row.level,
        "Parent_Part": row.parent_part,
        "Part": row.part,
        "PartDescription": row.PartDescription,
        "Qty_Per_Parent": row.qty_per_parent,
        "Qty_Per_Product": row.qty_per_product,
        "Routing": row.routing,
        "Part_Source": row.part_source,
        "Standard_Cost_Available": row.standard_cost_available,
        "Actual_Cost_Available": row.actual_cost_available,
        "Transfer_Cost_Available": row.transfer_cost_available,
        "Standard_Unit_Cost": row.standard_unit_cost,
        "Actual_Unit_Cost": row.actual_unit_cost,
        "Transfer_Unit_Cost": row.transfer_unit_cost
      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName1, ['GEN_BOM_STRUCTURE']);

    this.exportData = []
  }

  userroleAccessfn() {
    this.SpinnerService.show();
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_10115') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        this.SpinnerService.hide();
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            this.SpinnerService.hide();
            resolve(true);
          }
        else{
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }

  downloadresetBom(data) {
    this.exportData = [];
    const fileName = '';
    this.sheetName = [];
    if (data==='bom') {
     const fileName = "Reset_Bom_Processing"

    }
    else if (data==='cost') {
     const fileName = "Reset_Cost_Processing"
    }
    console.info("result", this.resetdownloadData);
    this.resetdownloadData.forEach(row => {
      let obj = {
        'Copy Struc': row.copyStruc,
        'SAP Processing Status': row.sap_proc_stat_typ_nm,
        'Processing Status': row.bom_proc_stat_typ_desc,
        'R/A': row.bom_typ_cd,
        'NAMC': row.namc_lgl_entity_id,
        'SAP Production Order Number': row.sap_prod_ord_no,
        'Model Number': row.mdl_no,
        'Option': row.optn_cd,
        'SAP Material ID': row.sap_mtrl_id,
        'Family': row.fam_cd,
        'Katashiki/Part': row.katashiki_cd,
        'Dest': row.dest_ctry_id,
        'Cust': row.buyoff_cust_nm,
        'S/P': row.base_optn_typ_cd,
        'Net Spec': row.net_spec_cd_comb_txt,
        'PC Spec': row.pc_spec_cd_comb_txt,
        'Rep Version Name': row.prod_bom_desc,
        'VIN/SN': row.vin,
        'On-Hold Status': row.on_hold_flg,
        'Prod Def ID': row.prod_def_id,
        'Prod Bom ID': row.prod_bom_id,
        'Hikiate Status': row.hikiate_stat_comb_cd,
        'Include 100% Mandatory Spec': row.hundred_pct_optn_flg,
        'Bom Effectivity/VIN I9/Unit Exit Date Date': (row.bom_eff_dt_ts===null) ? '' : moment(row.bom_eff_dt_ts).format('YYYY-MM-DD').split('T')[0],
        'VIN Buy off/Unit Ship Date': (row.buyoff_dt===null) ? '' : moment(row.buyoff_dt).format('YYYY-MM-DD').split('T')[0],
        'Processing Start Date': (row.procsg_strt_dt===null) ? '' : moment(row.procsg_strt_dt).format('YYYY-MM-DD').split('T')[0],
        'Processing Suspension Date': row.procSuspDate,
        'Processing Completion Date': (row.procsg_cmplt_dt===null) ? '' : moment(row.procsg_cmplt_dt).format('YYYY-MM-DD').split('T')[0],
        'Processed To Reports': row.procToReport
      }
      this.exportData.push(obj);
    })
    console.info('exportdata', this.exportData, fileName);

    this.exportService.exportAsExcelFile(this.exportData, fileName, this.sheetName);
    this.SpinnerService.hide();
    this.exportData = []
  }

  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    const a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.info(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.info('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.info(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.info("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              prod_id: row['prod_id'],
              checkboxdata: (row['prod_stat_enable_flg']==='Y') ? true : false,
              copyStruc: row['copyStruc'],
              sap_proc_stat_typ_nm: row['sap_proc_stat_typ_nm'],
              bom_proc_stat_typ_desc: row['bom_proc_stat_typ_desc'],
              bom_typ_cd: row['bom_typ_cd'],
              namc_lgl_entity_id: row['namc_cd'],
              sap_prod_ord_no: row['sap_prod_ord_no'],
              mdl_no: row['mdl_no'],
              optn_cd: row['optn_cd'],
              sap_mtrl_id: row['sap_mtrl_id'],
              fam_cd: row['fam_cd'],
              katashiki_cd: (row['katashiki_cd'] !==null) ? row['katashiki_cd'] : row['prod_part_no'],
              dest_ctry_id: row['dest_ctry_id'],
              buyoff_cust_nm: row['buyoff_cust_nm'],
              base_optn_typ_cd: row['base_optn_typ_cd'],
              net_spec_cd_comb_txt: row['net_spec_cd_comb_txt'],
              pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
              prod_bom_desc: row['prod_bom_desc'],
              on_hold_flg: row['on_hold_flg'],
              prod_def_id: row['prod_def_id'],
              prod_bom_id: row['prod_bom_id'],
              hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
              hundred_pct_optn_flg: row['hundred_pct_optn_flg'],
              bom_eff_dt_ts: (row['bom_eff_dt_ts']===null) ? '' : row['bom_eff_dt_ts'].replace('Z', '').replace('T', ' '),
              buyoff_dt: (row['buyoff_dt']===null) ? '' : row['buyoff_dt'].replace('Z', '').replace('T', ' '),
              procsg_strt_dt: (row['procsg_strt_dt']===null) ? '' : row['procsg_strt_dt'].replace('Z', '').replace('T', ' '),
              procSuspDate: (row['procsg_sspnsn_dt']===null) ? '' : row['procsg_sspnsn_dt'].replace('Z', '').replace('T', ' '),
              procsg_cmplt_dt: (row['procsg_cmplt_dt']===null) ? '' :
                row['procsg_cmplt_dt'].replace('Z', '').replace('T', ' '),
              procToReport: (row['dm_load_ts']===null) ? '' :
                row['dm_load_ts'].replace('Z', '').replace('T', ' '),
              vin: row['vin'],
              ManAdjsts: row['man_adjsmt_proc_stat_flg'],
              manadjpopup: row['man_adj_rules_dtls'],
              proctosap: (row['sent_to_sap']===null) ? '' :
                row['sent_to_sap'].replace('Z', '').replace('T', ' ')
            }))
            console.info(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.prodProcStatusService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data Storage Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable to get keys and token', 'Failed');
      });
    })
  }

  ///pagination
  changeRowLimits(event) {
    this.onLimitChange(event.target.value);
    this.currentPage = 0;
    this.currentLimit = Number(event.target.value);
    this.getGridData('pagination');
  }

  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.currentPageLimit;
    this.table.recalculate();
  }

  changePageLimit(limit: any): void {
    this.currentPageLimit = parseInt(limit, 10);
  }

  setPageSize(event) {
    this.currentPage = event.page;
    this.getGridData('pagination');
  }

  checkedState(event) {
    console.info(event.source.selected)
    // this.selectedvalue = event.source.selected
    // this.selectedlengthversion = event.value.length
    console.info(Number(this.versioncntval))
    if (event.source.selected.length < Number(this.versioncntval)) {
      this.limitexceed = false;
      console.info(this.limitexceed)
    } else {
      this.limitexceed = true;
      alert('Maximum 20 Version Selected');
      console.info(this.limitexceed)
    }
  }

  marpopup(value) {
    console.info('data', value)
    this.rulestatus = value.split(':')[0]
    this.ruleId = value.split(':')[1]
    $('#failedPopup').modal('show')

  }

  hidepopup() {
    $('#failedPopup').modal('hide')
  }

  dateFrom() {
    console.info("data", this.hidedata)
    if (this.hidedata==="false") {
      console.info("data1")
      if (this.bomEffFromDate !=="") {
        this.bomEffFromTime = "12:00 AM"
      } else {
        this.bomEffFromTime = ""
      }
    }
    else {
      this.bomEffFromTime = ""
      // this.bomEffToTime = ""
    }
  }
  dateTo() {
    console.info("data", this.hidedata)
    if (this.hidedata==="false") {
      console.info("data1")
      if (this.bomEffToDate !=="") {
        // this.bomEffFromTime = "12:00 AM"
        this.bomEffToTime = "11:59 PM"
      } else {
        // this.bomEffFromTime = ""
        this.bomEffToTime = ""
      }
    }
    else {
      // this.bomEffFromTime = ""
      this.bomEffToTime = ""
    }
  }

  toggleAllSelection() {
    console.info(this.allSelected)
    this.allSelected = !this.allSelected
    if (this.allSelected===false) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    console.info(this.allSelected) // to control select-unselect
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => { item.deselect() });
    }
    console.info(this.allSelected)
    // this.skillSel.close();
  }

  optionClick() {
    let newStatus = true;
    this.skillSel.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    console.info(this.allSelected)
  }

  generateMonthlyReport() {
    let tmp_startDate = new Date(this.year, this.monthsArr.indexOf(this.month), 1)
    let tem_endDate = new Date(this.year, this.monthsArr.indexOf(this.month) + 1, 0,)
    this.startDateMR = moment(tmp_startDate).format('YYYY-MM-DD')
    this.endDateMR = moment(tem_endDate).format('YYYY-MM-DD')
    console.info(this.startDateMR, this.endDateMR, "date");
    this.insertemptable();
  }

  insertemptable() {
    let TfileName= localStorage.getItem('Filename')
    let mail = localStorage.getItem('emailId');
    const user = localStorage.getItem('workDayId');
    const body = {
      'mailId': mail,
      
      'from_date': this.startDateMR,
      'to_date': this.endDateMR,
      "screenNo":"NBPC_10115",
        "fileName" :TfileName
    }
    this.SpinnerService.show();
    this.prodProcStatusService.monthlyDataDownload(body).subscribe(res => {
      console.info("res from api", res.payload);
      let response = res.payload
      this.SpinnerService.hide();
      if (response[0].result==="success") {
        this.successMessage = "Monthly Report Process Started, Report Details will be share via Mail"
        this.globalrole.goToTop();
      }
      else {
        this.errorMessage = "Data not updated"
        this.globalrole.goToTop();
      }
    })
  }
}

