import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { FamilyProjectNamcEditDialogService } from './family-project-namc-edit-dialog.service';


@Component({
  selector: 'app-family-project-namc-edit-dialog',
  templateUrl: './family-project-namc-edit-dialog.component.html',
  styleUrls: ['./family-project-namc-edit-dialog.component.css']
})
export class FamilyProjectNamcEditDialogComponent implements OnInit {
  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  fFamily: any = '';
  fProject: any = '';
  fSsn: any = '';
  fNamc: any = '';
  fHikiate: any = '';
  fModelYear: any = '';
  fModelChangeType: any = '';
  fGeneration: any = '';
  fLineOffPlant: any = '';
  fProdDateIn: any = '';
  fProdDateOut: any = '';
  fEffDateIn: any = '';
  fEffDateOut: any = '';
  fComments: any = '';
  fFam_gnrt_proj_relshp_id: any = ""
  //response after insert in a record
  response: any;

  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  projectArray: any = []
  modelChngTypArray: any = []
  generationIdArray: any = []

  constructor(private editfamProNamcService: FamilyProjectNamcEditDialogService,
    private readonly SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<FamilyProjectNamcEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  async ngOnInit(): Promise<void> {
    this.getProjectData();
    this.getModelchngTypData();
    // this.gethikiateData();
    this.getgenerationIdData();
    console.info(this.data);
    this.fFamily = this.data.family;
    this.fProject = this.data.project;
    this.fSsn = this.data.ssn;
    this.fNamc = this.data.namc;
    this.fHikiate = this.data.hikiate
    console.info(this.data.hikiate)

    console.info(this.selectedHikiateLst);
    this.fModelYear = this.data.modelYear;
    this.fGeneration = this.data.generation;
    this.fModelChangeType = this.data.modelChangeType;
    this.fLineOffPlant = this.data.lineOffPlant;
    this.fProdDateIn = this.data.prodDateIn;
    this.fProdDateOut = this.data.prodDateOut;
    this.fEffDateIn = this.data.effectiveDateIn;
    this.fEffDateOut = this.data.effectiveDateOut;
    this.fComments = this.data.comments;
    this.fFam_gnrt_proj_relshp_id = this.data.fam_gnrt_proj_relshp_id;
    console.info(this.data.fam_gnrt_proj_relshp_id)
    let d = await this.gethikiateData();
    if (d) {
      const hikiatelst = this.hikiateLstFromDB
      this.dummyHikiateLstToDB.forEach(hiki => {
        for (let i = 0; i < hikiatelst.length; i++) {
          if (hiki===hikiatelst[i].hikiate) {
            hikiatelst.splice(i, 1);
          }
        }
        this.hikiateLstFromDB = hikiatelst;
      });
      console.info(this.hikiateLstFromDB)
    }
  }
  //hikiate
  moveHikiForward(): void {
    const hikiatelst = this.hikiateLstFromDB
    console.info(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        console.info(i);
        console.info(hiki, hikiatelst[i].hikiate_stat_cd);
        console.info(hiki === hikiatelst[i].hikiate_stat_cd);
        console.info(i);
        if (hiki===hikiatelst[i].hikiate_stat_cd) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    console.info(this.hikiateLstFromDB);
    console.info(this.selectedHikiateLst);
    this.dummyHikiateLst = ""
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
  }
  moveHikiBackward(): void {
    this.dummyHikiateLstToDB.forEach(hiki => {
      const body = {
        "hikiate_stat_cd": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    console.info(this.hikiateLstFromDB)
    console.info(this.selectedHikiateLst);
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate_stat_cd > b.hikiate_stat_cd ? 1 : -1);
      this.dummyHikiateLstToDB = [];
    }
  }



  getProjectData() {
    console.info("data passing", this.fNamc, this.fFamily);

    this.editfamProNamcService.getProjectDropdown(this.fNamc, this.fFamily).subscribe(res => {
      console.info("res from getproj", res);
      this.projectArray = res.payload

    })
  }

  getModelchngTypData() {
    this.editfamProNamcService.getModelChngTypdropdown().subscribe(res => {
      console.info("res from modelchngTyp", res);
      this.modelChngTypArray = res.payload
    })
  }

  gethikiateData() {
    return new Promise(res => {
      this.editfamProNamcService.getHikiateStatusFpn().subscribe(data => {
        console.info(data);
        this.hikiateLstFromDB = data.payload;
        res(true)
      });
    })
  }
  getgenerationIdData() {
    this.editfamProNamcService.getGenerationdropdown().subscribe(res => {
      console.info("res from generation", res);
      this.generationIdArray = res.payload
    })
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  updateFamProNamc() {
    const user = localStorage.getItem('workDayId');
    const fileName= localStorage.getItem('Filename')

    console.info(this.fEffDateIn);

    if (!this.fEffDateIn) {

      this.errorMessage = "Please enter all mandatory field";

    }
    else if (this.fEffDateIn && this.fEffDateOut &&
      this.pipe.transform(this.fEffDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.fEffDateOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";


    }
    else if (this.fProdDateIn && this.fProdDateOut && this.pipe.transform(this.fProdDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.fProdDateOut, 'yyyy-MM-dd')) {

      this.errorMessage = "Prod Time In should be smaller than Prod Time Out";

    }
    else {
      console.info(this.fFam_gnrt_proj_relshp_id)

      this.errorMessage = "";
      if (this.fEffDateOut==="") {
        this.fEffDateOut = "9999-12-31"
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.fEffDateIn !=="") {
        const d1 = moment(this.fEffDateIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.fEffDateIn = date
      }
      if (this.fEffDateOut !=="") {
        const d1 = moment(this.fEffDateOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.fEffDateOut = date
      }
      let prodDateINFormat = ''; let prodDateOutFormat = '';
      if (this.fProdDateIn !=="") {
        const d1 = moment(this.fProdDateIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateINFormat = moment(date).format('YYYY-MM-DD')
        this.fProdDateIn = date

      }
      if (this.fProdDateOut !=="") {
        const d1 = moment(this.fProdDateOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        prodDateOutFormat = moment(date).format('YYYY-MM-DD')
        this.fProdDateOut = date
      }
      if (this.fProject===null) {
        this.fProject = ""
      }
      if (this.fSsn===null) {
        this.fSsn = ""
      }
      if (this.fHikiate===null) {
        this.fHikiate = ""
      }
      if (this.fModelChangeType===null) {
        this.fModelChangeType = ""
      }
      if (this.fGeneration===null) {
        this.fGeneration = ""
      }
      if (this.fLineOffPlant===null) {
        this.fLineOffPlant = ""
      }

      if (this.fComments===null) {
        this.fComments = " "
      }
      if (this.fComments==="") {
        this.fComments = " "
      }
      let cmts;
    if (this.fComments!==undefined) {
      cmts = this.fComments.replaceAll("'", "''");
    } else {
      cmts = this.fComments
    }
      const body = {
        'projCd': this.fProject,
        'ssn': this.fSsn,
        'hikiateCd': this.fHikiate,
        'mdlChgCd': this.fModelChangeType,
        'gnId': this.fGeneration,
        'lneOfPlnt': this.fLineOffPlant,
        'prodInCd': prodDateINFormat,
        'prodOutCd': prodDateOutFormat,
        'extInCd': indateFormat,
        'extOutCd': outdateFormat,
        'rmrkCd': cmts,
        'famGnrtProjRelshpId': this.fFam_gnrt_proj_relshp_id,
        "screenNo":"NBPC_9120",
        "fileName" :fileName
        
      }
      this.SpinnerService.show();
      this.editfamProNamcService.editFamProNamc(body).subscribe(data => {
        this.SpinnerService.hide();
        console.info(data);
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide()
      });
    }
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='fProdDateIn') {
      this.fProdDateIn = "";
    } else if (name==='fProdDateOut') {
      this.fProdDateOut = "";
    }
    else if (name==='fEffDateIn') {
      this.fEffDateIn = "";
    }
    else if (name==='fEffDateOut') {
      this.fEffDateOut = "";
    }
  }
}




