import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
       'x-api-key':localStorage.getItem('Filename')      
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ProductWithinVersionAddDialogService {
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  searchdropdown1UrlApi = environment.Mastersearchdropdown1apiUrl;
  BussinessDropdownApi = environment.BusinesssearchdropdownapiUrl;
  searchdropdown2UrlApi = environment.Mastersearchdropdown2apiUrl;
  version = 'getVersionType';
  versionQual = '/getVerQualBs';
  versionRev = '/getVersionRevision';
  namc = 'getNamcFpn';
  family = '/getFamilyByNamcFpn';
  katashiki = '/getKatashiki';
  destByCust = '/getDestByCust';
  hikiate = '/getHikiateStatus';
  getGridData = '/getPwvAddToVersion'
  addgridversion = '/addInProdWithinVersion'

  constructor(private http: HttpClient) { }

  addprodVerData(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + this.addgridversion
    return this.http.post(API_URL,body,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  getGridDetails(grid): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.getGridData
    return this.http.post(API_URL,grid,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //namc
  getNamcFpn(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown2UrlApi}/${this.namc}`+'?screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //versionType
  getVersionType(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown1UrlApi}/${this.version}`+'?screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //versionRev
  getVersionRev(versionQual): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let revParams = new HttpParams().set('bomVerQualId', versionQual)
    // .append('option', 'ADD')
    const API_URL = this.BussinessDropdownApi + this.versionRev + '?bomVerQualId=' + versionQual + '&option=' + 'ADD'+'&screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family
  getFamilyByNamcFpn(namcId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namcId)

    const API_URL = this.searchdropdown2UrlApi + this.family + '?namcId=' + namcId+'&screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)

      )
  }

  //katashiki
  getKatashikiByFamCd(famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katashiliParams = new HttpParams().set("famCd", famCd);
    const API_URL = this.searchdropdownUrlApi + this.katashiki + '?famCd=' + famCd+'&screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //dest
  getDestByCust(namcId, famCd, katashikiCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let custParams = new HttpParams().set('namcId', namcId)
    //   .append('famCd', famCd)
    //   .append('katashikiCd', katashikiCd)
    const API_URL = this.searchdropdownUrlApi + this.destByCust + '?namcId=' + namcId
      + '&famCd=' + famCd + '&katashikiCd=' + katashikiCd+'&screenNo=NBPC_10110'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )

  }

  //versionQual
  getQualifier(versionTyp): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let qualParams = new HttpParams().set('bomVerTypeId', versionTyp)
    const API_URL = this.searchdropdown1UrlApi + this.versionQual + '?bomVerTypeId=' + versionTyp+'&screenNo=NBPC_10110'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //hikiate status
  getHikiate(hikiObj): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.searchdropdownUrlApi + this.hikiate + '?namcId=' + hikiObj.namc
      + '&famCd=' + hikiObj.family + '&katashikiCd=' + hikiObj.katashiki
      // + '&custId=' + hikiObj.cust
      + '&destId=' + hikiObj.dest +'&screenNo=NBPC_10110'+'&fileName=' +fileName;
    // let hikiParams = new HttpParams().set('namcId', hikiObj.namc)
    //   .append('famCd', hikiObj.family)
    //   .append('katashikiCd', hikiObj.katashiki)
    //   // .append('custId', hikiObj.cust)
    //   .append('destId', hikiObj.dest);
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )

  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }

}
