import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner"; 


@Component({
  selector: 'app-part-source-routing-view-dialog',
  templateUrl: './part-source-routing-view-dialog.component.html',
  styleUrls: ['./part-source-routing-view-dialog.component.css']
})
export class PartSourceRoutingViewDialogComponent implements OnInit {

   //ngModelValues
   viewNamc:any;
   viewCompletePartRouting:any;
   viewPartSource:any;
   viewEffectiveInDate:any;
   viewEffectiveOutDate:any;
   viewComments:any;
   spinnerMessage="Please Wait...";


  
  constructor(   private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    console.info(this.data);
    this.viewNamc=this.data.namc;
    this.viewCompletePartRouting=this.data.completePartRouting;
    this.viewPartSource=this.data.partSource;
    this.viewEffectiveInDate=this.data.effectiveInDate;
    this.viewEffectiveOutDate=this.data.effectiveOutDate;
    this.viewComments=this.data.comments
   
  }

}
