import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { isDate } from 'moment';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { PartSourceAdjustmentAddDialogService } from './part-source-adjustment-add-dialog.service';

@Component({
  selector: 'app-part-source-adjustment-add-dialog',
  templateUrl: './part-source-adjustment-add-dialog.component.html',
  styleUrls: ['./part-source-adjustment-add-dialog.component.css']
})
export class PartSourceAdjustmentAddDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  currentdateval = new Date();

  //to main page
  message = "Records Updated Successfully";
  index: any;
  errorMessage = "";

  [x: string]: any;
  //ngModelValues
  namc: any = "";
  family: any = "";
  partNumber: any = "";
  bomEffectiveFrom: any = "";
  bomEffectiveTo: any = "";
  versionName: any = "";
  katashiki: any = "";
  status: any = "";
  comments: any = "";
  entered = [];
  spinnerMessage = "Please Wait...";


  //response after insert in a record
  response: any;

  //list
  namcLst: any[] = [];
  versionNameLst: any[] = [];
  familyLst: any[] = [];
  partNumberLst: any[] = [];
  katashikiLst: any[] = [];
  hidedata: any = false;

  constructor(private addPartSourceAdjService: PartSourceAdjustmentAddDialogService, private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<PartSourceAdjustmentAddDialogComponent>) {
    this.maxDate = new Date();
  }
  dateCheck() {
    this.errorMessage = ''
    this.fromDialog(this.entered)

    let fromDate: any = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
    let toDate: any = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
    const fromDateformat = moment(fromDate, 'YYYY-MM-DD', true).isValid()
    const toDateformat = moment(toDate, 'YYYY-MM-DD', true).isValid()
    if (fromDateformat !== true || toDateformat !== true) {
      console.info(fromDate, fromDateformat);
      console.info(toDateformat);
      this.errorMessage = 'Please provide Bom Effective Dates in this format [YYYY-MM-DD]'
    } else if (fromDate > toDate) {
      this.errorMessage = 'Effective To should be greater than or equal to Effective From'
    }
    else if (toDate > moment().format('YYYY-MM-DD')) {
      this.errorMessage = 'Effective To should be less than or equal to current date'
    }
  }

  getdatecheck(){
    this.errorMessage = ''
    let fromDate: any = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
    let toDate: any = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
    if (fromDate > toDate) {
      this.errorMessage = 'Effective To should be greater than or equal to Effective From'
    }
    else if (toDate > moment().format('YYYY-MM-DD')) {
      this.errorMessage = 'Effective To should be less than or equal to current date'
    }
  }

  fromDialog(entered): void {
    entered.forEach(row => {
      this.dialogData.bomEffectiveFrom = row.bomEffectiveFrom
      this.dialogData.bomEffectiveTo = row.bomEffectiveTo
    });

  }
  ngOnInit(): void {
    //to get namc
    // this.getnamc();


    //to get family
    // this.getfamily();

    //to get katashiki
    // this.getkatashiki();

    //to get version Name
    this.addPartSourceAdjService.getVersionNamePsa().subscribe(data => {
      console.info(data);
      this.versionNameLst = data.payload;
    });
  }


  //onchange
  getnamc() {
    if (this.versionName===undefined) {
      this.versionName = ""
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.bomEffectiveFrom !=="" ) {
      const d1 = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveFrom = date
    }
    if (this.bomEffectiveTo !=="") {
      const d1 = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveTo = date
    }

    if (this.versionName !=='0') {
      indateFormat = null
      outdateFormat = null
    }
    if (this.versionName !=="0" && this.versionName !=="") {
      this.SpinnerService.show()
      this.addPartSourceAdjService.getNamcPsa(this.versionName, indateFormat, outdateFormat).subscribe(data => {
        console.info(data);
        this.namcLst = data.payload;
        this.SpinnerService.hide()
        if (this.versionName !=='0' || this.versionName !=='') {
          this.namc = ""
        }
      });
      this.getVerEffDate()
    } else if (this.versionName==='0' && (outdateFormat !=='' && indateFormat !=='')) {
      this.SpinnerService.show()
      this.addPartSourceAdjService.getNamcPsa(this.versionName, indateFormat, outdateFormat).subscribe(data => {
        console.info(data);
        this.namcLst = data.payload;
        this.SpinnerService.hide()
        if (this.versionName !=='0' || this.versionName !=='') {
          this.namc = ""
        }
      });
      this.getdatecheck();
      this.getVerEffDate()
    }
  }
  //onchange
  getfamily() {
    if (this.namc===undefined) {
      this.namc = ""
    }
    if (this.versionName===undefined) {
      this.versionName = ""
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.bomEffectiveFrom !=="" ) {
      const d1 = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveFrom = date
    }
    if (this.bomEffectiveTo !=="") {
      const d1 = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveTo = date
    }

    if(this.versionName !=='0'){
      indateFormat = ""
      outdateFormat = ""
    }
    if (this.namc !=="") {
      this.SpinnerService.show()
      this.addPartSourceAdjService.getFamilyPsa(this.versionName, this.namc,indateFormat, outdateFormat).subscribe(data => {
        console.info(data);
        this.familyLst = data.payload;
        this.SpinnerService.hide()
        this.family = ""
      }, err => {
        this.familyLst = []
        this.SpinnerService.hide()
        this.family = ""
      });
    } else {
      this.familyLst = []
      this.SpinnerService.hide()
      this.family = ""
    }
  }

  // onchange
  getkatashiki() {
    if (this.namc===undefined) {
      this.namc = ""
    }
    if (this.versionName===undefined) {
      this.versionName = ""
    }
    if (this.family===undefined) {
      this.family = ""
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.bomEffectiveFrom !=="" ) {
      const d1 = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveFrom = date
    }
    if (this.bomEffectiveTo !=="") {
      const d1 = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.bomEffectiveTo = date
    }

    if(this.versionName !=='0'){
      indateFormat = ""
      outdateFormat = ""
    }
    this.SpinnerService.show()
    this.addPartSourceAdjService.getKatashikiPsa(this.versionName, this.namc, this.family,indateFormat, outdateFormat).subscribe(data => {
      console.info(data);
      this.katashikiLst = data.payload;
      this.SpinnerService.hide()
      this.katashiki = ""
    });
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }

  SavePartSourceAdj() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.family, this.namc, this.bomEffectiveFrom, this.bomEffectiveTo, this.versionName,
      this.katashiki, this.partNumber);

    if (!this.namc || !this.family || !this.versionName ||
      !this.katashiki || !this.partNumber) {

      this.errorMessage = "Please enter all mandatory field";
    }
    else if ((this.hidedata===false) && !this.bomEffectiveFrom) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if ((this.hidedata===false) && !this.bomEffectiveTo) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.bomEffectiveFrom && this.bomEffectiveTo &&
      this.pipe.transform(this.bomEffectiveFrom, 'yyyy-MM-dd') > this.pipe.transform(this.bomEffectiveTo, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective To should be greater than or equal to Effective From";
    }
    else {
      let createby = localStorage.getItem('workDayId')
      console.info("bomEffectiveFrom", this.bomEffectiveFrom);
      console.info("bomEffectiveTo", this.bomEffectiveTo);
      let versionname;
      console.info("versionname", this.versionName);
      for (let i = 0; i < this.versionNameLst.length; i++) {
        if (this.versionName===this.versionNameLst[i].rep_bom_ver_id) {
          versionname = this.versionNameLst[i].rep_bom_ver_nm
          break;
        }
      }
      if(this.versionName==='0'){
        versionname = 'Production'
      }
      console.info("versionId", versionname, this.versionNameLst);
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.bomEffectiveFrom !=="") {
        const d1 = moment(this.bomEffectiveFrom).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.bomEffectiveFrom = date
      }
      if (this.bomEffectiveTo !=="") {
        const d1 = moment(this.bomEffectiveTo).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.bomEffectiveTo = date
      }
      let cmts;
    if (this.comments!==undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      const body = {
        namc_lgl_entity_id: this.namc,
        part_no: this.partNumber,
        bom_eff_dt_from: indateFormat,
        fam_cd: this.family,
        bom_eff_dt_to: outdateFormat,
        rep_bom_ver_nm: versionname,
        rep_bom_ver_id: this.versionName,
        katashiki_cd: this.katashiki,
        comment: cmts,
        "screenNo":"NBPC_10122",
        "fileName" :fileName
      }
      this.SpinnerService.show()
      this.addPartSourceAdjService.addPArtSrcAdj(body).subscribe(data => {
        console.info("res", this.response);
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
          this.SpinnerService.hide()
        } else if (this.response[0].result==='Partnum_Invalid') {
          this.errorMessage = 'Part Number Invalid';
          this.SpinnerService.hide()
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Data Already Exists';
          // this.dialogRef.close(this.response[0].result);
          this.SpinnerService.hide()
        }
      },err=>{
        this.SpinnerService.hide()
      });
    }

  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  getVerEffDate() {
    console.info(this.versionName)
    if (this.versionName==="0") {
      // this.versionName = 0;
      this.hidedata = false;
      console.info(this.hidedata);
    } else if (this.versionName==="") {
      this.hidedata = false;
      console.info(this.hidedata)
    }
    else {
      this.hidedata = true;
      this.bomEffectiveFrom = ""
      this.bomEffectiveTo = ""
      console.info(this.hidedata)
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='bomEffectiveFrom') {
      this.bomEffectiveFrom = "";
    } else if (name==='bomEffectiveTo') {
      this.bomEffectiveTo = "";
    }
  }
}
