import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { PlantUserCodeAddDialogService } from './plant-user-code-add-dialog.service';


@Component({
  selector: 'app-plant-user-code-add-dialog',
  templateUrl: './plant-user-code-add-dialog.component.html',
  styleUrls: ['./plant-user-code-add-dialog.component.css']
})
export class PlantUserCodeAddDialogComponent implements OnInit {

  spinnerMessage = "Please Wait...";
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  [x: string]: any;
  //to main page
  message = "Records Updated Successfully";
  index: any;
  errorMessage = "";

  //ngModelValues
  namc: any = '';
  productType: any = '';
  userCode: any = '';
  family: any = '';
  effectiveInDate: any = '';
  effectiveOutDate: any = '';
  activeFlag: any = "";
  aGrpi: any = "";
  namcArray: any = [];
  familyArray: any = [];
  productArray: any = [];
  grpitext: boolean = false;
  //response after insert in a record
  response: any;
  constructor(private addPlntUserService: PlantUserCodeAddDialogService, 
  private readonly SpinnerService: NgxSpinnerService, public dialogRef: MatDialogRef<PlantUserCodeAddDialogComponent>) { }

  ngOnInit(): void {
    console.info("data inside add", this.data);
    this.getNamcData();
    // this.getFamilyData();
    this.getProductData();
  }

  getNamcData() {
    this.SpinnerService.show();
    this.addPlntUserService.getNamcdropdown().subscribe(response => {
      console.info("res from getnamc", response);
      this.SpinnerService.hide();
      this.namcArray = response.payload
    })
  }
  getFamilyData() {
    if (this.namc===undefined) {
      this.namc = ""
    }
    else if (this.namc !=="") {
      this.SpinnerService.show();
      this.addPlntUserService.getFamilydropdown(this.namc).subscribe(response => {
        console.info("res from getfamily", response);
        this.SpinnerService.hide();
        this.familyArray = response.payload
        this.family = "";
      }, err => {
        this.SpinnerService.hide();
      })
    }
  }
  getProductData() {
    this.SpinnerService.show();
    this.addPlntUserService.getProductdropdown().subscribe(response => {
      console.info("res from getproduct:", response);
      this.SpinnerService.hide();
      this.productArray = response.payload
    })
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  updatePlantUserCode() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.family, this.productType, this.namc, this.userCode, this.effectiveInDate, this.aGrpi);
    if (!this.effectiveInDate || !this.family || !this.productType || !this.userCode || !this.namc) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.effectiveInDate && this.effectiveOutDate &&
      this.pipe.transform(this.effectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.effectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else if ((this.grpitext===true) && !this.aGrpi) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      if (this.effectiveOutDate==="") {
        this.effectiveOutDate = "9999-12-31"
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.effectiveInDate !=="") {
        const d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveInDate = date
      }
      if (this.effectiveOutDate !=="") {
        const d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effectiveOutDate = date
      }
      let activeFlag
      if (this.activeFlag===true) {
        activeFlag = 'Y'
      } else {
        activeFlag = 'N'
      }
      const body = {
        'namc_lgl_entity_id': this.namc,
        'serl_no': this.userCode,
        'prod_typ_cd': this.productType,
        'fam_cd': this.family,
        'eff_dt': indateFormat,
        'expr_dt': outdateFormat,
        'incl_flg': activeFlag,
        'grpi_cd': this.aGrpi,        
        "screenNo":"NBPC_9991",
        "fileName" :fileName
      }

      this.SpinnerService.show();
      this.addPlntUserService.existscheckPlntUsrCode(body).subscribe(data => {
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result==='Data Already Exists') {
          this.errorMessage = "Data Already Exists";
          this.SpinnerService.hide();
        } else if (this.response[0].result==='success') {
          this.AddPlantUser();
        } else if (this.response[0].result==='Invalid') {
          this.errorMessage = "Data Invalid";
          this.SpinnerService.hide();
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }

  AddPlantUser() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId');
    let actFlgdata
    if (this.effectiveOutDate==="") {
      this.effectiveOutDate = "9999-12-31"
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.effectiveInDate !=="") {
      const d1 = moment(this.effectiveInDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.effectiveInDate = date
    }
    if (this.effectiveOutDate !=="") {
      const d1 = moment(this.effectiveOutDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.effectiveOutDate = date
    }
    if (this.activeFlag===true) {
      actFlgdata = 'Y'
    } else {
      actFlgdata = 'N'
    }
    this.errorMessage = "";
    const body = {
      'namc_lgl_entity_id': this.namc,
      'serl_no': this.userCode,
      'prod_typ_cd': this.productType,
      'fam_cd': this.family,
      'eff_dt': indateFormat,
      'expr_dt': outdateFormat,
      'incl_flg': actFlgdata,
      'grpi_cd': this.aGrpi,
      "screenNo":"NBPC_9991",
      "fileName" :fileName     
      
    }
    this.SpinnerService.show();
    this.addPlntUserService.addPlntUsrCode(body).subscribe(data => {
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result==='success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  getgrpi() {
    console.info(this.productType)
    if (this.productType==="AC") {
      this.grpitext = true;
    } else if (this.productType==="A") {
      this.grpitext = true;
    } else {
      this.grpitext = false;
    }
  }

  clearDate(event, name) {
    console.info(name,name==='effectiveInDate')
    event.stopPropagation();
    if (name==='effectiveInDate') {
      this.effectiveInDate = "";
    } else if (name==='effectiveOutDate') {
      this.effectiveOutDate = "";
    }
  }
}

