import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class MajorPartlstAddDialogService {


  searchdropdown1UrlApi = environment.Mastersearchdropdown1apiUrl;
  // searchdropdown2UrlApi = environment.Mastersearchdropdown2apiUrl;
  namc = '/getNamc';
  family = '/getFamByNamcMpt'
  katashiki = '/getKatDdRepMpt'
  katas = '/getKatDdMfrdMpt'
  // majPartFam = 'getMajorPartListFamily';
  majPartTyp = '/getMajorPartTypeList';
  partNoRpd = '/getPartDdRepMpl';
  partNoMfrd = '/getPartDdMfrdMpl';
  // majProdTyp = 'getMajorProductTypeList';
  prodTypByMajPart = '/getProductByMajorPart';
  activePartCode = '/getActivePrtCdByPrdctType';
  inactivePartCode = '/getInactvePrtCdByPrdctType';
  qtyComments = '/getExpPtQtyNdCmntsByPdctTpe';
  constructor(private http: HttpClient) { }

  checkMajPartLst(data): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${environment.Master_ActionInsertApiUrl}/${'addMajParLstChk'}`;
    return this.http.post(API_URL, data, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //add
  addMajPartLst(data): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${environment.Master_ActionInsertApiUrl}/${'addMajParLst'}`;
    return this.http.post(API_URL, data, httpOptions)
      .pipe(
        catchError(this.error)
      )

  }
  updateMajParLst(data): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${environment.Master_ActionInsertApiUrl}/${'updateMajParLst'}`;
    return this.http.post(API_URL, data, httpOptions)
      .pipe(
        catchError(this.error)
      )

  }

  // getMajPartFam(): Observable<any> {
  //   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  //   var httpOptions;
  //   if (isIEOrEdge) {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': cntType,
  //         'Cache-Control': 'no-cache',
  //         'Pragma': 'no-cache',
  //         'Expires': exp,
  //       })
  //     };
  //   } else {
  //     httpOptions = {
  //       headers: new HttpHeaders({
  //         'Content-Type': cntType,
  //         'Authorization': localStorage.getItem('Ebom_accessToken')
  //       })
  //     };
  //   }
  //   const API_URL = `${this.searchdropdown1UrlApi}/${this.majPartFam}`;
  //   return this.http.get(API_URL, httpOptions)
  //     .pipe(
  //       catchError(this.error)
  //     )
  // }
  //majPartTyp
  getMajPartTyp(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Mastersearchdropdown1apiUrl + this.majPartTyp + '?screenName=MPT'+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //namc
  getNamc(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
        'Authorization': localStorage.getItem('Ebom_accessToken'),
        'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
  const API_URL = environment.MastersearchdropdownapiUrl + this.namc+'?screenNo=NBPC_2220'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //family 
  getfamByNamc(namcId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Business_Qe_dropdownapiURL + this.family + '?namcId=' + namcId+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //katashiki
  getkatashikiByFam(namcId, famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
        'Authorization': localStorage.getItem('Ebom_accessToken'),
        'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
  const API_URL = environment.Business_Qe_dropdownapiURL + this.katashiki+ '?namcId='+ namcId + "&famCd=" + famCd+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //katashiki from mfrd tbl
  getkatasByFam(namcId, famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Business_Qe_dropdownapiURL + this.katas+ '?namcId='+ namcId + "&famCd=" + famCd+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //part no from rpd tbl
  getpartNoRpd(namcId, famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Business_Qe_dropdownapiURL + this.partNoRpd+ '?namcId='+ namcId + "&famCd=" + famCd+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //part no by mfrd tbl
  getpartNoMfrd(namcId, famCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Business_Qe_dropdownapiURL + this.partNoMfrd+ '?namcId='+ namcId + "&famCd=" + famCd +'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //prodTypByMajPartNm
  getProdTypByMajPartNm(majPartNm): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Mastersearchdropdown1apiUrl + this.prodTypByMajPart + '?majPartNm=' + majPartNm +'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    // let verParams = new HttpParams().set('majPartNm', majPartNm)
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //activepart code
  getActiveByProd(majPartTypNm, famCd, prodTypCd, namcId, partNo, katashikiCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let activeParams = new HttpParams().set('majPartTypNm', majPartTypNm)
    //   .append('famCd', famCd)
    //   .append('prodTypCd', prodTypCd)
    //   .append('namc', namc)
    //   .append('prodPartNo', parentpartValue)
    //   .append('katashiki', katashikivalue)
    const API_URL = environment.Mastersearchdropdown1apiUrl + this.activePartCode + "?majPartTypNm=" + majPartTypNm + "&famCd=" + famCd + "&prodTypCd=" + prodTypCd + "&namcId=" + namcId + "&katashikiCd=" + katashikiCd + "&partNo=" + partNo+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //inactivepart code
  getinactiveByProd(majPartTypNm, famCd, prodTypCd, namcId, partNo, katashikiCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let inactiveParams = new HttpParams().set('majPartTypNm', majPartTypNm)
    //   .append('famCd', famCd)
    //   .append('prodTypCd', prodTypCd)
    //   .append('namc', namc)
    //   .append('prodPartNo', parentpartValue)
    //   .append('katashiki', katashikivalue)
    const API_URL = environment.Mastersearchdropdown1apiUrl + this.inactivePartCode + "?majPartTypNm=" + majPartTypNm
      + "&famCd=" + famCd + "&prodTypCd=" + prodTypCd + "&namcId=" + namcId + "&katashikiCd=" + katashikiCd + "&partNo=" + partNo+'&screenNo=NBPC_2220'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //expctedQtyandComments
  getQtyComments(majPartNm, famCd, namcId, partNo, katashikiCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let commParams = new HttpParams().set('majPartNm', majPartNm)
    //   .append('famCd', famCd)
    //   .append('namc', namc)
    //   .append('prodPartNo', parentpartValue)
    //   .append('katashiki', katashikivalue)
    const API_URL = environment.Mastersearchdropdown1apiUrl + this.qtyComments + "?majPartNm=" + majPartNm + "&famCd=" + famCd + "&namcId=" + namcId + "&katashikiCd=" + katashikiCd + "&partNo=" + partNo+'&screenNo=NBPC_2220'+'&fileName=' +fileName;

    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //CheckPartNo
  CheckpartNo(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Mastersearchdropdown2apiUrl + '/addPartCodeValidation';
    return this.http.post(API_URL, body, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }



  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }
}
