import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import * as print from 'print-js';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { GenerateActProdStatusService } from './generate-act-prod-status.service'
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductWithinVersion } from '../dialog-data/product-within-version';
import { data } from 'jquery';


@Component({
  selector: 'app-generate-act-prod-status',
  templateUrl: './generate-act-prod-status.component.html',
  styleUrls: ['./generate-act-prod-status.component.css']
})
export class GenerateActProdStatusComponent implements OnInit {

  //export
  fileName = "ActualProductionStatus";
  exportData = [];
  sheetName = [];
  headerArray = []
  namcArray = []

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('prodStat') table;
  limitSelected: any = 5;
  DataArray: any = [];
  bomEffDate: any;
  gridList: any = [];
  displayedColumns: string[] = []
  dataSource = new MatTableDataSource<any>(this.gridList);
  columns = [
    { name: 'month', prop: 'month' }, { name: 'procStatus', prop: 'procStatus' },
    { name: 'tmmbc', prop: 'tmmbc' }, { name: 'vinTotal', prop: 'vinTotal' },
    { name: 'grandTotal', prop: 'grandTotal' }
  ];
  ColumnMode = ColumnMode;
  mainArray: any[];
  namcLst: any;
  displayheaderArray: any[];
  vinCount: any = 0;
  unitCount: any = 0;
  productTyp: any;
  showdata: boolean = true;
  counter: any = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ProductWithinVersion,
    private exportService: ExportToExcelService,
    public dialogRef: MatDialogRef<GenerateActProdStatusComponent>,
    private genActProdStatService: GenerateActProdStatusService,
    private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.productTyp = this.dialogData.productType
    this.SpinnerService.show()
    this.fetchActProdStatus();
  }

  fetchActProdStatus() {
    let flg = this.productTyp==="onHold" ? 'Y' : ''
    console.info(flg);
    this.genActProdStatService.genActProdStatus(flg).subscribe(res => {
      console.info("res from getactprod satus grid", res.payload);
      this.namcLst = res.payload
      if (this.namcLst.length !==0) {
        if (this.namcLst[0].namc_nm !==null) {
          this.showdata = true
          this.fetchActProdStatCnt(this.namcLst, flg)
          this.formSpan()
        } else {
          this.SpinnerService.hide();
          this.showdata = false

        }
      }
    }, err => {


      if (err===0) {
        console.info("this.counter", this.counter);
        if (this.counter <= 2) {
          this.counter++;
          this.fetchActProdStatus();
        } else if (this.counter===3) {
          this.SpinnerService.hide()
          this.showdata = false
          this.dialogRef.close("Request timed out. Please try again.")
        } else {
          this.SpinnerService.hide()
          this.showdata = false
          console.info("err", err);
          this.dialogRef.close("Data not found")
        }
      } else {
        this.SpinnerService.hide()
        this.showdata = false
        console.info("err", err);
        // this.dialogRef.close("Data not found")
      }
    });
  }

  fetchActProdStatCnt(data, flg) {
    const fileName= localStorage.getItem('Filename')
    this.SpinnerService.show()
    let datanamc = data[0].namc_nm
    let change = "('" + datanamc.split(" int,").join("'),('")
    let finalChange = change.split(' int').join("')")
    console.info(change)
    console.info('finalchange', finalChange)
    const body = {
      'frmtNamcLst': finalChange,
      'namcLst': data[0].namc_nm,
      'onHold': flg,
      "screenNo":"NBPC_10115",
      "fileName" :fileName
    }
    console.info("body", body);

    this.genActProdStatService.genActProdStatusCnt(body).subscribe(res => {
      console.info("res from getactprod satus count", res.payload);
      this.DataArray = res.payload;
      console.info(this.DataArray)
      this.dataAssign();
      // this.SpinnerService.hide()
    })
  }


  formSpan() {
    let data = this.namcLst[0].namc_nm
    let formatData = data.replace(/["']/g, "")
    console.info("formatData", formatData);

    let splitData = []
    splitData = formatData.split(',')

    let vinArray = []; let unitArray = [];
    let vinArrayDisplay = []; let unitArrayDisplay = []
    console.info("splitData", splitData);
    for (let i = 0; i < splitData.length; i++) {
      if (splitData[i].includes('VIN')) {
        let x = splitData[i].split(' ')[0]

        vinArray.push(x)
      }
      if (splitData[i].includes('UNIT')) {
        let x = splitData[i].split(' ')[0]
        unitArray.push(x)
      }
      if (splitData[i].includes('VIN')) {
        let x = splitData[i].split('_')[0]
        this.vinCount++;
        vinArrayDisplay.push(x)
      }
      if (splitData[i].includes('UNIT')) {
        let x = splitData[i].split('_')[0]
        this.unitCount++
        unitArrayDisplay.push(x)
      }
      let d = splitData[i].split(' ')[0]
      this.namcArray.push(d)
    }

    vinArray.unshift('Month', 'Processing_Status');
    vinArray.push('VIN Total')
    unitArray.push('UNIT Total', 'Grand Total')
    this.headerArray = vinArray.concat(unitArray)
    vinArrayDisplay.unshift('Month', 'Processing_Status');
    vinArrayDisplay.push('VIN Total')
    unitArrayDisplay.push('UNIT Total', 'Grand Total')
    this.displayheaderArray = vinArrayDisplay.concat(unitArrayDisplay)
    // console.info("vinArray", vinArray);
    // console.info("unitArray", unitArray);
    console.info("displayheaderArray", this.displayheaderArray);
    console.info("headerArray", this.headerArray);
    console.info("namcArray", this.namcArray);
  }

  dataAssign() {
    let detailArray = []; let data = []
    detailArray = this.DataArray
    let obj = {};
    for (let i of this.headerArray) {
      obj[i] = ''
    }
    data.push(obj)
    console.info("obj", obj);


    detailArray = detailArray.map(function (el, index) {
      var o = Object.assign({}, el);
      o.month = detailArray[index].monthstatus.substring(0, 3);
      o.status = detailArray[index].monthstatus.substring(4, detailArray[index].monthstatus.length)
      let obj = {}
      obj = detailArray[index]
      console.info("obj", obj);
      o.vin_sum = 0; o.unit_sum = 0; o.total_sum = 0
      for (const key in obj) {
        // o.Vin_sum = 0; o.unit_sum = 0;o.total_sum = 0
        if (key.includes('vin')) {
          o.vin_sum += obj[key];
        }
        if (key.includes('unit')) {
          o.unit_sum += obj[key];
        }
        o.total_sum = o.vin_sum + o.unit_sum
      }
      return o;
    })

    console.info("detailArray", detailArray);
    let rowcnt = 0;

    let finalArray = []
    for (let i = 0; i < this.DataArray.length; i++) {
      let count = 0; let result = []; finalArray[i] = []
      result = Object.values(this.DataArray[i])
      // console.info("result",result);

      for (let j = 0; j < result.length; j++) {
        // console.info("result",j,result[j]);

        if (j===0) {
          let month = result[j].split('_')[0]
          let status = result[j].split('_')[1]
          console.info("month : status", month, status);

          finalArray[rowcnt][count] = month
          count++
          finalArray[rowcnt][count] = status
          count++
        }
        else {
          finalArray[rowcnt][count] = result[j]
          count++
        }
        // console.info("finalArray",finalArray);

      }
      rowcnt++;


    }
    console.info("finalArray", finalArray);
    console.info("vinCount", this.vinCount);
    console.info("unitcount", this.unitCount);
    let displayArray = []; let rowTotal = []
    let row = 0; let col = 0; let grandTotal = []
    for (let i = 0; i < finalArray.length; i++) {
      displayArray[row] = []; let rowTotalFlg: boolean = false;
      let vin_total = 0; let unit_total = 0; col = 0
      for (let j = 0; j < finalArray[i].length; j++) {
        if (j===0) {
          if (i===0) {
            if (finalArray.length > 1 && finalArray[i + 1][j] !==finalArray[i][j]) {
              rowTotalFlg = true;
              displayArray[row][col] = finalArray[i][j]
              rowTotal[col] = displayArray[row][col]
              console.info('rowTotal:247', rowTotal[col])
              grandTotal[col] += displayArray[row][col]
              console.info('grandTotal:249', grandTotal[col])
              col++
            } else {
              // rowTotalFlg = true;
              displayArray[row][col] = finalArray[i][j]
              rowTotal[col] = displayArray[row][col]
              console.info('rowTotal:247', rowTotal[col])
              grandTotal[col] += displayArray[row][col]
              console.info('grandTotal:249', grandTotal[col])
              col++
            }

          }
          //new added
          else if (i > 0 && i===finalArray.length - 1) {
            if (finalArray[i - 1][j] !==finalArray[i][j]) {
              console.info("inside 254");
              rowTotalFlg = true;
              displayArray[row][col] = finalArray[i][j]
              console.info('257', finalArray[i][j]);
              rowTotal[col] = finalArray[i][j]
              console.info('rowTotal:282', rowTotal[col])
              grandTotal[col] = finalArray[i][j]
              console.info('grandTotal:284', grandTotal[col])
              col++
            } else {
              console.info("inside 254");
              rowTotalFlg = true;
              displayArray[row][col] = null
              console.info('257', finalArray[i][j]);
              rowTotal[col] = finalArray[i][j]
              console.info('rowTotal:282', rowTotal[col])
              grandTotal[col] = finalArray[i][j]
              console.info('grandTotal:284', grandTotal[col])
              col++
            }

          }
          else if (i > 0 && i < finalArray.length - 1 && finalArray[i + 1][j] !==finalArray[i][j] && finalArray[i - 1][j] !==finalArray[i][j]) {
            console.info("inside 242");
            rowTotalFlg = true;
            displayArray[row][col] = finalArray[i][j]
            console.info('248', finalArray[i][j], finalArray[i + 1][j]);
            rowTotal[col] = finalArray[i][j]
            console.info('rowTotal:294', rowTotal[col])
            grandTotal[col] = finalArray[i][j]
            console.info('grandTotal:296', grandTotal[col])
            col++
          }
          else if (i > 0 && i < finalArray.length - 1 && finalArray[i + 1][j] !==finalArray[i][j]) {
            console.info("inside 242");
            rowTotalFlg = true;
            displayArray[row][col] = null
            console.info('248', finalArray[i][j], finalArray[i + 1][j]);
            rowTotal[col] = finalArray[i][j]
            console.info('rowTotal:294', rowTotal[col])
            grandTotal[col] = finalArray[i][j]
            console.info('grandTotal:296', grandTotal[col])
            col++
          }
          else if (i > 0 && finalArray[i][j] !==finalArray[i - 1][j]) {
            ///display row count
            // rowTotalFlg = true;
            console.info("row:col:253", row, col, i, j, finalArray[i][j], finalArray[i - 1][j]);
            displayArray[row][col] = finalArray[i][j]
            rowTotal[col] = displayArray[row][col]
            console.info('rowTotal:268', rowTotal[col])
            grandTotal[col] = displayArray[row][col]
            console.info('grandTotal:271', grandTotal[col])
            // rowTotal[col] = finalArray[i - 1][j] + ' Total'
            col++
          }

          else {
            console.info("inside 250", row, col);
            displayArray[row][col] = null
            rowTotal[col] = displayArray[row][col]
            console.info('rowTotal:291', rowTotal[col])
            grandTotal[col] = displayArray[row][col]
            console.info('grandTotal:293', grandTotal[col])
            console.info("inside 254", rowTotal[col]);
            col++
          }
          console.info("i", i);
        }

        else if (j===1) {
          console.info("row:col", row, col, i, j, finalArray[i][j]);
          displayArray[row][col] = finalArray[i][j]
          rowTotal[col] = displayArray[row][col]
          grandTotal[col] = displayArray[row][col]
          col++
          console.info("displayArray in 272", displayArray);

        }
        else {
          if (j < this.vinCount + 2) {
            if (finalArray[i][j] !==null) {
              vin_total += finalArray[i][j]
            }
            displayArray[row][col] = finalArray[i][j]
            if (rowTotal[col]===undefined || rowTotal[col]===null) {
              rowTotal[col] = 0
            }
            if (grandTotal[col]===undefined || grandTotal[col]===null) {
              grandTotal[col] = 0
            }
            rowTotal[col] += displayArray[row][col]
            grandTotal[col] += displayArray[row][col]
            col++

          }
          if (j===this.vinCount + 1) {
            displayArray[row][col] = vin_total
            if (rowTotal[col]===undefined || rowTotal[col]===null) {
              rowTotal[col] = 0
            }
            if (grandTotal[col]===undefined || grandTotal[col]===null) {
              grandTotal[col] = 0
            }
            rowTotal[col] += displayArray[row][col]
            grandTotal[col] += displayArray[row][col]
            col++
          }
          if (j > this.vinCount + 1 && j < this.unitCount + this.vinCount + 2) {
            if (finalArray[i][j] !==null) {
              unit_total += finalArray[i][j]
            }
            displayArray[row][col] = finalArray[i][j]
            if (rowTotal[col]===undefined || rowTotal[col]===null) {
              rowTotal[col] = 0
            }
            if (grandTotal[col]===undefined || grandTotal[col]===null) {
              grandTotal[col] = 0
            }
            rowTotal[col] += displayArray[row][col]
            grandTotal[col] += displayArray[row][col]
            col++
          }
          if (j===this.unitCount + this.vinCount + 1) {
            displayArray[row][col] = unit_total
            if (rowTotal[col]===undefined || rowTotal[col]===null) {
              rowTotal[col] = 0
            }
            if (grandTotal[col]===undefined || grandTotal[col]===null) {
              grandTotal[col] = 0
            }
            rowTotal[col] += displayArray[row][col]
            grandTotal[col] += displayArray[row][col]
            col++
            displayArray[row][col] = vin_total + unit_total
            if (rowTotal[col]===undefined || rowTotal[col]===null) {
              rowTotal[col] = 0
            }
            if (grandTotal[col]===undefined || grandTotal[col]===null) {
              grandTotal[col] = 0
            }
            rowTotal[col] += displayArray[row][col]
            grandTotal[col] += displayArray[row][col]
            col++
          }
        }
      }
      console.info("row top", row, col);
      if (rowTotalFlg) {
        row++
        displayArray[row] = [];
        console.info('344 rowtotal of X', rowTotal)
        for (let x = 0; x < rowTotal.length; x++) {
          console.info('364', rowTotal[0])
          if (x===0) {
            console.info('347 rowtotal of X', rowTotal[x])
            displayArray[row][x] = rowTotal[x] + ' Total'
          }
          else if (x===1) {
            displayArray[row][x] = null
          }
          else {
            displayArray[row][x] = rowTotal[x]
          }
        }
        rowTotalFlg = false
        console.info("row total 328", rowTotal);
        rowTotal = []
      }
      row++;
    }
    // new removed
    displayArray[row] = [];
    console.info("displayArray[row]", displayArray[row]);
    console.info('365 rowtotal of X', rowTotal)
    for (let x = 0; x < grandTotal.length; x++) {
      if (x===0) {
        displayArray[row][x] = 'Grand Total'
      }
      else if (x===1) {
        displayArray[row][x] = null
      }
      else {
        displayArray[row][x] = grandTotal[x]
      }
    }

    console.info("displayArray", displayArray);
    // console.info("row total", rowTotal);
    console.info("grand total", grandTotal);
    this.gridList = displayArray
    this.SpinnerService.hide()
  }


  print(): void {
    print({ printable: 'prodStat', type: 'html' });
  }

  exportFile(): void {

    this.gridList.unshift(this.headerArray)
    console.info(this.gridList)
   

    this.exportService.exportAsExcelFileGenACt(this.gridList, this.fileName, this.sheetName);
  }

  cancel() {
    this.dialogRef.close('');
  }
}
