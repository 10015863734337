import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { MngDataTransSapEditDialogService } from './mng-data-trans-sap-edit-dialog.service';

@Component({
  selector: 'app-mng-data-trans-edit-dialog',
  templateUrl: './mng-data-trans-edit-dialog.component.html',
  styleUrls: ['./mng-data-trans-edit-dialog.component.css']
})
export class MngDataTransEditDialogComponent implements OnInit {
  [x: string]: any;

  //to main page
  message = "Records updated successfully";
  errorMessage = "";

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  enablesaptrans: boolean;
  //ngModelValues
  eNamc: any;
  eEbomToSAPTransmissionType: any;
  eSendToSAPStartDate: any;
  eSendToSAPEndDate: any;
  eSendToSAPStartTime: any;
  eSendToSAPEndTime: any;
  eComments: any;
  eActive: any;
  copyEbomToSapTransmissionType: any;
  spinnerMessage = "Please Wait...";
  esap_manage_data_trnfr_id: any;
  constructor(public dialogRef: MatDialogRef<MngDataTransEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public MngDataTransSapEditDialogService: MngDataTransSapEditDialogService, private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    console.info("data inside edit", this.data);
    this.eNamc = this.data.namc;
    this.eEbomToSAPTransmissionType = this.data.ebomToSAPTransmissionType
    this.eSendToSAPStartDate = this.data.sendToSAPStartDate;
    this.eSendToSAPEndDate = this.data.sendToSAPEndDate;
    this.eSendToSAPStartTime = this.data.sendToSAPStartTime;
    this.eSendToSAPEndTime = this.data.sendToSAPEndTime;
    this.eComments = this.data.comments;
    this.esap_manage_data_trnfr_id = this.data.sap_manage_data_trnfr_id;
    this.saptransenable();
  }

  closeAlert() {
    this.errorMessage = "";
  }

  save() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.eSendToSAPStartDate,this.eSendToSAPEndDate);
    // let isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(this.eSendToSAPStartTime);
    if (!this.eSendToSAPStartDate || !this.eEbomToSAPTransmissionType || !this.eSendToSAPEndDate
      || !this.eSendToSAPStartTime || !this.eSendToSAPEndTime) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eSendToSAPStartDate && this.eSendToSAPEndDate && this.pipe.transform(this.eSendToSAPStartDate, 'yyyy-MM-dd') > this.pipe.transform(this.eSendToSAPEndDate, 'yyyy-MM-dd')) {

      this.errorMessage = "End Date should be greater than or equal to Start Date";
    }
    // else if (!isValid) {
    //   this.errorMessage = "Please Provide HH:MM:SS Format";
    // }
    else {
      // return isValid;
      let SAPTransmissionType = '';
      if (this.eEbomToSAPTransmissionType==='Automatic') {
        SAPTransmissionType = "A"
      }
      if (this.eEbomToSAPTransmissionType==='Manual') {
        SAPTransmissionType = "M"
      }
      let indateFormat = ''; let outdateFormat = '';
      console.info("304", this.sendToSAPStartDate)
      if (this.eSendToSAPStartDate !=="") {
        const d1 = moment(this.eSendToSAPStartDate).format('YYYY-MM-DD')
        console.info("307", d1)
        const b = d1.split('T')[0]
        console.info("309", b)
        let parts = []
        parts = b.split('-');
        console.info("311", parts)
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        console.info("314", date)
        indateFormat = moment(date).format('YYYY-MM-DD')
        console.info("315", indateFormat)
        this.eSendToSAPStartDate = date
      }
      if (this.eSendToSAPEndDate !=="") {
        const d1 = moment(this.eSendToSAPEndDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eSendToSAPEndDate = date
      }
      this.errorMessage = "";
      let cmts;
      if (this.eComments!==undefined) {
        cmts = this.eComments.replaceAll("'", "''");
      } else {
        cmts = this.eComments
      }
      const body = {
        'ebomToSAPTransmissionType': SAPTransmissionType,
        'sendToSAPStartDate': indateFormat,
        'sendToSAPEndDate': outdateFormat,
        'sendToSAPStartTime': this.eSendToSAPStartTime,
        'sendToSAPEndTime': this.eSendToSAPEndTime,
        'comments': cmts,
        'MngDataTrnsfrID': this.esap_manage_data_trnfr_id,
        "screenNo":"NBPC_9997",
        "fileName" :fileName

      }
      console.info(body);
      this.SpinnerService.show()
      this.MngDataTransSapEditDialogService.editMngDataTrnsfer(body).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide()
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      });
    }
  }
  saptransenable() {
    if (this.eEbomToSAPTransmissionType==='Automatic') {
      this.enablesaptrans = false
    }
    else if (this.eEbomToSAPTransmissionType==='Manual') {
      this.enablesaptrans = true
    }

  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name==='eSendToSAPStartDate') {
      this.eSendToSAPStartDate = "";
    } else if (name==='eSendToSAPEndDate') {
      this.eSendToSAPEndDate = "";
    }
  }
}
