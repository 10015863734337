import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { VersionTypesAddDialogService } from './version-types-add-dialog.service';

@Component({
  selector: 'app-version-types-add-dailog',
  templateUrl: './version-types-add-dailog.component.html',
  styleUrls: ['./version-types-add-dailog.component.css']
})
export class VersionTypesAddDailogComponent implements OnInit {

  //errormessage
  errorMessage: string = "";

  //ngModel
  aVerTyp: any;
  aVerTypDesc: any;

  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Added Successfully";

  spinnerMessage = "Please Wait...";

  constructor(public dialogRef: MatDialogRef<VersionTypesAddDailogComponent>,
    private readonly SpinnerService: NgxSpinnerService, private verTypAddService: VersionTypesAddDialogService) { }

  ngOnInit(): void {
  }

  //save
  submitVerTyp(): void {
    if (!this.aVerTyp || !this.aVerTypDesc) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      this.errorMessage = "";
      const body = {
        'vertyp': this.aVerTyp.toUpperCase(),
      }
      this.SpinnerService.show();
      this.verTypAddService.ExistsVersionType(body).subscribe(data => {
        this.response = data.payload;
        console.info(data);
        if (this.response[0].result==='Data Already Exists') {
          this.errorMessage = "Version Type Already Exists";
          this.SpinnerService.hide();
        } else if (this.response[0].result==='success') {
          this.AddVersionType();
        } else if (this.response[0].result==='Invalid') {
          this.errorMessage = "Version Type Invalid";
	  this.SpinnerService.hide();
        }
      }, err => {
        console.info(err);
        this.SpinnerService.hide();
      });
    }
  }
  AddVersionType() {
    const user = localStorage.getItem('workDayId');
    const fileName= localStorage.getItem('Filename')
    let cmts;
    if (this.aVerTypDesc!==undefined) {
      cmts = this.aVerTypDesc.replaceAll("'", "''");
    } else {
      cmts = this.aVerTypDesc
    }
    const body = {
      'vertyp': this.aVerTyp.toUpperCase(),
      'verdesc': cmts,
      'screenNo':"NBPC_10105",
      'fileName': fileName
    }
    this.verTypAddService.addVersionType(body).subscribe(data => {
      this.response = data.payload;
      console.info(data);
      this.SpinnerService.hide();
      if (this.response[0].result==='success') {
        this.dialogRef.close(this.response[0].result);
      } else {
        this.errorMessage = "Data not Added";
      }
    }, err => {
      this.SpinnerService.hide();
    });
  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  closeAlert() {
    this.errorMessage = '';
  }

}
