import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";
import { UsageVarianceCalculationService } from './usage-variance-calculation.service';
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-usage-variance-calculation',
  templateUrl: './usage-variance-calculation.component.html',
  styleUrls: ['./usage-variance-calculation.component.css']
})
export class UsageVarianceCalculationComponent implements OnInit {


  [x: string]: any;

  //alertMessage
  errorMessage: string = '';


  //export to excel
  fileName = 'c13UsageVarianceCalculation';
  exportData = [];
  sheetName = ['C13_USG_VAR_CALC'];



  //ngModel values for searcH
  step = 0;
  namc: string = '';
  family: string = '';
  katashiki: string = "";
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any
  //list
  namcLst = [];
  familyLst = [];

  //ngx-datatable
  showGrid: boolean = false;
  showButtons: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [

    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' }, { name: 'suspensionDate', prop: 'excp_dt' },
    { name: 'prodBomId', prop: 'prod_bom_id' }, { name: 'dest', prop: 'dest_ctry_id' }, { name: 'prodDefId', prop: 'prod_def_id' }, { name: 'errorMessage', prop: 'err_desc' }, { name: 'bomEffectivityDate', prop: 'prod_trnfr_dt' },
    { name: 'productionOrder', prop: 'sap_prod_ord_no' }, { name: 'sapMaterialId', prop: 'sap_mtrl_id' }, { name: 'modelNumber', prop: 'mdl_no' },
    { name: 'vin', prop: 'vin' }
  ];

  ColumnMode = ColumnMode;
  recordcount: any;
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []
  constructor(private usageVarianceCalculationService: UsageVarianceCalculationService, private readonly SpinnerService: NgxSpinnerService, public dialog: MatDialog, private exportService: ExportToExcelService, private dialogData: DialogData, public globalrole: GlobalService,) { }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.info('a', a)
      //grid
      this.onloadfn();
    }

  }

  onloadfn() {
    //to get namc
    this.usageVarianceCalculationService.getNamc().subscribe(data => {
      console.info(data);
      this.namcLst = data.payload;
    });
    // to get family
    this.usageVarianceCalculationService.getFamily(this.family).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
    });
  }

  getFamCdByNamc() {
    if (this.namc===undefined) {
      this.namc = "";
    }
    //to get family
    this.usageVarianceCalculationService.getFamily(this.namc).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
      this.family = "";
    });
  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  //ngx-table
  onSelect({ selected }) {
    console.info('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  //export function
  exportFile(): void {
    this.exportData = [];
    if (this.rows.length !==0) {
      this.rows.forEach(row => {
        let obj = {
          "NAMC": row.namc_cd,
          "Family": row.fam_cd,
          "Katashiki/Part": row.katashiki_cd,
          "Suspension Date": row.excp_dt,
          "Prod Bom Id": row.prod_bom_id,
          "Dest": row.dest_ctry_id,
          "Prod Def ID": row.prod_def_id,
          "Error Message": row.err_desc,
          "BoM Effectivity Date": row.prod_trnfr_dt,
          "productionOrder": row.sap_prod_ord_no,
          "SAP Material Id": row.sap_mtrl_id,
          "Model Number": row.mdl_no,
          "VIN (or SN)": row.vin,
        }
        this.exportData.push(obj);
      });
    } else {
      let obj = {
        "NAMC": "",
        "Family": "",
        "Katashiki/Part": "",
        "Suspension Date": "",
        "Prod Bom Id": "",
        "Dest": "",
        "Prod Def ID": "",
        "Error Message": "",
        "BoM Effectivity Date": "",
        "productionOrder": "",
        "SAP Material Id": "",
        "Model Number": "",
        "VIN (or SN)": "",
      }
      this.exportData.push(obj);
    }
    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
  }
  //search func
  generateReport(): void {
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ""
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  getGridData() {
    if (this.family===undefined) {
      this.family = "";
    }
    if (this.namc===undefined) {
      this.namc = "";
    }
    if (this.katashiki===undefined) {
      this.katashiki = "";
    }
    let newDate = new Date();
    const date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
    const fileName = 'c13-usage_variance_calc/c13GrdSrc' + date + '.json';
    let gridData =
    {
      namc: this.namc,
      family: this.family,
      katashiki: this.katashiki,
      fileName: fileName

    }
    this.SpinnerService.show()
    this.usageVarianceCalculationService.getGridData(gridData).subscribe(data => {
      console.info(data);
      this.showdata = true;
      console.info(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      const datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
      this.rows = data.payload;
      this.SpinnerService.hide();
      this.errorMessage = "";
      }
      console.info(this.showdata);
    }, err => {
      this.showdata = false;
      this.successMessage = "";
      console.info(this.showdata);
      this.rows = [];
      if (err===0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });

  }
  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    const a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.info(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.info('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.info(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.info("res and reult", res, result)
          if (res.length > 0) {
            this.rows=res;
            console.info(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.usageVarianceCalculationService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data?Storage?Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable?to?get?keys?and?token', 'Failed');
      });
    })
  }
  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_7004') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }
}
