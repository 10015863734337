import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { RepVehMultieditDialogServiceService } from './rep-veh-multiedit-dialog-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogData } from '../dialog-data/dialog-data';

@Component({
  selector: 'app-rep-vehicle-multiedit-dialog',
  templateUrl: './rep-vehicle-multiedit-dialog.component.html',
  styleUrls: ['./rep-vehicle-multiedit-dialog.component.css']
})
export class RepVehicleMultieditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //errormessage
  errorMessage: string = "";

  //ngModel
  emMonthlyRep: any;
  emCompBom: any;
  emExtractionTimeIn: any;
  emExtractionTimeOut: any;
  emComments: any;
  response: any;
  erepproddefid: any;
  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";



  constructor(public dialogRef: MatDialogRef<RepVehicleMultieditDialogComponent>, private readonly SpinnerService: NgxSpinnerService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private repMultiEditService: RepVehMultieditDialogServiceService) { }

  ngOnInit(): void {
    console.info(this.data)
    
    const arrayvalue = this.data;

    console.info(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str==='') {
        str = "'" + arrayvalue[i].rep_prod_def_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].rep_prod_def_id + "'";
      }

      this.erepproddefid = str;
    }
  }

  updateMultiRepVehicle() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.emExtractionTimeIn);
    const user = localStorage.getItem('workDayId');
    if (!this.emExtractionTimeIn || !this.emExtractionTimeOut) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.emExtractionTimeIn && this.emExtractionTimeOut &&
      this.pipe.transform(this.emExtractionTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.emExtractionTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      this.errorMessage = "";
      let indateFormat = ''; let outdateFormat = '';
      if (this.emExtractionTimeIn !=="") {
        const d1 = moment(this.emExtractionTimeIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.emExtractionTimeIn = date
        // const date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
        // this.effDateIn = date
        // console.info("this.effDateIn",date,this.effDateIn);
      }
      if (this.emExtractionTimeOut !=="") {
        const d1 = moment(this.emExtractionTimeOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.emExtractionTimeOut = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      if (this.emMonthlyRep===true) {
        this.emMonthlyRep = 'Y';
      } else {
        this.emMonthlyRep = 'N';
      }
      if (this.emCompBom===true) {
        this.emCompBom = 'Y';
      } else {
        this.emCompBom = 'N';
      }
      let cmts;

    if (this.emComments !==undefined) {

      cmts = this.emComments.replaceAll("'", "''");

    } else {

      cmts = this.emComments

    }
      const body = {
        "monRepFlg": this.emMonthlyRep,
        "compBomFlg": this.emCompBom,
        "extTimeIn": indateFormat,
        "extTimeOut": outdateFormat,
        "comment": cmts,
        "repProdDefid": this.erepproddefid,
        "screenNo":"NBPC_9350",
        "fileName" :fileName,
        
      }

      this.SpinnerService.show();
      this.repMultiEditService.updateMultiRepVehicle(body).subscribe(data => {
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });
    }
  }
  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  clearDate(event, name) {
    event.stopPropagation();
    if (name==='emExtractionTimeIn') {
      this.emExtractionTimeIn = "";
    } else if (name==='emExtractionTimeOut') {
      this.emExtractionTimeOut = "";
    }
  }


  closeAlert() {
    this.errorMessage = ""
  }
}
