import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ManualAdjustmentRuleAddDialogService {
  [x: string]: any;
  apiUrl = environment.apiUrl;
  Bussinessgridapi = environment.BussinessgridapiUrl;
  BussinessDropdownApi = environment.BusinesssearchdropdownapiUrl;
  // cmpltPrtApi = environment.BusinessManAdjstapiUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  ruleType = '/getRuleTypeBpMar'
  partDesc = '/getPartDesc';

  constructor(private http: HttpClient) { }


  //rule type
  getRuleTypeBpMar(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.BussinessDropdownApi + this.ruleType + '?page=' + 'add'+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //getAddVersionNameBpMar
  getAddVersionNameBpMar(ruletypid): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + '/getAddVersionNameBpMar'+'?man_adj_rule_def='+ruletypid +'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }


  //getAddNamcBpMar
  getAddNamcBpMar(versionName, repBomVerId, fromDate, toDate): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    var API_URL;
    if (versionName !=='production') {
      API_URL = environment.BussinessgridapiUrl + '/getAddNamcBpMar' + '?repBomVerId=' + repBomVerId + '&versionName=' + versionName+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    }
    else {
      API_URL = environment.BussinessgridapiUrl + '/getAddNamcBpMar' + '?fromDate=' + fromDate + '&toDate=' + toDate + '&versionName=' + versionName+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    }
    return this.http.get(API_URL,httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddFamilyMar
  getAddFamilyMar(repBomVerId, namcId, fromDate, toDate): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + '/getAddFamilyMar'
      + '?repBomVerId=' + repBomVerId + '&namcId=' + namcId + '&fromDate=' + fromDate + '&toDate=' + toDate+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddKatashikiMar
  getAddKatashikiMar(repBomVerId, namcId, famCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + '/getAddKatashikiMar'
      + '?repBomVerId=' + repBomVerId + '&namcId=' + namcId + '&famCd=' + famCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  ///getAddGrpiMar
  getAddGrpiMar(namcId, famCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/getAddGrpiMar'
      + '?namcId=' + namcId + '&famCd=' + famCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  ///getDestMar
  getDestData(namcId, famCd, katashikiCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.MastersearchdropdownapiUrl + '/getDestByCust'
      + '?namcId=' + '' + '&famCd=' + '' + '&katashikiCd=' + ''+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddNetSpecMar
  getAddNetSpecMar(namcId, famCd, baseOptnTypCd, repBomVerId): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/getAddNetSpecMar'
      + '?namcId=' + namcId + '&famCd=' + famCd + '&baseOptnTypCd=' + baseOptnTypCd + '&repBomVerId=' + repBomVerId+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddPartNoMar
  getAddPartNoMar(namcId, famCd, grpiCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/getAddPartNoMar'
      + '?namcId=' + namcId + '&famCd=' + famCd + '&grpiCd=' + grpiCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddChildPartNoMar
  getAddChildPartNoMar(namcId, famCd, grpiCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/getAddChildPartNoMar'
      + '?namcId=' + namcId + '&famCd=' + famCd + '&grpiCd=' + grpiCd +'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddActCurCodeBpMar
  getAddActCurCodeBpMar(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + '/getAddActCurCodeBpMar'+'?screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //getAddCompletePartRoutingMar
  getAddCompletePartRoutingMar(namcId): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl + '/getAddCmpltPrtRntngMar' + '?namcId=' + namcId+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //partDesc
  getPartDesc(part): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let partParams = new HttpParams().set("partNo", part);
    const API_URL = environment.MastersearchdropdownapiUrl + this.partDesc + '?partNo=' + part+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  /////////insert or add function////////////////////////////
  //addManAdjRule
  getUnitStdPrcAmt(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/getUnitStdPrcAmt'
    return this.http.post(API_URL, body,httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  chkValParntPartNm(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/chkValParntPartNm' + '?parpart' + body.parpart+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  chkValPartNo(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/chkValPartNo'
    return this.http.post(API_URL, body,httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  chkCarFmFlg(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/chkCarFmFlg' + '?famCd=' + body.famCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  versionValChk(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/versionValChk' + '?repBomVerId=' + body.repBomVerId+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }
  //addManAdjRule
  getChildPartNoManAdjAddExclude(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/getChildPartNoManAdjAddExclude' + "?famCd=" + body.famCd +
      "&namcLglEntityId=" + body.namcLglEntityId +
      "&grpiCd=" + body.grpiCd +
      '&childPartNo=' + body.childPartNo+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  getChildPartNoManAdjAdd(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/getChildPartNoManAdjAdd' + "?famCd=" + body.famCd +
    "&namcLglEntityId=" + body.namcLglEntityId +
    "&grpiCd=" + body.grpiCd +
    '&childPartNo=' + body.childPartNo+'&screenNo=NBPC_10122'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  chkGrpiCd(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/chkGrpiCd'
    return this.http.post(API_URL, body, httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  checkIfMarRecordExist(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/checkIfMarRecordExist'
    return this.http.post(API_URL, body,httpOptions)
      .pipe(catchError(this.error))
  }

  //addManAdjRule
  addManualAdjustmentRule(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinessActionRestapiUrl + '/addManualAdjustmentRule'
    return this.http.post(API_URL, body,httpOptions)
      .pipe(catchError(this.error))
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }

}

