import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders,HttpParams} from '@angular/common/http';
import { BehaviorSubject,Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})

export class PcSpecsExclusionListService {

  
  searchdropdown3UrlApi = environment.Mastersearchdropdown3apiUrl;

  pcSpecPosition = 'getPcSpecPosition'
  gridData = '/getPcSpecExcLstGrid'

  constructor(private http: HttpClient) { }

  //Pc Spec Position
  getPcSpecPosition(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown3UrlApi}/${this.pcSpecPosition}`+'?screenNo=NBPC_2271'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //grid data
  getPcSpecsExclusionListGrid(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.MastergridapiUrl + this.gridData + '?pcSpecPosnCd=' + gridData.pcSpecPosition
    + '&rmrkTxt=' + gridData.comments + '&option=' + gridData.option+'&screenNo=NBPC_2271'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(catchError(this.error)
      )
  }
  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }

}
