import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { SapDataDeleteDialogService } from './sap-data-delete-dialog.service';


@Component({
  selector: 'app-sap-data-delete-dialog',
  templateUrl: './sap-data-delete-dialog.component.html',
  styleUrls: ['./sap-data-delete-dialog.component.css']
})
export class SapDataDeleteDialogComponent implements OnInit {
  [x: string]: any;

  //to main page
  message = "Records Deleted Successfully";
  repProdDefId: any;
  response: any;
  constructor(private SapDataDeleteDialogService: SapDataDeleteDialogService, public dialogRef: MatDialogRef<SapDataDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private readonly SpinnerService: NgxSpinnerService,) { }

  ngOnInit(): void {
    console.info(this.data.repProdDefId)
    
    const arrayvalue = this.data;

    console.info(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str==='') {
        str = arrayvalue[i].rep_prod_def_sap_id
      } else {
        str += ',' + arrayvalue[i].rep_prod_def_sap_id
      }

      this.repProdDefId = str;
    }
    console.info(this.erepproddefid)
    // this.repProdDefId = this.data.repProdDefId;
  }
  deleteSapData() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      'repProdDefSapId': this.repProdDefId,
      "screenNo":"NBPC_9998",
      "fileName" :fileName

    }
    this.SpinnerService.show();
    this.SapDataDeleteDialogService.delSap(body).subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.response = data.payload;
      if (this.response[0].result==='success') {
        this.dialogRef.close(this.response[0].result);
      }
    }, err => {
      this.SpinnerService.hide();
    });

  }

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
}
