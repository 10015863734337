import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class ManualAdjustmentRuleService {

  gridData = '/getManAdjRuleGrid';
  family = '/getFamilyBpMar'
  ruleType = '/getRuleTypeBpMar'
  versionName = '/getVersionNameBpMar'
  namc = '/getNamcBpMar'
  grpi = '/getGrpiBpMar'
  partNumber = '/getPartNoMar'
  ruleStatus = '/getRuleStatusMar'
  katashiki = '/getKatashikiBpMar'




  constructor(private http: HttpClient) { }
  //rule type
  getRuleTypeBpMar(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.ruleType + '?page=' + ''+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //version Name
  getVersionNameBpMar(ruleTyp): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let versionNameParams = new HttpParams().set('ruleTyp', ruleTyp)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.versionName + "?ruleTyp=" + ruleTyp +'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)

      .pipe(
        catchError(this.error)
      )
  }
  //namc
  getNamcBpMar(ruleTyp, VerTypCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let namcParams = new HttpParams().set('ruleTyp', ruleTyp)
    //   .append('VerTypCd', VerTypCd)

    const API_URL = environment.BusinesssearchdropdownapiUrl + this.namc + "?ruleTyp=" + ruleTyp + '&VerTypCd=' + VerTypCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //family
  getFamilyBpMar(ruleTyp, VerTypCd, namc): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let familyParams = new HttpParams().set('ruleTyp', ruleTyp)
    //   .append('VerTypCd', VerTypCd)
    //   .append('namcId', namc)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.family 
    + "?ruleTyp=" + ruleTyp + '&VerTypCd=' + VerTypCd
      + '&namcId=' + namc+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)

      .pipe(
        catchError(this.error)
      )
  }
  //grpi
  getGrpiBpMar(namc, famCd, katashikiCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let GrpiParams = new HttpParams().set('namcId', namc)
    //   .append('famCd', famCd)
    //   .append('katashikiCd', katashikiCd)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.grpi + '?namcId=' + namc +
      '&famCd=' + famCd + '&katashikiCd=' + katashikiCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //part Number
  getPartNoMar(namc, famCd, katashikiCd, grpiCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let partNumberParams = new HttpParams().set('namcId', namc)
    //   .append('famCd', famCd)
    //   .append('katashikiCd', katashikiCd)
    //   .append('grpiCd', grpiCd)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.partNumber + '?namcId=' + namc +
      '&famCd=' + famCd + '&katashikiCd=' + katashikiCd + '&grpiCd=' + grpiCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //katashiki
  getkatashikiMar(namc, famCd): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let katashikiParams = new HttpParams().set('namcId', namc)
    //   .append('famCd', famCd)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.katashiki + '?namcId=' + namc + '&famCd=' + famCd+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //rule Status
  getRuleStatusMar(namc, famCd, katashikiCd, grpiCd, partNo): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let ruleStatusParams = new HttpParams().set('namcId', namc)
    //   .append('famCd', famCd)
    //   .append('katashikiCd', katashikiCd)
    //   .append('grpiCd', grpiCd)
    //   .append('partNo', partNo)
    const API_URL = environment.BusinesssearchdropdownapiUrl + this.ruleStatus + '?namcId=' + namc +
      '&famCd=' + famCd + '&katashikiCd=' + katashikiCd + '&grpiCd=' + grpiCd + '&partNo=' + partNo+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //grid data
  getGridData(gridData): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessgridapiUrl + this.gridData + "?ruleTyp=" + gridData.ruleType
      + '&verNm=' + gridData.versionName + '&namc=' + gridData.namc + '&family=' + gridData.family
      + '&katashiki=' + gridData.katasPart + '&grpi=' + gridData.grpi + '&partNum=' + gridData.partNo
      + '&ruleSts=' + gridData.ruleStatus + '&createdFrmDt=' + gridData.createFromDt + '&createdToDt=' + gridData.createToDt
      + '&option=' + gridData.option + '&GfileName=' + gridData.fileName+'&screenNo=NBPC_10122'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  fileUpldData(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole'+'?screenNo=NBPC_10122'+'&fileName=' +fileName, httpOptions)
      .map((response: any) => response).catch(this.error)
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }


}
