import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private messageSource = new BehaviorSubject('');
  Roledetails = this.messageSource.asObservable();
  tokenURL = environment.tokenURL;

  constructor(private http: HttpClient) { }

  userprevilageapi(body): Observable<any> {
    const fileName=localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')          
        })
      };
    }
    //const fileName=localStorage.getItem('Filename')
    console.info(fileName,httpOptions);
    
    const API_URL = environment.Master_ActionInsertApiUrl + '/getUserPreviliege'+'?fileName='+fileName;
    return this.http.get(API_URL, httpOptions)
      .map((response: any) => response).catch(this.handleError)
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  getProcStatus(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const API_URL = environment.BussinessppsvapiUrl + '/getProcStatus'
    return this.http.post(API_URL, body,httpOptions)
      .map((response: any) => response).catch(this.handleError)
  }

  truncTempRepProd(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    const API_URL = environment.BussinessppsvapiUrl + '/trucnTempRepProd'
    return this.http.post(API_URL, body,httpOptions)
      .map((response: any) => response).catch(this.handleError)
  }

  editProcProcessStatus(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.BussinessppsvapiUrl + '/editProcProcessStatus'
    return this.http.post(API_URL, body,httpOptions)
      .map((response: any) => response).catch(this.handleError)

  }

  async getTokens(tokenBody) {
    let isIEOrEdgecheck = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    let httpOptionsauth;
    if (isIEOrEdgecheck) {
      httpOptionsauth = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
        })
      };
    } else {
      httpOptionsauth = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
        })
      };
    }
    return await this.http.post(this.tokenURL, tokenBody, httpOptionsauth)
      .map((response: any) => response)
      .catch(this.handleError).toPromise();
  }
  tokenUplddata(): Observable<any> {
    const fileName=localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole'+'?fileName='+fileName, httpOptions)
      .map((response: any) => response).catch(this.handleError)
  }
  rmvUserDetails(): Observable<any> {
    const fileName=localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.rmvUserDetailsUrl + 'rmvUserDetails'+'?fileName='+fileName;    
    return this.http.get(API_URL, httpOptions)
      .map((response: any) => response).catch(this.handleError)
  }
  UsersLoginDetails(): Observable<any> {
    const fileName=localStorage.getItem('Filename')
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionInsertApiUrl+'/UsersLoginDetails'
    return this.http.get(API_URL, httpOptions)
    .map((response: any) => response).catch(this.handleError)
  }

  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // ===================================== Exception Handler ===========================================
  private handleError(error: HttpErrorResponse) {
    var errorMsg = '';
    if (error.error instanceof ErrorEvent) {
     
      errorMsg = `An error occurred: ${error.error.message}`;
    }else if(error.status===undefined) {
  	  location.reload();
    } else {
     
      errorMsg = `Backend returned code ${error.status}`;
    }

    return throwError(errorMsg);
  }

}
