import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { PlantUserCodeService } from './plant-user-code.service';
import { PlantUserCodeDeleteDialogComponent } from '../plant-user-code-delete-dialog/plant-user-code-delete-dialog.component';
import { DialogData } from '../dialog-data/dialog-data';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlantUserCodeEditDialogComponent } from '../plant-user-code-edit-dialog/plant-user-code-edit-dialog.component';
import { PlantUserCodeAddDialogComponent } from '../plant-user-code-add-dialog/plant-user-code-add-dialog.component';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';


@Component({
  selector: 'app-plant-user-code',
  templateUrl: './plant-user-code.component.html',
  styleUrls: ['./plant-user-code.component.css']
})
export class PlantUserCodeComponent implements OnInit {


  //export to excel
  fileName = 'PlantUserCode';
  exportData = [];
  sheetName = [];

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');


  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel 
  step = 0;
  namc: any = "";
  fam: any = "";
  productType: any = "";
  activeFlag: any = "";
  userCode: any = "";
  effDateIn: any = "";
  effDateOut: any = "";
  grpiCode: any = "";
  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  roleAccess: any = []
  Roledata: any = [];
  accessEnable: boolean;
  constructor(private readonly SpinnerService: NgxSpinnerService, private plantUserCodeService: PlantUserCodeService, public dialog: MatDialog, private exportService: ExportToExcelService,
    private dialogData: DialogData, public globalrole: GlobalService,
    ) { }

  //list
  namcLst: string[] = [];
  productTypeLst: string[] = []
  famLst: string[] = []
  userCodeLst: string[] = []
  activeFlagLst: string[] = []
  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();

  }
  onloadfn(){
    //to get namc
    this.plantUserCodeService.getNamc().subscribe(data => {
      console.info(data);
      this.namcLst = data.payload;
    });

    //to get userCode
    this.plantUserCodeService.getUserCodePuc().subscribe(data => {
      console.info(data);
      this.userCodeLst = data.payload;
    });

    //to get activeFlag
    this.plantUserCodeService.getActiveFlagPuc().subscribe(data => {
      console.info(data);
      this.activeFlagLst = data.payload;
    });


    //to get family 
    this.plantUserCodeService.getFamilyMl(this.namc).subscribe(data => {
      console.info(data);
      this.famLst = data.payload;
    });

    //to get product type
    this.plantUserCodeService.getProductType().subscribe(data => {
      console.info(data);
      this.productTypeLst = data.payload;
    });


  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  resetAll(data) {
    this.selected = []
    this.namc = '';
    this.fam = '';
    this.activeFlag = '';
    this.productType = '';
    this.userCode = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }




  search() {

    if (this.effDateIn && this.effDateOut && this.pipe.transform(this.effDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
      this.globalrole.goToTop();
    }
   else {
      this.successMessage="";
      this.errorMessage = '';
      this.getGridData("initial");
    }

  }
  getGridData(data) {
    this.selected = []
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.fam===undefined) {
        this.fam = ""
      }
      if (this.productType===undefined) {
        this.productType = ""
      }
      if (this.activeFlag===undefined) {
        this.activeFlag = ""
      }
      if (this.userCode===undefined) {
        this.userCode = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.effDateIn !=="") {
        const d1 = moment(this.effDateIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateIn = date
      }
      if (this.effDateOut !=="") {
        const d1 = moment(this.effDateOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateOut = date
      }
      let gridData
      if (data==="initial") {
        gridData =
        {
          namc: this.namc,
          fam: this.fam,
          productType: this.productType,
          activeFlag: this.activeFlag,
          userCode: this.userCode,
          grpiCode: this.grpiCode,
          effectiveInDate: indateFormat,
          effectiveOutDate: outdateFormat,
          option: data
        }
      }
      else {
        gridData =
        {
          namc: "",
          fam: "",
          productType: "",
          activeFlag: "",
          userCode: "",
          grpiCode: "",
          effectiveInDate: "",
          effectiveOutDate: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.plantUserCodeService.getGridData(gridData).subscribe(data => {
        console.info(data)
        this.showdata = true;
        console.info(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        const datarow = data.payload;
        this.rows = datarow.map(row => ({
          namc_cd: row['namc_cd'],
          fam_cd: row['fam_cd'],
          prod_typ_desc: row['prod_typ_desc'],
          incl_flg: row['incl_flg'],
          serl_no: row['serl_no'],
          grpi_cd: row['grpi_cd'],
          eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
        }));
        console.info(this.rows)
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'productType', prop: 'prod_typ_desc' },
    { name: 'userCode', prop: 'serl_no' }, { name: 'grpiCode', prop: 'grpi_cd' }, { name: 'activeFlag', prop: 'incl_flg' },
    { name: 'effDateIn', prop: 'eff_dt' }, { name: 'effDateOut', prop: 'expr_dt' }
  ];
  ColumnMode = ColumnMode;



  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(PlantUserCodeAddDialogComponent, {
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');

        if (result==='success') {
          this.getGridData("add");
          this.successMessage = "Records Added Successfully"

          this.selected = []
        }
        else if (result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // th
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.info(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(PlantUserCodeEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          if (result==='success') {
            this.getGridData("add");
            this.successMessage = "Record Updated Successfully"


            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!';
            this.globalrole.goToTop();
          }
          else {
            // th
          }
        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to Edit";
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to Edit";
          this.globalrole.goToTop();
        }
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  openDeleteDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Delete'))
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(PlantUserCodeDeleteDialogComponent, {
          disableClose: true,
          width: '50%',
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {

          if (result==='success') {
            this.getGridData("add");
            this.successMessage = "Records Deleted Successfully"

            this.selected = []
          }
          else {
            // th
          }
        });
      }
      else {
        this.errorMessage = "Please select only one row to delete";
        this.globalrole.goToTop();
      }
    }
    else {
      this.errorMessage = "User does not have permission to perform 'delete' operation";
      this.globalrole.goToTop();
    }
  }



  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "NAMC": row.namc_cd,
        "Family": row.fam_cd,
        "ProductType": row.prod_typ_desc,
        "Active Flag": row.incl_flg,
        "User Code": row.serl_no,
        "GRPI Code": row.grpi_cd,
        "Effective In Date": row.eff_dt,
        "Effective Out Date": row.expr_dt,

      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }

  sendToDialog(selected): void {
    console.info("selet", selected);

    selected.forEach(row => {
      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.productType = row.prod_typ_desc
      this.dialogData.activeFlag = row.incl_flg
      this.dialogData.effectiveInDate = row.eff_dt;
      this.dialogData.effectiveOutDate = row.expr_dt;
      this.dialogData.userCode = row.serl_no
      this.dialogData.grpiCode = row.grpi_cd
    });

    console.info(this.dialogData)
  }

  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='effDateIn') {
      this.effDateIn = "";
    } else if (name==='effDateOut') {
      this.effDateOut = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_9991') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true)
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true)
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }


}

