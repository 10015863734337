import { Component, Inject, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner"; 
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { ManualAdjustmentRuleDeleteDialogService} from './manual-adjustment-rule-delete-dialog.service';


@Component({
  selector: 'app-manual-adjustment-rule-delete-dialog',
  templateUrl: './manual-adjustment-rule-delete-dialog.component.html',
  styleUrls: ['./manual-adjustment-rule-delete-dialog.component.css']
})
export class ManualAdjustmentRuleDeleteDialogComponent implements OnInit {

  spinnerMessage="Please Wait...";
  //to main page
  message = "Records Updated Successfully";
  index: any;
  //response after insert in a record
 response:any;
 manAdjRuleDefId:any;


  constructor(private dltManAdjRuleService: ManualAdjustmentRuleDeleteDialogService,  
    private readonly SpinnerService: NgxSpinnerService, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ManualAdjustmentRuleDeleteDialogComponent>) { }

  ngOnInit(): void {
    console.info("data",this.data);
    this.manAdjRuleDefId = this.data.man_adjstmnt_rule_def_id
  }


  deleteManAdj() {
    const fileName= localStorage.getItem('Filename')
    const body = {
      "ManualAdjustmentRuleId": this.manAdjRuleDefId,
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.SpinnerService.show();
    this.dltManAdjRuleService.dltManAdjRule(body).subscribe(data => {
      console.info(data);
      this.response=data.payload;
      this.SpinnerService.hide(); 
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
      console.info("err",err);
      
      
    });
}

  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
}

