import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { SelectivityPartMultieditDialogService } from './selectivity-part-multiedit-dialog.service';
import * as moment from 'moment';
@Component({
  selector: 'app-selectivity-part-multiedit-dialog',
  templateUrl: './selectivity-part-multiedit-dialog.component.html',
  styleUrls: ['./selectivity-part-multiedit-dialog.component.css']
})
export class SelectivityPartMultieditDialogComponent implements OnInit {
  [x: string]: any;
  //to main page
  message = "Records Edited Successfully";
  index: any;
  errorMessage = "";
  eslctv_part_rule_id: any;
  listdata: any;
  //ngModelValues
  selectivityRuleTimeOut: any;
  comments: any;
  arraylengthdata: any = []
  spinnerMessage = "Please Wait...";
  constructor(public dialogRef: MatDialogRef<SelectivityPartMultieditDialogComponent>, public SlvtyMultiservice: SelectivityPartMultieditDialogService, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.listdata = this.data
    console.info(this.data);
    
    const arrayvalue = this.data;

    console.info(arrayvalue.length, arrayvalue);
    let str = '';
    for (let i = 0; i < arrayvalue.length; i++) {
      if (str==='') {
        str = "'" + arrayvalue[i].slctv_part_rule_id + "'"
      } else {
        str += ',' + "'" + arrayvalue[i].slctv_part_rule_id + "'";
      }
      this.eslctv_part_rule_id = str;
    }
    console.info(this.eslctv_part_rule_id)
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }


  updateSelPart() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.selectivityRuleTimeOut);
    if (!this.selectivityRuleTimeOut) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else {
      let outdateFormat = ""
      if (this.selectivityRuleTimeOut !=="") {
        const d1 = moment(this.selectivityRuleTimeOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.selectivityRuleTimeOut = date
      }
      console.info(this.arraylengthdata,outdateFormat)
      this.listdata.filter((data) => {
        console.info(data.eff_dt,outdateFormat)
        if (data.eff_dt > outdateFormat) {
          this.arraylengthdata.push(data)
        }
      })
      console.info(this.arraylengthdata)
      if (this.arraylengthdata.length===0) {
        const user = localStorage.getItem('workDayId');
        this.errorMessage = "";
        let cmts;
        if (this.comments!==undefined) {
          cmts = this.comments.replaceAll("'", "''");
        } else {
          cmts = this.comments
        }
        const body = {
          'comments': cmts,
          'slctvRuleTimeOut': outdateFormat,
          'slctvPartRuleID': this.eslctv_part_rule_id,
          "screenNo":"NBPC_2230",
          "fileName" :fileName
         
        }
        console.info(body);
        this.SpinnerService.show();
        this.SlvtyMultiservice.editSlvtypart(body).subscribe(data => {
          console.info(data);
          this.SpinnerService.hide();
          this.response = data.payload;
          if (this.response[0].result==='success') {
            this.dialogRef.close(this.response[0].result);
          } else if (this.response[0].result==='AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!';
          }
        }, err => {
          this.SpinnerService.hide();
        });
      } else {
        this.dialogRef.close(this.listdata.length + ' record(s) failed due to incorrect date');
      }
    }
  }


  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


  //hikiate
  moveHikiForward(): void {

    // this.dummyHikiateLst.forEach(hiki => {
    //   this.selectedHikiateLst.push(hiki);
    //   this.index = this.hikiateLstFromDB.indexOf(hiki);
    //   this.hikiateLstFromDB.splice(this.index, 1);

    // });
    // console.info(this.selectedHikiateLst)
    const hikiatelst = this.hikiateLstFromDB
    console.info(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        if (hiki===hikiatelst[i].hikiate) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    console.info(this.hikiateLstFromDB);
    console.info(this.selectedHikiateLst);
    this.dummyHikiateLstToDB = this.selectedHikiateLst;
    this.dummyHikiateLst = ""
  }
  moveHikiBackward(): void {

    // this.dummyHikiateLstToDB.forEach(hiki => {
    //   this.hikiateLstFromDB.push(hiki);
    //   this.index = this.selectedHikiateLst.indexOf(hiki);
    //   this.selectedHikiateLst.splice(this.index, 1);
    // });
    this.dummyHikiateLstToDB.forEach(hiki => {
      const body = {
        "hikiate": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    if (this.dummyHikiateLstToDB.length >= 1) {
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
      // this.dummyHikiateLstToDB = this.selectedHikiateLst;-
      this.dummyHikiateLstToDB = []
    }
  }
}




