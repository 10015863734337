import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-major-partlst-view-dialog',
  templateUrl: './major-partlst-view-dialog.component.html',
  styleUrls: ['./major-partlst-view-dialog.component.css']
})
export class MajorPartlstViewDialogComponent implements OnInit {

  //ngModel values
  majorPartViewFamily: string;
  majorPartViewPartType: string;
  majorPartViewExpPartQty: string;
  majorPartViewProdType: string;
  majorPartViewPartCode: string;
  majorPartViewComments: any;
  majorPartViewActive: any;
  majorPartViewKatashiki: any
  majorPartViewNamc: any
  spinnerMessage = "Please Wait...";

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private readonly SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    if (this.data.majorPartStatus==='In Active') {
      this.majorPartViewActive = false
    }
    if (this.data.majorPartStatus==='Active') {
      this.majorPartViewActive = true
    }
    this.majorPartViewFamily = this.data.majorPartFamily;
    this.majorPartViewPartType = this.data.majorPartType;
    this.majorPartViewExpPartQty = this.data.expectedMajorPartTypeQty;
    this.majorPartViewProdType = this.data.majorPartProdType;
    this.majorPartViewPartCode = this.data.majorPartPCode;
    this.majorPartViewComments = this.data.majorPartComments;
    if (this.data.majorPartEditKatashiki !==null) {
      this.majorPartViewKatashiki = this.data.majorPartEditKatashiki;
    }
    else if (this.data.majorPartpartno !==null) {
      this.majorPartViewKatashiki = this.data.majorPartpartno;
    } else {
      this.majorPartViewKatashiki = ""
    }
    this.majorPartViewNamc = this.data.majorPartEditNAMC
  }


}
