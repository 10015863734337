import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { Workbook } from 'exceljs';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportToExcelService {
  reportName: string;
  severity: string;

  constructor(private http: HttpClient) { }

  exportAsExcelFile(rows: any[], excelFileName: string, sheetName: string[]): void {
    this.generateExcel(rows, excelFileName, sheetName);
  }

  exportAsExcelFileForReport(rows: any[], excelFileName: string) {
    const worksheet = XLSX.utils.json_to_sheet([
      { A: "Report Name", B: this.reportName, C: "", D: "", E: "", F: "Severity", G: this.severity }
    ], { header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true });
    XLSX.utils.sheet_add_json(worksheet, rows, { origin: "A3" });
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  //export for multiple sheet
  exportAsExcelFileForMultipleSheets(rows: any[]) {

    this.http.get('assets/exceltemplate/DownLoadExcelTemplate_PIVOT.xlsx', { responseType: 'blob' })
      .subscribe((data: any) => {
        console.info(data);
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          const wsname1: string = wb.SheetNames[3];
          console.info(wsname1);
          const ws1: XLSX.WorkSheet = wb.Sheets[wsname1];
          console.info(ws1);
          XLSX.utils.sheet_add_json(ws1, rows, { origin: "A2", skipHeader: true });
          const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, 'DownLoadExcelTemplate_PIVOT.xlsx');

        };
        reader.readAsBinaryString(data);
        console.info(data);
      });


  }

  saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, baseFileName + EXCEL_EXTENSION);
  }
  generateExcel(rows: any, excelFileName: any, sheetName: any) {

    console.info(rows)
    const header = Object.keys(rows[0]);
    const data = rows.map(el => Object.values(el));
    console.info(data)

   

    const workbook = new Workbook();

    const worksheet = workbook.addWorksheet(excelFileName);
    
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      cell.font = {
        bold: true,
      };
    })

    worksheet.views = [
      { state: 'frozen', ySplit: 1 }
    ];
    data.forEach(d => {
      const row = worksheet.addRow(d);
      d.WorksheetViewFrozen = {
        state: 'frozen', xSplit: 1
      };
    }
    );
    for (let i = 0; i < worksheet.columns.length; i++) {
      const lengths = worksheet.columns[i].values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      worksheet.columns[i].width = maxLength + 6;
      if (i===4 && excelFileName==='Rep Version Summary') {
        worksheet.columns[4].alignment = { horizontal: 'center' }
      } else {
        worksheet.columns[i].alignment = { horizontal: 'left' }
      }
    }


    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, excelFileName);
      console.info('data1')

    })
  }

  exportAsExcelFileGenACt(rows: any[], excelFileName: string, sheetName: string[]) {
    const data = rows
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelFileName);

    data.forEach(d => {
      const row = worksheet.addRow(d);
    })
    console.info(data)
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, excelFileName);
      console.info('data1')

    })
  }
}