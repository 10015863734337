import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ExchangeRateAddDialogService } from './exchange-rate-add-dialog.service';

@Component({
  selector: 'app-exchange-rate-add-dialog',
  templateUrl: './exchange-rate-add-dialog.component.html',
  styleUrls: ['./exchange-rate-add-dialog.component.css']
})
export class ExchangeRateAddDialogComponent implements OnInit {

  [x: string]: any;
  //to main page
  message = "Records updated successfully";
  errorMessage = "";
  successMessage = "";
  costTypeLst = [];
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //ngModelValues
  eCurrencyCodeFrom: any = "";
  eCurrencyCodeTo: any = "USD";
  eCostType: any = "";
  eMonthSelection: any = "";
  eEffectiveInDate: any = "";
  eEffectiveOutDate: any = "";
  eExchangeRate: any = "";
  eComments: any = "";
  firstDate: any = "";
  lastDate: any = "";
  effInDate: any = "";
  effOutDate: any = "";
  selectdMnth: any = "";
  aExchngRtExtrctTimeIn: any = "";
  aExchngRtExtrctTimeOut: any = "";
  setMnth: any = "";
  curDate: any = "";
  curMonth: any = "";
  setYr: any = "";
  curYr: any = "";
  spinnerMessage = "Please Wait...";
  currcodefromArray: any = []

  constructor(public dialogRef: MatDialogRef<ExchangeRateAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly SpinnerService: NgxSpinnerService,
    private exchangeaddservice: ExchangeRateAddDialogService) { }

  ngOnInit(): void {
    this.getCurrCodefromData()

   }

  getCurrCodefromData() {
    this.SpinnerService.show();
    this.exchangeaddservice.getcurrencyCodeDropdown().subscribe(data => {
      console.info("res from getcurrcodefrom", data);
      this.SpinnerService.hide();
      this.currcodefromArray = data.payload;
    });
  }



  getExchangeRtEffDates() {
    this.selectdMnth = this.eMonthSelection//$.trim($("aExchngRate").val());
    $("aExchngRtExtrctTimeIn").prop('disabled', false);
    $('aExchngRtExtrctTimeOut').prop('disabled', false);

    if (this.selectdMnth !=="") {
      this.selectdMnth = parseInt(this.selectdMnth, 10);
      this.setMnth = this.selectdMnth - 1;
      this.curDate = new Date();

      this.curMonth = this.curDate.getMonth();
      this.curYr = this.curDate.getFullYear();
      this.setYr = this.curYr;

      if (this.setMnth < this.curMonth) {
        this.setYr = this.curYr + 1;
      }

      this.firstDate = new Date(this.setYr, this.setMnth, 1).getDate().toString();
      this.lastDate = new Date(this.setYr, this.setMnth + 1, 0).getDate().toString();

      this.selectdMnth = this.selectdMnth.toString();
      this.selectdMnth = this.selectdMnth.length > 1 ? this.selectdMnth : '0' + this.selectdMnth;
      this.firstDate = this.firstDate.length > 1 ? this.firstDate : '0' + this.firstDate;

      this.effInDate = this.setYr + '-' + this.selectdMnth + '-' + this.firstDate;
      this.effOutDate = this.setYr + '-' + this.selectdMnth + '-' + this.lastDate;


      this.eEffectiveInDate = this.effInDate;
      this.eEffectiveOutDate = this.effOutDate;

    }
    else {
      this.eEffectiveInDate = "";
      this.eEffectiveOutDate = "";
    }

    $("aExchngRtExtrctTimeIn").prop('disabled', true);
    $('aExchngRtExtrctTimeOut').prop('disabled', true);
    return true;
  }



  closeAlert() {
    this.errorMessage = "";
  }

  save() {
    const fileName= localStorage.getItem('Filename')
    console.info(this.eCurrencyCodeFrom);
    if (!this.eCurrencyCodeFrom || !this.eCurrencyCodeTo || !this.eCostType
      || !this.eMonthSelection || !this.eExchangeRate) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eEffectiveInDate && this.eEffectiveOutDate &&
      this.pipe.transform(this.eEffectiveInDate, 'yyyy-MM-dd') > this.pipe.transform(this.eEffectiveOutDate, 'yyyy-MM-dd')) {
      this.errorMessage = "Effective Out Date should be greater than or equal to Effective In Date";
    }
    else {
      this.errorMessage = "";
       if (this.eEffectiveOutDate==="") {
        this.eEffectiveOutDate = "9999-12-31"
      }
      const body = {
        'crncy1Cd': this.eCurrencyCodeFrom,
        'crncy2Cd': this.eCurrencyCodeTo,
        'exchngRtTyp': this.eCostType,
        'effDt': this.eEffectiveInDate,
        'exprDt': this.eEffectiveOutDate,
        "screenNo":"NBPC_3910",
        "fileName" :fileName

      }
      this.SpinnerService.show();
      this.exchangeaddservice.existscheckExchangeRate(body).subscribe(data => {
        console.info(data);
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.exchangerate();
        }
        else if (this.response[0].result==='Data Already Exists') {
          this.SpinnerService.hide()
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide()
      });
    }

  }

  exchangerate() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId');
    let cmts;
    if (this.eComments!==undefined) {
      cmts = this.eComments.replaceAll("'", "''");
    } else {
      cmts = this.eComments
    }
    const body = {
      'crncy1Cd': this.eCurrencyCodeFrom,
      'crncy2Cd': this.eCurrencyCodeTo,
      'exchngRtTyp': this.eCostType,
      'curExchngRt': this.eExchangeRate,
      'comments': cmts,
      'effDt': this.eEffectiveInDate,
      'exprDt': this.eEffectiveOutDate,
      "screenNo":"NBPC_3910",
      "fileName" :fileName

      
    }
    this.SpinnerService.show();
    this.exchangeaddservice.addExchangeRate(body).subscribe(data => {
      console.info(data);
      this.response = data.payload;
      this.SpinnerService.hide();
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
    });
  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }


}
