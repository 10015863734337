import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class SelectivityPartAddDialogService {
  searchdropdown1UrlApi = environment.Mastersearchdropdown1apiUrl;
  searchdropdown2UrlApi = environment.Mastersearchdropdown2apiUrl;
  searchdropdown3UrlApi = environment.Mastersearchdropdown3apiUrl;
  namcSpr = 'getNamcSpr'
  familyByNamcSpr = '/getfamilyByNamcAddSpr'
  selectivity = '/getSelectivityByFamily'
  selectivityRuleVin = 'getSelectivityRuleVin'
  selectivityRuleRep = 'getSelectivityRuleRep'
  completePartRouting = '/getCmpltePrtRtngSprByNamc'

  constructor(private http: HttpClient) { }

  //namcSpr
  getNamcSpr(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown1UrlApi}/${this.namcSpr}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //familyByNamcSpr
  getFamilyByNamcSpr(namcId): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let familyParams = new HttpParams().set('namcId', namcId);
    const API_URL = this.searchdropdown3UrlApi + this.familyByNamcSpr + '?namc=' + namcId+'&screenNo=NBPC_2230'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //selectivityByFamily
  getSelectivityByFamily(famcId): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let selectivityParams = new HttpParams().set('famcId', famcId);

    const API_URL = this.searchdropdown2UrlApi + this.selectivity + '?famcId=' + famcId+'&screenNo=NBPC_2230'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //selectivityRuleVin
  getSelectivityRuleVin(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown2UrlApi}/${this.selectivityRuleVin}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  //selectivityRuleVin
  getSelectivityRuleRep(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown2UrlApi}/${this.selectivityRuleRep}`+'?screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //completePartRouting
  getCompletePartRouting(namcId): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let completePartRoutingParams = new HttpParams().set('namcId', namcId);

    const API_URL = this.searchdropdown3UrlApi + this.completePartRouting + '?namcId=' + namcId+'&screenNo=NBPC_2230'+'&fileName=' +fileName
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //CheckGrpi
  CheckGrpi(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${environment.Master_ActionapiUrl}/${'addvalidateGrpi'}`;
    return this.http.post(API_URL, body, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //CheckPartNo
  CheckpartNo(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.Master_ActionapiUrl + '/addpartNum' + '?partNo=' + body.partNo+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //CheckParentpartNo
  CheckParentpartNo(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // const API_URL = `${environment.Master_ActionapiUrl}/${'addparentPartNum'}`;
    // return this.http.post(API_URL, body, httpOptions)
    //   .pipe(
    //     catchError(this.error)
    //   )
    const API_URL = environment.Master_ActionapiUrl + '/addparentPartNum' + '?parentPartNo=' + body.parentPartNo+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Checkselectivity
  Checkselectivity(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    
    // const API_URL = `${environment.Master_ActionapiUrl}/${'addslctvTypExists'}`;
    // return this.http.post(API_URL, body, httpOptions)
    //   .pipe(
    //     catchError(this.error)
    //   )
    const API_URL = environment.Master_ActionapiUrl + '/addslctvTypExists' + '?slctvTypExists=' + body.slctvTypExists+'&screenNo=NBPC_2230'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //checkaddExists
  checkaddExists(body): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${environment.Master_ActionapiUrl}/${'slctvRuleAddExists'}`;
    return this.http.post(API_URL, body, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //addSelectivityPart
  addSelectivityPart(selPartObj): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    const API_URL = `${environment.Master_ActionapiUrl}/${'addslctvPartRule'}`;
    return this.http.post(API_URL, selPartObj, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }


  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }
}
