import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { RecordCountsFromDataSourcesService } from './record-counts-from-data-sources.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { GlobalService } from '../global.service';
@Component({
  selector: 'app-record-counts-from-data-sources',
  templateUrl: './record-counts-from-data-sources.component.html',
  styleUrls: ['./record-counts-from-data-sources.component.css']
})
export class RecordCountsFromDataSourcesComponent implements OnInit {
  [x: string]: any;

  //alertMessage
  errorMessage: string = '';


  //export to excel
  fileName = 'i27RecordCountFromDataSource';
  exportData = [];
  sheetName = ['I27_REC_COU_FROM_DAT_SOU'];
  excp_rpt_id_lst: any;


  //ngModel values for searcH
  step = 0;
  status: string = 'Open';
  sourceSystem: string = '';
  inputFileName: string = "";
  sequenceNumber: string = "";
  reasons: string = "";
  startDate: any = "";
  endDate: any = "";
  spinnerMessage = "Please Wait...";
  showdata: boolean = true;
  //list
  statusLst = [];
  sourceSystemLst = [];
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []
  getGridData(data) {
    if (this.status===undefined) {
      this.status = "";
    }
    if (this.sourceSystem===undefined) {
      this.sourceSystem = "";
    }
    if (this.sourceSystem==='ALL') {
      this.sourceSystem = "";
    }
    let startDateFormat = ''; let endDateFormat = '';
    if (this.startDate !=="") {
      const d1 = moment(this.startDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      startDateFormat = moment(date).format('YYYY-MM-DD')
      this.startDate = date
    }
    if (this.endDate !=="") {
      const d1 = moment(this.endDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      endDateFormat = moment(date).format('YYYY-MM-DD')
      this.endDate = date
    }
    let gridData =
    {
      errStatCd: this.status,
      dataSrcId: this.sourceSystem,
      stdName: this.inputFileName,
      seqNbr: this.sequenceNumber,
      reasons: this.reasons,
      strtDt: startDateFormat,
      endDt: endDateFormat,
      option:data
    }
    this.SpinnerService.show();
    this.recordCountsFromDataSourcesService.getGridData(gridData).subscribe(data => {
      console.info(data)
      this.SpinnerService.hide();
      this.showdata = true;
      this.rows = data.payload;
      this.errorMessage = "";
    }, err => {
      console.info(err);
      this.showdata = false;
      this.successMessage = "";
      console.info(this.showdata);
      this.rows = [];
      if (err===0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });

  }

  //ngx-datatable
  showGrid: boolean = false;
  title: boolean = false;
  showButtons: boolean = false;
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;


  columns = [

    { name: 'status', prop: 'err_stat_cd' }, { name: 'sourceSystem', prop: 'data_src_nm' },
    { name: 'stdName', prop: 'std_name' }, { name: 'sequenceNumber', prop: 'seq_nbr' },
    { name: 'fileRecCount', prop: 'file_cnt' }, { name: 'sourceCount', prop: 'src_cnt' },
    { name: 'stageCount', prop: 'stg_cnt' }, { name: 'ebomCount', prop: 'ebom_cnt' },
    { name: 'errorDate', prop: 'excp_dt' },
    { name: 'susRec', prop: 'suspended_rcrd' }, { name: 'SucProRecCoun', prop: 'success_cnt' },
    { name: 'Reasons', prop: 'reasons' },

  ];
  ColumnMode = ColumnMode;

  constructor(private readonly SpinnerService: NgxSpinnerService, private recordCountsFromDataSourcesService: RecordCountsFromDataSourcesService, private exportService: ExportToExcelService, public globalrole: GlobalService) { }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.info('a', a)
      //grid
      this.srcsyscheck();
    }


  }
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  //ngx-table
  onSelect({ selected }) {
    console.info('Select Event', selected, this.selected);
    this.selected = selected
    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }

  //export function
  exportFile(): void {

    this.exportData = [];

    if (this.rows.length !==0) {
      this.rows.forEach(row => {
        let obj = {
          "Status": row.err_stat_cd,
          "Source System": row.data_src_nm,
          "Standard Name for the Input File": row.std_name,

          "Sequence Number": row.seq_nbr,
          "File Record Count": row.file_cnt,
          "Source Count": row.sourceCount,
          "Stage Count": row.stg_cnt,

          "eBoM Count": row.ebom_cnt,
          "(%) Suspended Records": row.suspended_rcrd,
          "Successfully Processed Record Count": row.success_cnt,
          "Error Date": row.excp_dt,
          "Reasons": row.reasons,

        }
        this.exportData.push(obj);
      });
    } else {
      this.rows.forEach(row => {
        let obj = {
          "Status": row.err_stat_cd,
          "Source System": row.data_src_nm,
          "Standard Name for the Input File": row.std_name,

          "Sequence Number": row.seq_nbr,
          "File Record Count": row.file_cnt,
          "Source Count": row.sourceCount,
          "Stage Count": row.stg_cnt,

          "eBoM Count": row.ebom_cnt,
          "(%) Suspended Records": row.suspended_rcrd,
          "Successfully Processed Record Count": row.success_cnt,
          "Error Date": row.excp_dt,
          "Reasons": row.reasons,

        }
        this.exportData.push(obj);
      });
    }

    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []

  }
  srcsyscheck() {
    this.SpinnerService.show();
    this.recordCountsFromDataSourcesService.srcsyscheck().subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.srcsyslst = data.payload
    }, err => {
      this.SpinnerService.hide();
    })
  }

  //generate func
  generateReport(): void {
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.title = true;
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ''
      this.getGridData('initial');
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }

  }
  //alert close
  closeAlert(): void {
    this.errorMessage = '';
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='startDate') {
      this.startDate = "";
    } else if (name==='endDate') {
      this.endDate = "";
    }
  }

  openStatus(sts) {
    const fileName= localStorage.getItem('Filename')
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length > 0) {
        
        const arrayvalue = this.selected;
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = arrayvalue[i].excp_rpt_id
          } else {
            str += ',' + arrayvalue[i].excp_rpt_id
          }
          this.excp_rpt_id_lst = str;
          console.info(this.excp_rpt_id_lst)
        }
        const user = localStorage.getItem("workDayId");
        const body = {
          'errStatCd': sts,
          'excpRptId': this.excp_rpt_id_lst,
          "screenNo":"NBPC_7007",
          "fileName" :fileName
          
        }
        this.SpinnerService.show();
        this.recordCountsFromDataSourcesService.statuscheck(body).subscribe(data => {
          console.info(data);
          this.SpinnerService.hide();
          if (data.payload[0].response==='success') {
            this.selected = []
            this.getGridData('add');
          }
        }, err => {
          this.SpinnerService.hide();
        })
      } else {
        this.errorMessage = "Select a row to update the status";
        this.globalrole.goToTop()
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_7007') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }

}

