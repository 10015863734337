import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { HunderedSpecificationsService } from './hundered-specifications.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data'
import { HunderedSpecificationsAddDialogComponent } from '../hundered-specifications-add-dialog/hundered-specifications-add-dialog.component';
import { HunderedSpecificationsEditDialogComponent } from '../hundered-specifications-edit-dialog/hundered-specifications-edit-dialog.component';
import { HunderedSpecificationsMultieditDialogComponent } from '../hundered-specifications-multiedit-dialog/hundered-specifications-multiedit-dialog.component';
import { HunderedSpecificationsViewDialogComponent } from '../hundered-specifications-view-dialog/hundered-specifications-view-dialog.component';
import { HunderedSpecificationsUploadDialogComponent } from '../hundered-specifications-upload-dialog/hundered-specifications-upload-dialog.component';
import { ExportToExcelService } from '../common/export-to-excel.service'
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-hundered-specifications',
  templateUrl: './hundered-specifications.component.html',
  styleUrls: ['./hundered-specifications.component.css']
})
export class HunderedSpecificationsComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');


  // filename 
  fileName: string = "Hundered_Percent_Specifications"
  exportData: any = []
  sheetName = [];
  errorSheetName = ['HUN_SPEC_ERROR'];
  errorFileName = 'HunPerSpecError';

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  spinnerMessage = "Please Wait...";

  //ngModel values for search
  step = 0;
  namc: string = "";
  family: string = "";
  katashiki: string = "";
  destination: any = "";
  hikiate: string = "";
  pcSpec: string = "";
  timeInDt: any = "";
  timeOutDt: any = "";
  comments: any = "";
  vdwEffIn: any = "";
  vdwEffOut: any = "";
  hundPercent: string = "";
  hunSpecId: any = "";
  //List
  namcLst: string[];
  famcdLst: string[];
  katashikiLst: string[];
  destLst: string[] = [];
  hikiateLst: string[] = [];
  pcSpecLst: string[] = [];
  roleAccess: any = []
  Roledata: any = [];
  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'destination', prop: 'dest_ctry_id' }, { name: 'pcSpec', prop: 'pc_spec_cd_comb_txt' }, { name: 'timeInDt', prop: 'eff_dt' },
    { name: 'timeOutDt', prop: 'expr_dt' }, { name: 'namc', prop: 'namc_cd' },
    { name: 'family', prop: 'fam_cd' }, { name: 'katashiki', prop: 'katashiki_cd' },
    { name: 'vdwEffIn', prop: 'vdw_eff_dt' }, { name: 'vdwEffOut', prop: 'vdw_expr_dt' },
    { name: 'hikiate', prop: 'hikiate_stat_comb_cd' }, { name: 'hundPercent', prop: 'hundred_pct_optn_typ_cd' },
    { name: 'comments', prop: 'cmt_txt' }
  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  accessEnable: boolean;

  constructor(private hunderedSpecsService: HunderedSpecificationsService, private dialogData: DialogData, public dialog: MatDialog, private exportService: ExportToExcelService,
    public globalrole: GlobalService, private readonly SpinnerService: NgxSpinnerService) { }

  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn(){
    //to get namc
    this.hunderedSpecsService.getNamcHps().subscribe(data => {
      console.info(data);
      this.namcLst = data.payload;
    });

    //to get family
    this.getFamily();

    //to get hikiate
    this.getHikiate();

    //to get destination
    this.getDestination();



  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);


  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(HunderedSpecificationsAddDialogComponent, { disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');

        if (result==='success') {
           this.getGridData("add");
          this.successMessage = "Records Added Successfully"
          // this.getGridData();
          this.selected = []
        }
        else if (result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // th
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  openEditDialog(): void {
    console.info(this.selected, this.selected.length)
    this.errorMessage = '';
    this.successMessage = '';
    let count = this.selected.length
    console.info(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(HunderedSpecificationsEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');

          if (result==='success') {
             this.getGridData("add");
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // th
          }

        });
      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(HunderedSpecificationsMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');

          if (result==='success') {
            this.resetAll("add");
            this.successMessage = +count + " Records Updated Successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // th
          }


        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }

  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(HunderedSpecificationsViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  openUploadDialog(): void {
    this.successMessage = ''
    this.errorMessage = ''
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(HunderedSpecificationsUploadDialogComponent, {
        disableClose: true,
        // width: '50%'

      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        if (result==='success') {
          this.getGridData("add");
          this.successMessage ='Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result==='cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      })
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }

  closeAlert() {
    this.errorMessage = '';
    this.successMessage = '';
  }

  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "NAMC": row.namc_cd,
        "Family": row.fam_cd,
        "Katashiki": row.katashiki_cd,
        "Dest": row.dest_ctry_id,
        "Hikiate Status": row.hikiate_stat_comb_cd,
        "PC Spec": row.pc_spec_cd_comb_txt,
        "Time In Date": row.eff_dt,
        "Time Out Date": row.expr_dt,
        "VDW Effective In": row.vdw_eff_dt,
        "VDW Effective Out": row.vdw_expr_dt,
        "Comments": row.cmt_txt,
        "100% Spec Id": row.reqd_100pct_pc_spec_optn_id

      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  sendToDialog(selected): void {
    selected.forEach(row => {

      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.katashiki = row.katashiki_cd
      this.dialogData.dest = row.dest_ctry_id
      this.dialogData.hikiate = row.hikiate_stat_comb_cd
      this.dialogData.pcSpec = row.pc_spec_cd_comb_txt
      this.dialogData.timeInDt = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.timeOutDt = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.vdwEffIn = row.vdw_eff_dt
      this.dialogData.vdwEffOut = row.vdw_expr_dt
      this.dialogData.comments = row.cmt_txt
      this.dialogData.hundPercent = row.hundred_pct_optn_typ_cd,
        this.dialogData.reqd_100pct_pc_spec_optn_id = row.reqd_100pct_pc_spec_optn_id
    });

  }
  resetAll(data): void {
    this.selected=[]
    this.namc = '';
    this.family = '';
    this.katashiki = '';
    this.destination = '';
    this.katashikiLst = [];
    this.pcSpecLst = []
    this.hikiate = '';
    this.pcSpec = '';
    this.timeInDt = '';
    this.timeOutDt = '';
    this.comments = '';
    this.vdwEffIn = '';
    this.vdwEffOut = '';
    this.hundPercent = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }
  search() {
    if (this.timeInDt && this.timeOutDt && this.pipe.transform(this.timeInDt, 'yyyy-MM-dd') > this.pipe.transform(this.timeOutDt, 'yyyy-MM-dd')) {

      this.errorMessage = "Time Out Date should be greater than or equal to Time In Date";
      this.globalrole.goToTop();
    }

    else if (this.vdwEffIn && this.vdwEffOut && this.pipe.transform(this.vdwEffIn, 'yyyy-MM-dd') > this.pipe.transform(this.vdwEffOut, 'yyyy-MM-dd')) {
      this.errorMessage = "VDW Effective Out should be greater than or equal to VDW Effective In";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = ''
      this.getGridData("initial");

    }
  }


  getGridData(data) {
    this.selected=[]
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.family===undefined) {
        this.family = ""
      }
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.katashiki===undefined) {
        this.katashiki = ""
      }
      if (this.destination===undefined) {
        this.destination = ""
      }
      if (this.hikiate===undefined) {
        this.hikiate = ""
      }
      if (this.pcSpec===undefined) {
        this.pcSpec = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.timeInDt !=="") {
        const d1 = moment(this.timeInDt).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.timeInDt = date
      }
      if (this.timeOutDt !=="") {
        const d1 = moment(this.timeOutDt).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.timeOutDt = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }

      let vdwEffInFormat = ''; let vdwEffOutFormat = '';
      if (this.vdwEffIn !=="") {
        const d1 = moment(this.vdwEffIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        vdwEffInFormat = moment(date).format('YYYY-MM-DD')
        this.vdwEffIn = date
      }
      if (this.vdwEffOut !=="") {
        const d1 = moment(this.vdwEffOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        vdwEffOutFormat = moment(date).format('YYYY-MM-DD')
        this.vdwEffOut = date
        // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
      }
      let gridData
      let cmts;
    if (this.comments!==undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if (data==="initial") {
        gridData =
        {
          namc: this.namc,
          family: this.family,
          katashiki: this.katashiki,
          dest: this.destination,
          hikiate: this.hikiate,
          pcSpec: this.pcSpec,
          timeInDt: indateFormat,
          timeOutDt: outdateFormat,
          vdwEffIn: vdwEffInFormat,
          vdwEffOut: vdwEffOutFormat,
          comments: cmts,
          hundPercent: this.hundPercent,
          option: data
        }
      } else {
        gridData =
        {
          namc: "",
          family: "",
          katashiki: "",
          dest: "",
          hikiate: "",
          pcSpec: "",
          timeInDt: "",
          timeOutDt: "",
          vdwEffIn: "",
          vdwEffOut: "",
          comments: "",
          hundPercent: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.hunderedSpecsService.getGridData(gridData).subscribe(data => {
        console.info(data);
        this.showdata = true;
        console.info(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        this.rows = data.payload;
        const datarow = data.payload;
        this.rows = datarow.map(row => ({
          dest_ctry_id: row['dest_ctry_id'],
          pc_spec_cd_comb_txt: row['pc_spec_cd_comb_txt'],
          namc_cd: row['namc_cd'],
          fam_cd: row['fam_cd'],
          katashiki_cd: row['katashiki_cd'],
          hikiate_stat_comb_cd: row['hikiate_stat_comb_cd'],
          hundred_pct_optn_typ_cd: (row['hundred_pct_optn_typ_cd']===null) ? '' : row['hundred_pct_optn_typ_cd'].split('%')[0],
          eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
          vdw_eff_dt: (row['vdw_eff_dt']===null) ? '' : row['vdw_eff_dt'].split('T')[0],
          vdw_expr_dt: (row['vdw_expr_dt']===null) ? '' : row['vdw_expr_dt'].split('T')[0],
          cmt_txt: row['cmt_txt'],
          reqd_100pct_pc_spec_optn_id: row['reqd_100pct_pc_spec_optn_id']
        }));
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  //onChange  
  getFamily(): void {
    if (this.namc===undefined) {
      this.namc = ''
    }
    this.hunderedSpecsService.getFamilyHps(this.namc).subscribe(data => {
      console.info(data);
      this.famcdLst = data.payload;
      this.family = ""
      this.katashikiLst = [];
      this.destination = ""
      this.pcSpec = ""
      this.hikiate = ""
    });
  }

  getHikiate(): void {
    if (this.namc===undefined) {
      this.namc = ''
    }
    if (this.family===undefined) {
      this.family = ''
    }
    if (this.katashiki===undefined) {
      this.katashiki = ''
    }
    // if (this.destination !=="") {
     

    this.hunderedSpecsService.getHikiateStatusHps(this.namc, this.family, this.katashiki, this.destination).subscribe(data => {
      console.info(data);
      this.hikiateLst = data.payload;
      this.hikiate = ""
      this.pcSpec = ""
      
    });
  // }

// else{
//   this.hikiate = ""
// }
}
  getDestination(): void {
    if (this.katashiki===undefined) {
      this.katashiki = ''
    }
    this.hunderedSpecsService.getDestHps(this.katashiki).subscribe(data => {
      console.info(data);
      this.destLst = data.payload;
      this.destination = ""
      this.pcSpec = ""
      this.hikiate = ""
    });
  }

  getKatashikiHps(): void {
    if (this.namc===undefined) {
      this.namc = ''
    }
    if (this.family !=="") {

      this.hunderedSpecsService.getKatashikiHps(this.namc, this.family).subscribe(data => {
        console.info(data);
        this.katashikiLst = data.payload;

        this.destination = ""
        this.pcSpec = ""
        this.hikiate = ""
      });
    }
    else {
      this.katashiki = "";
    }
  }
  getPcSpecHps(): void {
    if (this.namc===undefined) {
      this.namc = ''
    }
    if (this.family===undefined) {
      this.family = ''
    }
    if (this.katashiki===undefined) {
      this.katashiki = ''
    }
    if (this.destination===undefined) {
      this.destination = ''
    }
    if (this.hikiate===undefined) {
      this.hikiate = ''
    }
    this.hunderedSpecsService.getPcSpecHps(this.namc, this.family, this.katashiki, this.destination, this.hikiate).subscribe(data => {
      console.info(data);
      this.pcSpecLst = data.payload;
      this.pcSpec = ""
    });
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "NAMC": row.namc,
        "Family": row.family,
        "Katashiki": row.katashiki,
        "Dest": row.dest,
        "Hikiate Status": row.hikiate,
        "PC Spec": row.pcSpec,
        "Time In Date": row.timeInDt,
        "Time Out Date": row.timeOutDt,
        "VDW Effective In": row.vdwEffIn,
        "VDW Effective Out": row.vdwEffOut,
        "Comments": row.comments,
        "100% Spec Id": row.hundPercent,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.info(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='timeInDt') {
      this.timeInDt = "";
    } if (name==='timeOutDt') {
      this.timeOutDt = "";
    }
    if (name==='vdwEffIn') {
      this.vdwEffIn = "";
    }
    if (name==='vdwEffOut') {
      this.vdwEffOut = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_9178') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }

}

