import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import * as moment from 'moment';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { RepresentativeVehiclesService } from '../representative-vehicles/representative-vehicles.service';
import { ExtraneousPartsListAddDialogComponent } from '../extraneous-parts-list-add-dialog/extraneous-parts-list-add-dialog.component';
import { ExtraneousPartsListEditDialogComponent } from '../extraneous-parts-list-edit-dialog/extraneous-parts-list-edit-dialog.component';
import { ExtraneousPartsListViewDialogComponent } from '../extraneous-parts-list-view-dialog/extraneous-parts-list-view-dialog.component';
import { CommentStmt } from '@angular/compiler';
import { ExtraneousPartsListServiceService } from './extraneous-parts-list-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-extraneous-parts-list',
  templateUrl: './extraneous-parts-list.component.html',
  styleUrls: ['./extraneous-parts-list.component.css']
})
export class ExtraneousPartsListComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'ExtraneousPartsList';
  exportData = [];
  sheetName = [];


  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';
  roleAccess: any = []
   accessEnable: boolean;
  Roledata: any = [];
  //ngModel values for search
  step = 0;
  extraneousPartType: string = "";
  matchingPartText: any = "";
  componentCode: string = "";
  sourceRouting: string = "";
  destRouting: string = "";

  spinnerMessage = "Please Wait...";
  recordcount: any = 0;
  //list
  extraneousPartTypeLst: string[] = [];
  matchingPartTextLst: string[] = [];
  componentCodeLst: string[] = [];
  sourceRoutingLst: string[] = [];
  destRoutingLst: string[] = [];

  //ngx-datatable
  rows = [];
  private _SelectionType = SelectionType;
  public get SelectionType() {
    return this._SelectionType;
  }
  public set SelectionType(value) {
    this._SelectionType = value;
  }
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [
    { name: 'extraneousPartType', prop: 'spcl_part_typ_nm' }, { name: 'extraneousPartDescription', prop: ' spcl_part_typ_desc' }, { name: 'matchingPartText', prop: 'mtchg_part_kywrd_txt' },
    { name: 'matchingPartDescriptionText', prop: 'mtchg_desc_kywrd_txt' }, { name: 'extraneousPartType', prop: 'extraneousPartType' },
    { name: 'componentCode', prop: ' cmpnt_cd' }, { name: 'sourceRouting', prop: 'src_rtg_cd' }, { name: 'destRouting', prop: 'dest_rtg_cd' },
    { name: 'effectiveInDate', prop: ' eff_dt' }, { name: 'effectiveOutDate', prop: ' expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }
  ];
  ColumnMode = ColumnMode;
  sameLengthRows: boolean = false;
  constructor(private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService,
    private extraneousPartsListServiceService: ExtraneousPartsListServiceService, public dialog: MatDialog, private exportService: ExportToExcelService,

    private dialogData: DialogData) { }


  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();
  }

  onloadfn() {
    this.getMatchPartTxt();
    this.getCompCode();
    this.getSrcRouting();
    this.fetchDestRouting();

    // to get extraneousPartType
    this.extraneousPartsListServiceService.getExtraneousPartType().subscribe(data => {
      console.info(data);
      this.extraneousPartTypeLst = data.payload;
    });
  }
  getMatchPartTxt() {
    // to get matchingPartText
    if (this.extraneousPartType===undefined) {
      this.extraneousPartType = ""
    }
    this.extraneousPartsListServiceService.getMatchingPartText(this.extraneousPartType).subscribe(data => {
      console.info(data);
      this.matchingPartTextLst = data.payload;
      this.matchingPartText = ""
      this.componentCode = ""
      this.sourceRouting = ""
      this.destRouting = ""
    });

  }

  getCompCode() {
    // to get componentCode
    if (this.extraneousPartType===undefined) {
      this.extraneousPartType = ""
    }
    if (this.matchingPartText===undefined) {
      this.matchingPartText = ""
    }
    this.extraneousPartsListServiceService.getComponentCode(this.extraneousPartType, this.matchingPartText).subscribe(data => {
      console.info(data);
      this.componentCodeLst = data.payload;
    });

  }

  getSrcRouting() {
    // to get sourceRouting
    if (this.extraneousPartType===undefined) {
      this.extraneousPartType = ""
    }
    if (this.matchingPartText===undefined) {
      this.matchingPartText = ""
    }
    if (this.componentCode===undefined) {
      this.componentCode = ""
    }
    this.extraneousPartsListServiceService.getSourceRouting(this.extraneousPartType, this.matchingPartText, this.componentCode,).subscribe(data => {
      console.info(data);
      this.sourceRoutingLst = data.payload;
    });
  }

  fetchDestRouting() {
    // to get destRouting
    if (this.extraneousPartType===undefined) {
      this.extraneousPartType = ""
    }
    if (this.matchingPartText===undefined) {
      this.matchingPartText = ""
    }
    if (this.componentCode===undefined) {
      this.componentCode = ""
    }
    if (this.sourceRouting===undefined) {
      this.sourceRouting = ""
    }
    this.extraneousPartsListServiceService.getDestRouting(this.extraneousPartType, this.matchingPartText, this.componentCode, this.sourceRouting).subscribe(data => {
      console.info(data);
      this.destRoutingLst = data.payload;
    });
  }


  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);


  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(ExtraneousPartsListAddDialogComponent, {
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        if (result==='success') {
          // this.resetAll("add");
          this.getGridData("add")
          this.successMessage = "Records Added Successfully"
          this.selected = []
        }
        else if (result==='AlreadyExists') {
          this.resetAll("initial");
          this.errorMessage = 'Data Entered already Exists!';
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    console.info(this.selected, this.selected.length)
    this.errorMessage = "";
    this.successMessage = "";
    console.info(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ExtraneousPartsListEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialogBox was closed');
          if (result==='success') {
            // this.resetAll("add");
            this.getGridData("add")
            this.successMessage = "Record Updated Successfully"
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to Edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to Edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(ExtraneousPartsListViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog Box was closed');

        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  search() {
    this.errorMessage = '';
    this.successMessage = "";
    this.getGridData("initial");
  }
  exportFile(): void {

    this.rows.forEach(row => {
      const obj = {
        "Extraneous Part Type": row.spcl_part_typ_nm,
        "Extraneous Part Description": row.spcl_part_typ_desc,
        "Matching Part Text": row.mtchg_part_kywrd_txt,
        "Matching Part Description Text": row.mtchg_desc_kywrd_txt,
        "Component Code": row.cmpnt_cd,
        "Source Routing": row.src_rtg_cd,
        "Dest Routing": row.dest_rtg_cd,
        "Effective In Date": row.eff_dt,
        "Effective Out Date": row.expr_dt,
        "Comments": row.rmrk_txt,

      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.extraneousPartType = row.spcl_part_typ_nm
      this.dialogData.extraneousPartDescription = row.spcl_part_typ_desc
      this.dialogData.matchingPartText = row.mtchg_part_kywrd_txt
      this.dialogData.matchingPartDescriptionText = row.mtchg_desc_kywrd_txt
      this.dialogData.componentCode = row.cmpnt_cd
      this.dialogData.sourceRouting = row.src_rtg_cd
      this.dialogData.destRouting = row.dest_rtg_cd
      this.dialogData.effectiveInDate = row.eff_dt
      this.dialogData.effectiveOutDate = row.expr_dt
      this.dialogData.comments = row.rmrk_txt
      this.dialogData.spcl_part_typ_id = row.spcl_part_typ_id

    });
    console.info(this.dialogData.monthlyRep)
  }

  resetAll(data): void {
    this.selected = []
    this.extraneousPartType = '';
    this.matchingPartText = '';
    this.componentCode = '';
    this.sourceRouting = '';
    this.destRouting = '';
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }



  getGridData(data) {
    this.selected = []
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      console.info(this.matchingPartText);
      let data1
      if (this.matchingPartText !==undefined) {
        data1 = this.matchingPartText.replaceAll('+', '%2B');
      } else {
        data1 = this.matchingPartText
      }
      console.info(data1)
      if (this.extraneousPartType===undefined) {
        this.extraneousPartType = ""
      }
      if (this.matchingPartText===undefined) {
        data1 = ""
      }

      if (this.componentCode===undefined) {
        this.componentCode = ""
      }
      if (this.sourceRouting===undefined) {
        this.sourceRouting = ""
      }
      if (this.destRouting===undefined) {
        this.destRouting = ""
      }
      if (this.effectiveInDate !=="") {
        this.effectiveInDate = this.pipe.transform(this.effectiveInDate, 'yyyy-MM-dd')
      }
      if (this.effectiveOutDate !=="") {
        this.effectiveOutDate = this.pipe.transform(this.effectiveOutDate, 'yyyy-MM-dd')
      }
      let gridData
      if (data==="initial") {
        gridData =
        {
          extraneousPartType: this.extraneousPartType,
          extraneousPartDescription: this.extraneousPartDescription,
          matchingPartText: data1,
          matchingPartDescriptionText: this.matchingPartDescriptionText,
          componentCode: this.componentCode,
          sourceRouting: this.sourceRouting,
          destRouting: this.destRouting,
          effectiveInDate: this.effectiveInDate,
          effectiveOutDate: this.effectiveOutDate,
          comments: this.comments,
          option: data
        }
      }
      else {
        gridData =
        {
          extraneousPartType: "",
          extraneousPartDescription: "",
          matchingPartText: "",
          matchingPartDescriptionText: "",
          componentCode: "",
          sourceRouting: "",
          destRouting: "",
          effectiveInDate: "",
          effectiveOutDate: "",
          comments: "",
          option: data
        }
      }
      console.info(gridData)
      this.SpinnerService.show();
      this.extraneousPartsListServiceService.getGridData(gridData).subscribe(data => {
        console.info(data)
        this.showdata = true;
        console.info(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        const datarow = data.payload;
        this.rows = datarow.map(row => ({
          spcl_part_typ_nm: row['spcl_part_typ_nm'],
          spcl_part_typ_desc: row['spcl_part_typ_desc'],
          mtchg_part_kywrd_txt: row['mtchg_part_kywrd_txt'],
          mtchg_desc_kywrd_txt: row['mtchg_desc_kywrd_txt'],
          cmpnt_cd: row['cmpnt_cd'],
          src_rtg_cd: row['src_rtg_cd'],
          dest_rtg_cd: row['dest_rtg_cd'],
          eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
          rmrk_txt: row['rmrk_txt'],
          spcl_part_typ_id: row['spcl_part_typ_id']
        }));

        console.info(this.rows)

      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have access/permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      const obj = {
        "Extraction Part Type": row.extraneousPartType,
        "Extraneous Part Description": row.extraneousPartDescription,
        "Matching Part Text": row.matchingPartText,
        "Matching Part Description Text": row.matchingPartDescriptionText,
        "Component Code": row.componentCode,
        "Source Routing": row.sourceRouting,
        "Dest Routing": row.destRouting,
        "Effective In Date": row.effectiveInDate,
        "Effective Out Date": row.effectiveOutDate,
        "Comments": row.comments,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.info(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_2250') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
        let roleList: any;
        const data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

          if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
         else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have no permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        };
      }
      console.info(this.roleAccess);
    })
  }

}


