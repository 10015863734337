import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';
var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class UsageVarianceCalculationService {
  searchdropdownUrlApi = environment.MastersearchdropdownapiUrl;
  qualandExcpgridapi = environment.QualandExcpgridapiUrl;
  namc = 'getNamc';
  family = '/getFamByNamcQual';
  gridData = '/getUsgVarianceCalculatnGrd'


  constructor(private http: HttpClient) { }

  //namc
  getNamc(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdownUrlApi}/${this.namc}`+'?screenNo=NBPC_7004'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)

      )
  }

  //family and famCdByNamc
  getFamily(namc): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // let famParams = new HttpParams().set('namcId', namc);
    const API_URL = environment.QualandExcpgridapi1Url + this.family + '?namcId=' + namc+'&screenNo=NBPC_7004'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //grid data
  getGridData(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.qualandExcpgridapi + this.gridData + '?namcCd=' + gridData.namc
      + '&famCd=' + gridData.family + '&katashikiCd=' + gridData.katashiki + '&GfileName=' + gridData.fileName+'&screenNo=NBPC_7004'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  fileUpldData(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
 const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    return this.http.get(environment.BussinessgridapiUrl + '/AssumeRole'+'?screenNo=NBPC_7004'+'&fileName=' +fileName, httpOptions)
      .map((response: any) => response).catch(this.error)
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any= '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }



}


