import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MngDataTransSapService } from './mng-data-trans-sap.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { MngDataTransEditDialogComponent } from '../mng-data-trans-edit-dialog/mng-data-trans-edit-dialog.component';
import { MngDataTransViewDialogComponent } from '../mng-data-trans-view-dialog/mng-data-trans-view-dialog.component';
import { DialogData } from '../dialog-data/dialog-data'
import { NgxSpinnerService } from "ngx-spinner";
import * as $ from 'jquery';

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-mng-data-trans-sap',
  templateUrl: './mng-data-trans-sap.component.html',
  styleUrls: ['./mng-data-trans-sap.component.css']
})
export class MngDataTransSapComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export
  fileName = "ManageDataTransfertoSAP";
  exportData = [];
  sheetName = [];

  //alertMessage
  errorMessage: string = '';
  successMessage: string = '';

  //ngModel values for search
  step = 0;
  namc: any = "";
  ebomToSAPTransmissionType: any = "";
  sendToSAPStartDate: any = "";
  sendToSAPEndDate: any = "";
  comments: any = "";
  sendToSAPStartTime: any = "";
  sendToSAPEndTime: any = "";
  spinnerMessage = "Please Wait...";


  //list
  namcLst: string[] = [];
  ebomToSAPTransmissionTypeLst: string[] = [];
  sendToSAPStartDateLst: string[] = [];
  sendToSAPEndDateLst: string[] = [];
  commentsLst: string[] = [];
  sendToSAPStartTimeLst: string[] = []
  sendToSAPEndTimeLst: string[] = []

  //mat-dialog
  data: any = {};



  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [

    { name: 'namc', prop: ' namc_lgl_entity_id' }, { name: 'ebomToSAPTransmissionType', prop: 'trans_typ | lowercase' },
    { name: 'sendToSapStartDate', prop: 'strt_dt ' }, { name: 'sendToSAPStartTime', prop: 'strt_time' },
    { name: 'sendToSapEndDate', prop: 'end_dt' }, { name: 'sendToSapEndTime', prop: 'end_time' },
    { name: 'comments', prop: 'comments' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  roleAccess: any = []
  Roledata: any;
  accessEnable: boolean;
  constructor(private mngDataTransSapService: MngDataTransSapService, public dialog: MatDialog,
    private exportService: ExportToExcelService, private dialogData: DialogData, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) {

  }

  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();

  }
onloadfn(){
  //to get namc
  this.mngDataTransSapService.getNAMC().subscribe(data => {
    console.info(data);
    this.namcLst = data.payload;
  });
}

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }


  exportFile(): void {

    this.rows.forEach(row => {
      let obj =
      {
        "NAMC": row.namc_cd,
        "eBoM To SAP Transmission Type": row.trans_typ,
        "Send To SAP Start Date": row.strt_dt,
        "Send To SAP End Date": row.strt_time,
        "Send To SAP Start Time": row.end_dt,
        "Send To SAP End Time": row.end_time,
        "Comments": row.comments
      }
      this.exportData.push(obj);
    });
    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(MngDataTransEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          this.selected = [];

          if (result==='success') {
            // this.resetAll("add");
            this.getGridData("all")
            this.successMessage = "Record Updated Successfully"
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.resetAll("initial");
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }

        });

      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation"
      this.globalrole.goToTop();
    }
  }

  openViewDialog() {
    this.errorMessage = '';
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(MngDataTransViewDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          this.selected = [];
        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  sendToDialog(selected): void {

    selected.forEach(row => {
      console.info(row);
      this.dialogData.namc = row.namc_cd;
      this.dialogData.ebomToSAPTransmissionType = row.trans_typ;
      this.dialogData.sendToSAPStartDate = row.strt_dt;
      this.dialogData.sendToSAPEndDate = row.end_dt;
      this.dialogData.sendToSAPStartTime = row.strt_time;
      this.dialogData.sendToSAPEndTime = row.end_time;
      this.dialogData.comments = row.comments;
      this.dialogData.sap_manage_data_trnfr_id = row.sap_manage_data_trnfr_id
    });

  }
  resetAll(data): void {
    this.selected = [];
    this.errorMessage = '';
    this.successMessage = '';
    this.namc = '';
    this.ebomToSAPTransmissionType = '';
    this.sendToSAPStartDate = '';
    this.sendToSAPEndDate = '';
    this.sendToSAPStartTime = '';
    this.sendToSAPEndTime = '';
    this.comments = '';
    // this.getGridData(data);
    this.onloadfn();
  }
  search() {

    if (this.sendToSAPStartDate && this.sendToSAPEndDate && this.pipe.transform(this.sendToSAPStartDate, 'yyyy-MM-dd') > this.pipe.transform(this.sendToSAPEndDate, 'yyyy-MM-dd')) {

      this.errorMessage = "End Date should be greater than or equal to Start Date";
      this.globalrole.goToTop();
    }
    else if ((this.sendToSAPStartTime !=="" && this.sendToSAPStartDate==="") || (this.sendToSAPStartTime==="" && this.sendToSAPStartDate !=="")) {
      this.errorMessage = "Please Enter Both SAP Start Date And Start Time";
      this.globalrole.goToTop();
    }
    else if ((this.sendToSAPEndTime !=="" && this.sendToSAPEndDate==="") || (this.sendToSAPEndTime==="" && this.sendToSAPEndDate !=="")) {
      this.errorMessage = "Please Enter Both SAP End Date And End Time";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }



  getGridData(data) {
    this.selected = [];
    this.errorMessage = "";
    this.successMessage = "";
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.ebomToSAPTransmissionType===undefined) {
        this.ebomToSAPTransmissionType = ""
      }
      let indateFormat = ''; let outdateFormat = ''; let indate = ''; let outdate = ""
      console.info("304", this.sendToSAPStartDate)
      if (this.sendToSAPStartDate !=="") {
        const d1 = moment(this.sendToSAPStartDate).format('YYYY-MM-DD')
        console.info("307", d1)
        const b = d1.split('T')[0]
        console.info("309", b)
        let parts = []
        parts = b.split('-');
        console.info("311", parts)
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        console.info("314", date)
        indateFormat = moment(date).format('YYYY-MM-DD')
        console.info("315", indateFormat)
        this.sendToSAPStartDate = date
      }
      if (this.sendToSAPEndDate !=="") {
        const d1 = moment(this.sendToSAPEndDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.sendToSAPEndDate = date
      }
      if (indateFormat==="") {
        indate = ""
      } else {
        indate = indateFormat + ' ' + this.sendToSAPStartTime
      }
      if (outdateFormat==="") {
        outdate = ""
      } else {
        outdate = outdateFormat + ' ' + this.sendToSAPEndTime
      }
      let gridData
      let cmts;
      if (this.comments!==undefined) {
        cmts = this.comments.replaceAll("'", "''");
      } else {
        cmts = this.comments
      }
      if (data==="initial") {
        gridData =
        {
          namc: this.namc,
          ebomToSAPTransmissionType: this.ebomToSAPTransmissionType,
          sendToSAPStartDate: indate,
          sendToSAPEndDate: outdate,
          sendToSAPStartTime: this.sendToSAPStartTime,
          sendToSAPEndTime: this.sendToSAPEndTime,
          comments: cmts,
          option: data
        }
      }
      else {
        gridData =
        {
          namc: "",
          ebomToSAPTransmissionType: "",
          sendToSAPStartDate: "",
          sendToSAPEndDate: "",
          sendToSAPStartTime: "",
          sendToSAPEndTime: "",
          comments: "",
          option: data
        }
      }
      console.info(gridData)
      this.SpinnerService.show();
      this.mngDataTransSapService.getMngDataTrnsfrGrid(gridData).subscribe(data => {
        console.info("data", data);
        this.showdata = true;
        console.info(this.showdata);
        this.SpinnerService.hide();
        this.recordcount = data.pagination.totalRecords;
        const datarow = data.payload;
        for (let i = 0; i < datarow.length; i++) {
          let strtm; let endtm; let d;
          if (datarow[i].strt_dt_time) {
            strtm = datarow[i].strt_dt_time.split('.')[0]
            d = strtm.split('T')[1]
          }
          // this.rows[i].namc_cd = datarow[i].namc_cd
          console.info(d, strtm);
        }
        this.rows = datarow.map(row => ({
          namc_cd: row['namc_cd'],
          trans_typ: row['trans_typ']==='A' ? 'Automatic' : 'Manual',
          strt_dt: row['strt_dt'],
          end_dt: row['end_dt'],
          strt_time: row['strt_time'],
          end_time: row['end_time'],
          comments: row['comments'],
          sap_manage_data_trnfr_id: row['sap_manage_data_trnfr_id']
        }));

        console.info(this.rows)
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='sendToSAPStartDate') {
      this.sendToSAPStartDate = "";
    } else if (name==='sendToSAPEndDate') {
      this.sendToSAPEndDate = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_9997') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.info(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.info(this.roleAccess);
    })
  }

}
