import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { RepresentativeUnitsService } from './representative-units.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepUnitsAddDialogComponent } from '../rep-units-add-dialog/rep-units-add-dialog.component';
import { RepUnitsCopyaddDialogComponent } from '../rep-units-copyadd-dialog/rep-units-copyadd-dialog.component'
import { DialogData } from '../dialog-data/dialog-data'
import { RepUnitsEditDialogComponent } from '../rep-units-edit-dialog/rep-units-edit-dialog.component';
import { RepUnitsMultieditDialogComponent } from '../rep-units-multiedit-dialog/rep-units-multiedit-dialog.component';
import { RepUnitsViewDialogComponent } from '../rep-units-view-dialog/rep-units-view-dialog.component'
import { RepUnitsUploadDialogComponent } from '../rep-units-upload-dialog/rep-units-upload-dialog.component'
import { ExportToExcelService } from '../common/export-to-excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';

import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-representative-units',
  templateUrl: './representative-units.component.html',
  styleUrls: ['./representative-units.component.css']
})
export class RepresentativeUnitsComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //alertMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  spinnerMessage = "Please Wait...";

  // filename 
  fileName: string = "RepresentativeUnits"
  exportData: any = []
  sheetName = [];
  errorSheetName = ['REP_UNIT_ERROR'];
  errorFileName = 'RepUnitError';
  //ngModel values for search
  step = 0;
  namcCd: string = "";
  famCd: string = "";
  prodTyp: string = "";
  katashiki: string = "";
  effDateIn: any = "";
  effDateOut: any = "";
  comments: any = "";
  part: string = "";
  partDesc: string = "";
  sapMtrlId: string = "";
  screen = 'unit'

  //list
  namcLst = [];
  famLst = [];
  prodTypLst: string[] = [];
  katashikiLst = [];

  //ngx-dataTable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  showdata: boolean = true;
  columns = [

    { name: 'namc', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'prodType', prop: 'prod_typ_desc' }, { name: 'prodTypecd', prop: 'prod_typ_cd' },
    { name: 'relKatashiki', prop: 'rel_katashiki' }, { name: 'extractionTimeIn', prop: 'eff_dt' },
    { name: 'extractionTimeOut', prop: 'expr_dt' }, { name: 'comments', prop: 'rmrk_txt' }, { name: 'part', prop: 'part_no' },
    { name: 'partDesc', prop: 'part_desc' }, { name: 'sapMtrlId', prop: 'sap_mtrl_id' }, { name: 'grpi', prop: 'grpi_cd' },
    { name: 'repProddefId', prop: 'rep_prod_def_id' }, { name: 'monthlyRep', prop: 'monthly_rep_flg' }

  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  roleAccess: any = [];
  Roledata: any;
  accessEnable: boolean;
  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.monthlyRep = row.monthly_rep_flg==='Y' ? true : false
      this.dialogData.sapMatId = row.sap_mtrl_id
      this.dialogData.namc = row.namc_cd
      this.dialogData.family = row.fam_cd
      this.dialogData.part = row.part_no
      this.dialogData.grpi = row.grpi_cd
      this.dialogData.partDesc = row.part_desc
      this.dialogData.katashiki = row.rel_katashiki
      this.dialogData.prodType = row.prod_typ_desc
      this.dialogData.extractionTimeIn = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.extractionTimeOut = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt;
      this.dialogData.repProdDefId = row.rep_prod_def_id;
    });
  }

  resetAll(data): void {
    this.selected = [];
    this.namcCd = '';
    this.famCd = '';
    this.prodTyp = '';
    this.katashiki = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.comments = '';
    this.part = '';
    this.partDesc = '';
    this.sapMtrlId = '';
    this.errorMessage = '';
    this.successMessage = '';
    this.katashikiLst = []
    this.onloadfn();
    // this.getGridData(data);
  }
  constructor(private repUnitsService: RepresentativeUnitsService, public dialog: MatDialog, private dialogData: DialogData, private exportService: ExportToExcelService, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }

  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }

    if (localStorage.getItem('partnumber')) {
      localStorage.removeItem('partnumber')
    }
    this.onloadfn();
  }

  onloadfn() {
    this.namconload();
    this.getFamily();
    this.prodtyponload();
  }


  namconload() {
    this.repUnitsService.getNamc().subscribe(data => {
      console.info(data)
      this.namcLst = data.payload;
      console.info(this.namcLst)
    });
  }
  prodtyponload() {
    this.repUnitsService.getProdType().subscribe(data => {
      console.info(data)
      this.prodTypLst = data.payload;
    });
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);
  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(RepUnitsAddDialogComponent, {
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed', result);
        if (result==='success') {
          this.successMessage = "Record Added Successfully";
          // this.Resetafterdialogmsg("add");
          this.getGridData("add");
          this.selected = []
        }
        else if (result==='AlreadyExists') {
          this.errorMessage = 'Record Entered already Exists!'
          this.globalrole.goToTop();
          // this.Resetafterdialogmsg("initial");
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openCopyAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepUnitsCopyaddDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });
        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed', result);

          if (result==='success') {
            this.successMessage = "Record Added Successfully";
            // this.Resetafterdialogmsg("add");
            this.getGridData("add");
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Record Entered already Exists!';
            this.globalrole.goToTop();
            // this.Resetafterdialogmsg("initial");
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else if (this.selected.length <= 0) {
        this.errorMessage = "Please select a row to copy-add";
        this.globalrole.goToTop();
      }
      else {
        this.errorMessage = "Please select only one row to copy-add";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    let count = this.selected.length
    console.info(this.roleAccess.includes('Update'))
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepUnitsEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          if (result==='success') {
            this.successMessage = "Record Updated Successfully";
            // this.Resetafterdialogmsg("add");
            this.getGridData("add");
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Record Entered already Exists!';
            this.globalrole.goToTop();
            // this.Resetafterdialogmsg("initial");
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else if (this.selected.length > 1) {
        const dialogRef = this.dialog.open(RepUnitsMultieditDialogComponent, {
          disableClose: true,
          data: this.selected
        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          this.selected = [];
          if (result==='success') {
            this.successMessage = +count + " Record (S) Updated Successfully"
            // this.Resetafterdialogmsg("add");
            this.getGridData("add");
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Record Entered already Exists!';
            this.globalrole.goToTop();
            // this.Resetafterdialogmsg("initial");
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    console.info(this.roleAccess.includes('Read'))
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(RepUnitsViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "Please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "Please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation"
      this.globalrole.goToTop();
    }
  }
  openUploadDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    console.info(this.roleAccess.includes('Create'))
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(RepUnitsUploadDialogComponent, {
        disableClose: true,
        // width: '50%'
      });
      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        if (result==='success') {
          this.getGridData("add");
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result==='cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      });
    }
    else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthly_rep_flg,
        "NAMC": row.namc_cd,
        "SAP Material ID": row.sap_mtrl_id,
        "Family": row.fam_cd,
        "Part Number": row.part_no,
        // "Part Description": row.part_desc,
        "Product Type": row.prod_typ_cd,
        "GRPI": row.grpi_cd,
        "Related Katashiki": row.rel_katashiki,
        "Extaction Time In": row.eff_dt,
        "Extraction Time Out": row.expr_dt,
        "Comments": row.rmrk_txt,
        "Rep Prod Def Id": row.rep_prod_def_id
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  getFamily(): void {
    console.info(this.namcCd)
    if (this.namcCd===undefined) {
      this.namcCd = ""
    }
    this.repUnitsService.getFamily(this.namcCd, this.screen).subscribe(data => {
      console.info(data);
      this.famLst = data.payload;
      this.famCd = ""
      this.katashiki = ""
    });
  }

  //onChangeMethod
  getFamCdByNamc(): void {
    this.getFamily();
  }

  getKatas(): void {
    console.info(this.famcd)
    if (this.famCd===undefined) {
      this.famCd = ""
    }
    if (this.famCd !=="") {
      this.repUnitsService.getKatashikiByFamCd(this.famCd).subscribe(data => {
        console.info(data);
        this.katashikiLst = data.payload;
        this.katashiki = ""
      });
    }
  }
  //onChangeMethod
  getKatashikiByFamCd(): void {
    this.getKatas();
  }

  search() {
    if (this.effDateIn && this.effDateOut && this.pipe.transform(this.effDateIn, 'yyyy-MM-dd') > this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
      this.globalrole.goToTop();
    }
    else {
      this.errorMessage = '';
      this.successMessage = "";
      this.getGridData("initial");
    }
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  getGridData(data) {
    this.selected = [];
    if (data==="initial") {
      this.errorMessage = '';
      this.successMessage = '';
    }
    console.info("namc", this.namcCd)
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.namcCd===undefined) {
        this.namcCd = ""
      }

      if (this.famCd===undefined) {
        this.famCd = ""
      }

      if (this.prodTyp===undefined) {
        this.prodTyp = ""
      }

      if (this.katashiki===undefined) {
        this.katashiki = ""
      }

      if (this.part===undefined) {
        this.part = ""
      }

      if (this.partDesc===undefined) {
        this.partDesc = ""
      }

      if (this.comments===undefined) {
        this.comments = ""
      }

      let indateFormat = ''; let outdateFormat = '';
      if (this.effDateIn !=="") {
        const d1 = moment(this.effDateIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateIn = date
      }
      if (this.effDateOut !=="") {
        const d1 = moment(this.effDateOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.effDateOut = date
      }
      if (this.sapMtrlId===undefined) {
        this.sapMtrlId = ""
      }
      let newDate = new Date();
      const date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      const fileName = 'Rep-units-Grid/RepUntGrdSrc' + date + '.json'
      let gridData
      let cmts;
    if (this.comments !==undefined) {
      cmts = this.comments.replaceAll("'", "''");
    } else {
      cmts = this.comments
    }
      if (data==="initial") {
        gridData =
        {
          namc: this.namcCd,
          family: this.famCd,
          prodtyp: this.prodTyp,
          katashiki: this.katashiki,
          extTimeIn: indateFormat,
          extTimeOut: outdateFormat,
          comments:cmts,
          part: this.part,
          partDesc: this.partDesc,
          sapMtrlId: this.sapMtrlId,
          option: data,
          fileName: fileName
        }
      } else {
        gridData =
        {
          namc: "",
          family: "",
          prodtyp: "",
          katashiki: "",
          extTimeIn: "",
          extTimeOut: "",
          comments: "",
          part: "",
          partDesc: "",
          sapMtrlId: "",
          option: data,
          fileName: fileName
        }
      }
      console.info(gridData);
      this.SpinnerService.show();
      this.repUnitsService.getGridData(gridData).subscribe(data => {
        console.info(data);
        this.showdata = true;
        console.info(this.showdata);
        this.recordcount = data.pagination.totalRecords;
        const datarow = data.payload;
        if (data.payload[0].RecordCnt > 1000) {
          setTimeout(() => {
            this.getFileFromS3(fileName);
          }, 2000);
        } else {
          this.rows = datarow.map(row => ({
            namc_cd: row['namc_cd'],
            fam_cd: row['fam_cd'],
            prod_typ_desc: row['prod_typ_desc'],
            rel_katashiki: row['rel_katashiki'],
            eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
            expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
            rmrk_txt: row['rmrk_txt'],
            part_no: row['part_no'],
            part_desc: row['part_desc'],
            prod_typ_cd: row['prod_typ_cd'],
            sap_mtrl_id: row['sap_mtrl_id'],
            grpi_cd: row['grpi_cd'],
            rep_prod_def_id: row['rep_prod_def_id'],
            monthly_rep_flg: row['monthly_rep_flg']
          }));
          this.SpinnerService.hide();
          console.info(this.rows)
        }
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  errorExport(data) {
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "Monthly Representative": row.monthlyRep,
        "NAMC": row.namc,
        "SAP Material ID": row.sapMatId,
        "Family": row.family,
        "Part Number": row.partNo,
        "Product Type": row.prod_typ_cd,
        "GRPI Code": row.grpiCd,
        "Related Katashiki": row.relatedKatshiki,
        "Extaction Time In": row.extTimeIn,
        "Extraction Time Out": row.extTimeOut,
        "Comments": row.comments,
        "Error": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.info(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }

  partdescription(partdata) {
    this.errorMessage = "";
    if (this.part==="") {
      console.info('1')
      this.partDesc = ""
    }
    else if (this.part !=="") {

      if (this.partDesc==="") {
        this.repUnitsService.getPartDesc(this.part).subscribe(data => {
          console.info(data)
          if (data.payload) {
            this.partDesc = data.payload[0].part_desc;
            this.errorMessage = "";
            localStorage.setItem("partnumberpopup", this.aPart);
          }
          else {
            this.errorMessage = "Part Number <" + this.part + "> Invalid"
            // this.part = ""
            this.partDesc = ""
          }

        });
      }
      else if (this.partDesc !=="") {
        if (this.part !==localStorage.getItem('partnumber'))
          this.repUnitsService.getPartDesc(this.part).subscribe(data => {
            console.info(data)
            if (data.payload) {
              this.partDesc = data.payload[0].part_desc;
              this.errorMessage = "";
              localStorage.setItem("partnumberpopup", this.aPart);
            }
            else {
              this.errorMessage = "Part Number <" + this.part + "> Invalid"
              // this.part = ""
              this.partDesc = ""
            }
          });
      }
    }

  }
  clearDate(event, name) {
    event.stopPropagation();
    if (name==='effDateIn') {
      this.effDateIn = "";
    } else if (name==='effDateOut') {
      this.effDateOut = "";
    }
  }

  Resetafterdialogmsg(data) {
    this.namcCd = '';
    this.famCd = '';
    this.prodTyp = '';
    this.katashiki = '';
    this.effDateIn = '';
    this.effDateOut = '';
    this.comments = '';
    this.part = '';
    this.partDesc = '';
    this.sapMtrlId = '';
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_9355') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.info(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.info(this.roleAccess);
    })
  }

  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    const a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.info(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.info('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.info(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.info("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res.map(row => ({
              namc_cd: row['namc_cd'],
              fam_cd: row['fam_cd'],
              prod_typ_desc: row['prod_typ_desc'],
              rel_katashiki: row['rel_katashiki'],
              eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
              expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
              rmrk_txt: row['rmrk_txt'],
              part_no: row['part_no'],
              part_desc: row['part_desc'],
              prod_typ_cd: row['prod_typ_cd'],
              sap_mtrl_id: row['sap_mtrl_id'],
              grpi_cd: row['grpi_cd'],
              rep_prod_def_id: row['rep_prod_def_id'],
              monthly_rep_flg: row['monthly_rep_flg']

            }));
            console.info(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.repUnitsService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data�Storage�Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable�to�get�keys�and�token', 'Failed');
      });
    })
  }

}
