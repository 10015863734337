import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialog-data/dialog-data';
import { ManualAdjustmentRuleEditDialogService } from './manual-adjustment-rule-edit-dialog.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-manual-adjustment-rule-edit-dialog',
  templateUrl: './manual-adjustment-rule-edit-dialog.component.html',
  styleUrls: ['./manual-adjustment-rule-edit-dialog.component.css']
})
export class ManualAdjustmentRuleEditDialogComponent implements OnInit {
  editRuleType: any;

  //to main page
  message = "Records Edited Successfully";
  editVersionName: any;
  editNamc: any;
  editFamily: any;
  editKatasPart: any;
  editGrpi: any;
  editPartNo: any;
  editRuleStatus: any;
  editCreateFromDt: any;
  editCreateToDt: any;
  editBomEffFrom: any;
  editBomEffTo: any;
  editSp: any;
  editNetSpec: any;
  editDest: any;
  editQtyPerPar: any;
  editCmpRouting: any;
  editActUnitCst: any;
  editActCurrencyCd: any;
  editComments: any = "";
  editPartDesc: any;

  spinnerMessage = "Please Wait...";
  //response after insert in a record
  response: any;
  manAdjRuleDefId: any;

  constructor(private readonly SpinnerService: NgxSpinnerService,
    private editManAdjRuleService: ManualAdjustmentRuleEditDialogService,
    public dialogRef: MatDialogRef<ManualAdjustmentRuleEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    console.info("data", this.data);
    this.manAdjRuleDefId = this.data.man_adjstmnt_rule_def_id
    this.editRuleType = this.data.ruleType;
    this.editVersionName = this.data.versionName;
    this.editNamc = this.data.namc;
    this.editFamily = this.data.family;
    this.editKatasPart = this.data.katasPart;
    this.editGrpi = this.data.grpi;
    this.editRuleStatus = this.data.ruleStatus;
    this.editPartNo = this.data.partNo;
    this.editCreateFromDt = this.data.createFromDt;
    this.editCreateToDt = this.data.createToDt;
    this.editBomEffFrom = this.data.bomEffFrom;
    this.editBomEffTo = this.data.bomEffFrom;
    this.editSp = this.data.sp;
    this.editNetSpec = this.data.netSpec;
    this.editDest = this.data.dest
    this.editQtyPerPar = this.data.qtyPerPar;
    this.editCmpRouting = this.data.cmpRouting;
    this.editActUnitCst = this.data.actUnitCst;
    this.editActCurrencyCd = this.data.actCurrencyCd;
    this.editComments = this.data.comments;
    this.editPartDesc = this.data.partDesc;

  }

  manAdjEdit() {
    const fileName= localStorage.getItem('Filename')
    let createby = localStorage.getItem('workDayId');
    if (this.editComments==='') {
      this.editComments = ' '
    }
    let cmts;
    if (this.editComments!==undefined) {
      cmts = this.editComments.replaceAll("'", "''");
    } else {
      cmts = this.editComments
    }
    const body = {
      "comments": cmts,
      "ManualAdjustmentRuleId": this.manAdjRuleDefId,
      "screenNo":"NBPC_10122",
      "fileName" :fileName
    }
    this.SpinnerService.show()
    this.editManAdjRuleService.editManAdjRule(body).subscribe(data => {
      console.info('res from edit', data);
      this.response = data.payload;
      this.dialogRef.close(this.response[0].result);
    }, err => {
      this.SpinnerService.hide();
      console.info("err", err);
    });


  }
  cancel() {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }

}
