import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';


var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class HikiateStatusService {


  searchdropdown3UrlApi = environment.Mastersearchdropdown3apiUrl;


  namcHs = '/getNamcHs'
  familyByNamcHs = '/getFamilyByNamcHs'
  family ='/getfamilyHikiStsScrn'
  hikiateStatusHs = '/getHikiateStatusHs'
  gridData = '/getHikiateStatusGrid'

  constructor(private http: HttpClient) { }

  //namc
  getNamcHs(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url = environment.Mastersearchdropdown3apiUrl + this.namcHs+'?screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.error)
  }

  //familybynamc
  getFamilyHs(namc): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    const url = environment.Mastersearchdropdown3apiUrl + this.familyByNamcHs + '?namcId=' + namc+'&screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.error)
  }
  
//family
  getFamily(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url = environment.Mastersearchdropdown3apiUrl + this.family+'?screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.error)
  }

  //hikiate status
  getHikiateStatusHs(): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    // const API_URL = `${this.searchdropdown3UrlApi}/${this.hikiateStatusHs}`;
    const url = environment.Mastersearchdropdown3apiUrl + this.hikiateStatusHs+'?screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(url, httpOptions)
      .map((res: any) => res).catch(this.error)
  }



  //grid data
  getHikiateStatusGrid(gridData): Observable<any> {
   var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = environment.MastergridapiUrl + this.gridData + '?namc=' + gridData.namc +
      '&family=' + gridData.family + '&hikiate=' + gridData.hikiateStatus + '&effInDt=' + gridData.effectiveInDate +
      '&effOutDt=' + gridData.effectiveOutDate + '&option=' + gridData.option+'&screenNo=NBPC_9996'+'&fileName=' +fileName
    return this.http.get(API_URL,httpOptions)
      .pipe(catchError(this.error)
      )
  }



  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any= '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }


  

}
