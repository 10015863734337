import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/Rx';
import {environment} from 'src/environments/environment';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken'),
      'x-api-key':localStorage.getItem('Filename')
    })
  };
}

@Injectable({
  providedIn: 'root'
})
export class ManualAdjustmentRuleDeleteDialogService {
 
  dltManAdjRuleData='/deleteManualAdjustmentRule';

  constructor(private http: HttpClient) { }

  //dltManAdjRule
  dltManAdjRule(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const url= environment.BusinessActionRestapiUrl + this.dltManAdjRuleData
    return this.http.post(url,body, httpOptions)
      .map((res: any) => res).catch(this.handleError)
}


  // ===================================== Exception Handler ===========================================
 private handleError(error: HttpErrorResponse) {
  var errorMsg = '';
  if (error.error instanceof ErrorEvent) {
    //A client-side or network error occurred. Handle it accordingly.
    errorMsg = `An error occurred: ${error.error.message}`;
  } else {
    //The backend returned an unsuccessful response code.
    //The response body may contain clues as to what went wrong,
    errorMsg = `Backend returned code ${error}`;
  }
  //return an observable with a user-facing error message
  return throwError(errorMsg);
}

}
