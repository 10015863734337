import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {DialogData} from '../dialog-data/dialog-data';
import {PartsWithoutCostDetailService} from './parts-without-cost-detail.service';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { NgxSpinnerService } from "ngx-spinner";
import { GlobalService } from '../global.service';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-parts-without-cost-detail',
  templateUrl: './parts-without-cost-detail.component.html',
  styleUrls: ['./parts-without-cost-detail.component.css']
})
export class PartsWithoutCostDetailComponent implements OnInit {


//Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export
  fileName = "c8partsWithoutCostDetail";
  exportData = [];
  sheetName = [];

   //alertMessage
   errorMessage:string='';
  
  //ngModel values for search
  step = 0;
  namc: any = "";
  family: any = "";
  version: any = "";
  partSource: any = "";
  completePartRouting:  any = "";
  costType:  any = "";
  katashkiPart:  any = "";
  grpi:  any = "";
  vinsrNo:  any = "";
  part:  any = "";
  buyer: any = "";
  startDate: any = "";
  endDate: any = "";
  errMsg: any = "";
  bomEffectivityDate: any = "";
  dest: any = "";
  vinSrNo: any = "";
  supplier: any = "";
  supplierName: any = "";
  buyerName:  any = "";
  actCost:  any = "";
  trnCost: any = "";
  currencyCode: any = "";
  waterfallStatus: any = "";
  suspensionDate: any = "";
  prodDefId: any = "";
  prodBomId: any = "";
  spinnerMessage = "Please Wait...";
  accessKey: any
  secretKey: any
  sessionToken: any
  fileFormat: any


  //list
  namcLst: string[] = [];
  familyLst: string[] = [];
  versionLst: string[] = [];
  partSourceLst: string[] = [];
  completePartRoutingLst: string[] = [];
  costTypeLst: string[] = [];
  katashkiPartLst: string[] = [];
  grpiLst: string[] = [];
  vinsrNoLst: string[] = [];
  partLst: string[] = [];
  buyerLst: string[] = [];
  startDateLst: string[] = [];
  endDateLst: string[] = [];
  errMsgLst: string[] = [];

 //mat-dialog
 data:any={};

 //ngx-datatable
 rows = [];
 SelectionType = SelectionType;
 selected = [];
 isLoading: boolean = false;//loadingIndicator = false;
 reorderable = true;
 @ViewChild('myTable') table;
 limitSelected:any=50;
 showGrid:boolean=false;
 showButtons:boolean=false;
 showdata: boolean = true;
 columns = [

  { name:'namc',prop:'namc_cd'},{ name: 'family',prop: 'fam_cd'},
  { name: 'version',prop: 'prod_bom_desc'},{ name:'katashiki/Part',prop: 'katashiki_cd'},
  { name: 'bomEffectivityDate' ,prop:'prod_trnfr_dt'},{ name: 'grpi',prop:'grpi_cd'},
  { name: 'partSource',prop:'part_source'},{ name: 'completePartRouting',prop:'part_src_rtng'},
  { name: 'part' ,prop:'part_no'},{ name: 'partDescription',prop:'part_desc'},
  { name: 'dest',prop:'dest_ctry_id'},{ name: 'vin/srNo',prop:'vin_serl_no'},
  { name: 'supplier',prop:'supplier_cd'},
  { name: 'supplierName' ,prop:'supplier_nm'},{ name: 'buyer',prop:'buyer_cd'},
  { name: 'buyerName',prop:'buyer_nm'},{ name: 'costType',prop:'cost_type'},
  { name: 'actCost' ,prop:'actl_cost'},{ name: 'trnCost',prop:'trnfr_cost'},
  { name: 'currencyCode',prop:'crncy_cd'},{ name: 'waterfallStatus',prop:'waterfall_status'},
  { name: 'suspensionDate',prop:'excp_dt'},
  { name: 'errorMessage' ,prop:'err_desc'},{ name: 'prodDefId',prop:'prod_def_id'},
  { name: 'prodBomId',prop:'prod_bom_id'}

  ];
  ColumnMode = ColumnMode;
  recordcount: any;
  accessEnable: boolean;
  roleAccess: any = []
  Roledata: any = []
  constructor(private partsWithoutCostDetailService: PartsWithoutCostDetailService, public dialog: MatDialog, private dialogData: DialogData, public globalrole: GlobalService,
    private exportService: ExportToExcelService, private readonly SpinnerService: NgxSpinnerService) {

  }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    this.SpinnerService.hide();
    if (a) {
      console.info('a', a)
      //grid
      this.onloadfn();
    }
  }

  onloadfn() {
    //to get namc
    this.partsWithoutCostDetailService.getNamc().subscribe(data => {
      console.info(data);
      this.namcLst = data.payload;
    });

    // to getFamily
    this.partsWithoutCostDetailService.getFamily(this.namc).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
    });

    // to getVersion
    this.partsWithoutCostDetailService.getVersion().subscribe(data => {
      console.info(data);
      this.versionLst = data.payload
    });

    // to getCostType
    this.partsWithoutCostDetailService.getCostType().subscribe(data => {
      console.info(data);
      this.costTypeLst = data.payload;
    });

    // to getPartSource
    this.partsWithoutCostDetailService.getPartSource().subscribe(data => {
      console.info(data);
      this.partSourceLst = data.payload;
    });

    // to getCompletePartRouting
    this.partsWithoutCostDetailService.getCompletePartRouting(this.namc).subscribe(data => {
      console.info(data);
      this.completePartRoutingLst = data.payload;
    });

    // to geterrorMessage
    this.partsWithoutCostDetailService.getErrorMessage().subscribe(data => {
      console.info(data);
      this.errMsgLst = data.payload;
    });


  }

  getFamCdByNamc() {
    if (this.namc===undefined) {
      this.namc = "";
    }
    //to get family
    this.partsWithoutCostDetailService.getFamily(this.namc).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
      this.family = "";
    });
  }

   //step for expansion panel
   setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    console.info('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }
  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }

  //search func
  generateReport(): void {
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      this.showGrid = true;
      this.showButtons = true;
      this.errorMessage = ""
      this.getGridData();
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  exportFile(): void {
    if (this.rows.length !==0) {
      this.rows.forEach(row => {
        let obj =
        {
          "NAMC": row.namc_cd,
          "Family": row.fam_cd,
          "Version": row.prod_bom_desc,
          "Katashki/Part": row.katashiki_cd,
          "Bom Effectivity": row.prod_trnfr_dt,
          "GRPI": row.grpi_cd,
          "Part Source": row.part_source,
          "Complete Part Routing": row.part_src_rtng,
          "Part": row.part_no,
          "Part Description": row.part_desc,
          "Dest": row.dest_ctry_id,
          "VIN/SR No": row.vin_serl_no,
          "Supplier": row.supplier_cd,
          "Supplier Name": row.supplier_nm,
          "Buyer": row.buyer_cd,
          "Buyer Name": row.buyer_nm,
          "Cost Type": row.cost_type,
          "ACT Cost": row.actl_cost,
          "TRN Cost": row.trnfr_cost,
          "Currency Code": row.crncy_cd,
          "Waterfall Status": row.waterfall_status,
          "Suspension Date": row.excp_dt,
          "Error Message": row.err_desc,
          "Prod Def Id": row.prod_def_id,
          "Prod Bom Id": row.prod_bom_id

        }
        this.exportData.push(obj);
      });
    } else {

      let obj =
      {
        "NAMC": "",
        "Family": "",
        "Version": "",
        "Katashki/Part": "",
        "Bom Effectivity": "",
        "GRPI": "",
        "Part Source": "",
        "Complete Part Routing": "",
        "Part": "",
        "Part Description": "",
        "Dest": "",
        "VIN/SR No": "",
        "Supplier": "",
        "Supplier Name": "",
        "Buyer": "",
        "Buyer Name": "",
        "Cost Type": "",
        "ACT Cost": "",
        "TRN Cost": "",
        "Currency Code": "",
        "Waterfall Status": "",
        "Suspension Date": "",
        "Error Message": "",
        "Prod Def Id": "",
        "Prod Bom Id": "",
      }
      this.exportData.push(obj);
    }
    this.exportService.exportAsExcelFileForReport(this.exportData, this.fileName);
    this.exportData = []

  }

  getGridData() {
    // if (this.endDate !=="") {
    //   this.endDate = this.pipe.transform(this.endDate, 'yyyy-MM-dd')
    // }
    // if (this.startDate !=="") {
    //   this.startDate = this.pipe.transform(this.startDate, 'yyyy-MM-dd')
    // }
    let indateFormat = ''; let outdateFormat = '';
    if (this.startDate !=="") {
      const d1 = moment(this.startDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.startDate = date
    }
    if (this.endDate !=="") {
      const d1 = moment(this.endDate).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.endDate = date
    }
    let newDate = new Date();
      const date = '_' + newDate.getDate() + (newDate.getMonth() + 1) + newDate.getFullYear() + '_' + newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() + newDate.getMilliseconds();
      const fileName = 'c8-zero_value_detail/c8GrdSrc' + date + '.json';
    let gridData =
    {
      namc: this.namc,
      family: this.family,
      version: this.version,
      katashikiPart: this.katashkiPart,
      bomEffectivityDate: this.bomEffectivityDate,
      grpi: this.grpi,
      partSource: this.partSource,
      completePartRouting: this.completePartRouting,
      part: this.part,
      startDate: indateFormat,
      dest: this.dest,
      vinSrNo: this.vinsrNo,
      supplier: this.supplier,
      supplierName: this.supplierName,
      buyer: this.buyer,
      buyerName: this.buyerName,
      costType: this.costType,
      actCost: this.actCost,
      trnCost: this.trnCost,
      currencyCode: this.currencyCode,
      waterfallStatus: this.waterfallStatus,
      suspensionDate: outdateFormat,
      errorMessage: this.errMsg,
      fileName: fileName
    }
    this.SpinnerService.show();
    this.partsWithoutCostDetailService.getGridData(gridData).subscribe(data => {
      console.info(data)
      this.showdata = true;
      console.info(this.showdata);
      this.recordcount = data.pagination.totalRecords;
      const datarow = data.payload;
      if (data.payload[0].RecordCnt > 1000) {
        setTimeout(() => {
          this.getFileFromS3(fileName);
        }, 2000);
      } else {
        this.rows = data.payload;
        this.SpinnerService.hide();
      }
    }, err => {
      console.info(err);
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      if (err===0) {
        this.errorMessage = "Request timed out. Please try again.";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      } else {
        this.errorMessage = "No Records";
        this.SpinnerService.hide();
        this.globalrole.goToTop();
      }
    });
  }


  clearDate(event, name) {
    event.stopPropagation();
    if (name==='startDate') {
      this.startDate = "";
    } else if (name==='endDate') {
      this.endDate = "";
    }
  }

  ////get from s3 Grid search data
  async getFileFromS3(fileName) {
    this.SpinnerService.show();
    // return new Promise(async resolve => {
    const a = await this.getS3Info();
    if (a) {
      const bucket = new S3(
        {
          accessKeyId: this.accessKey,
          secretAccessKey: this.secretKey,
          sessionToken: this.sessionToken,
          region: 'us-west-2'
        }
      );
      const params = {
        Bucket: 'search-grids',
        Key: fileName,
      };
      console.info(params);
      bucket.getObject(params, (err: any, data: any) => {
        if (err) {
          console.info('download err:::::::::', err)
          this.SpinnerService.hide();
        } else {
          console.info(data)
          this.SpinnerService.hide();
          let result = data.Body.toString();
          let res = JSON.parse(result);
          console.info("res and reult", res, result)
          if (res.length > 0) {
            this.rows = res;
            console.info(this.rows);
          }
        }
      })
    }
    // })
  }

  getS3Info() {
    return new Promise(resolve => {
      this.partsWithoutCostDetailService.fileUpldData().subscribe(data => {
        let result = data.payload[0]
        if (result !== null) {
          const aws = result;
          this.accessKey = aws.AccessKeyId;
          this.secretKey = aws.SecretAccessKey;
          this.sessionToken = aws.SessionToken;
          resolve(true)
        }
        else {
          // this.toastr.error('Data?Storage?Disconnected.');
          resolve(false)
        }
      }, err => {
        // this.toastr.error('Unable?to?get?keys?and?token', 'Failed');
      });
    })
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_7310') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }

}

