import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { timeStamp } from 'console';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { DialogData } from '../dialog-data/dialog-data';
import { MajorPartlstAddDialogComponent } from '../major-partlst-add-dialog/major-partlst-add-dialog.component';
import { MajorPartlstEditDialogComponent } from '../major-partlst-edit-dialog/major-partlst-edit-dialog.component';
import { MajorPartlstUploadDialogComponent } from '../major-partlst-upload-dialog/major-partlst-upload-dialog.component';
import { MajorPartlstViewDialogComponent } from '../major-partlst-view-dialog/major-partlst-view-dialog.component';
import { MajorPartListService } from './major-part-list.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-major-part-list',
  templateUrl: './major-part-list.component.html',
  styleUrls: ['./major-part-list.component.css']
})
export class MajorPartListComponent implements OnInit {
  [x: string]: any;
  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');
  //export to excel
  fileName = 'MajorPartList';
  exportData = [];
  sheetName = ['MAJ_PART_LST'];
  errorSheetName = ['MAJ_PART_LIST_ERROR'];
  errorFileName = 'MajPartListError';
  enablePartnum: boolean = false;
  enableKatashiki: boolean = true;

  //alertMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  spinnerMessage = "Please Wait...";

  //ngModel values for search 
  step = 0;
  majPartType: any = '';
  productType: any = '';
  namc: any = '';
  family: any = '';
  status: string = 'Active';
  comments: any = '';
  katashiki: any = '';
  part_no: any = '';
  //list
  majPartTypeLst = [];
  productTypeLst = [];
  familyLst = [];
  statusLst = ['Active', 'Inactive', 'All',];
  partnumLst: [];
  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('majPartLstTable') table;
  limitSelected: any = 50;

  showdata: boolean = true;
  columns = [
    { name: 'NAMC', prop: 'namc_cd' }, { name: 'family', prop: 'fam_cd' }, { name: 'majorePartType', prop: 'maj_part_typ_nm' },
    { name: 'productType', prop: 'prod_typ_desc' }, { name: 'majorPartTypeQty', prop: 'expct_part_qty' },
    { name: 'status', prop: 'inactv_flg' }, { name: 'partCode', prop: 'maj_part_prfx_cd' },
    { name: 'comments', prop: 'rmrk_txt' }, { name: 'katashiki', prod: 'katashiki_cd' },
    { name: 'PartNo', prod: 'prod_part_no' },
  ];
  ColumnMode = ColumnMode;
  recordcount: any = 0;
  sameLengthRows: boolean = false;
  Roledata: any;
  roleAccess: any = [];
   accessEnable: boolean;
  namcLst: any = []
  katashikiLst: any = []

  constructor(public dialog: MatDialog, private majPartLstService: MajorPartListService,
    private exportService: ExportToExcelService, private dialogData: DialogData, private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService,) { }

  async ngOnInit() {
    this.SpinnerService.show();

    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }
    this.onloadfn();

  }
  onloadfn() {
    //majPartTyp
    this.majprotypfn()

    //majProdTyp
    this.prodtypefn();
    this.SpinnerService.show();
    //to get namc
    this.majPartLstService.getNamc().subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.namcLst = data.payload;
    });

    this.SpinnerService.show();
    //famBynamc
    this.majPartLstService.getfamByNamc(this.namc).subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.familyLst = data.payload;
    });

    //Family
    this.majPartLstService.getFamByMajProdTyp(this.productType, this.majPartType).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
    });

  }

  majprotypfn() {
    this.majPartLstService.getMajPartTyp().subscribe(data => {
      console.info(data);
      this.majPartTypeLst = data.payload;
    });
  }

  prodtypefn() {
    this.majPartLstService.getProdTypByMajPartNm(this.majPartType).subscribe(data => {
      console.info(data);
      this.productTypeLst = data.payload;
    });
  }

  //fambynamc on chnge
  getfamily(): void {
    if (this.namc===undefined) {
      this.namc = ""
    }
    this.SpinnerService.show();
    this.majPartLstService.getfamByNamc(this.namc).subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.familyLst = data.payload;
      this.family = ""
      this.katashikiLst = []
    });
  }

  async onchangecall() {
    this.SpinnerService.show()
    this.katashikiLst = []
    this.partnumLst = []
    let a: any = await this.getKatashiki();
    let b: any = await this.getPartNumber();
    console.info(a, b)
    console.info(a.length, b.length)
    if (b.length !==0 && a.length !==0) {
      function renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
      const arr = b;
      arr.forEach(obj => renameKey(obj, 'part_no', 'katashiki_cd'));
      const updatedJson = arr;
      console.info(updatedJson);
      this.katashikiLst = a.concat(updatedJson);
      console.info(this.katashikiLst)
      this.SpinnerService.hide()
      this.enableKatashiki = true;
      this.enablePartnum = false
    }
    else if (a.length !==0) {
      this.katashikiLst = a
      this.SpinnerService.hide()
      this.enableKatashiki = true;
      this.enablePartnum = false
    }
    else if (b.length !==0) {
      this.partnumLst = b
      this.SpinnerService.hide()
      this.enableKatashiki = false;
      this.enablePartnum = true
    } else {
      this.SpinnerService.hide()
    }
    console.info(this.katashikiLst);
  }


  //katashiki by fam onchng
  getKatashiki() {
    return new Promise(res => {
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.family===undefined) {
        this.family = ""
      }
      this.majPartLstService.getKatashikiByFamCd(this.namc, this.family).subscribe(data => {
        console.info(this.namc, this.family)
        console.info(data);
        //  this.SpinnerService.hide();
        this.katashikiLst = data.payload;
        this.katashiki = ""
        res(this.katashikiLst)
        console.info(this.katashikiLst)
      }, err => {
        this.katashikiLst = []
        res(this.katashikiLst);
        this.SpinnerService.hide()
      });
    });

  }
  //partno
  getPartNumber() {
    return new Promise(res => {
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.family===undefined) {
        this.family = ""
      }
      this.majPartLstService.getpartNoByFam(this.namc, this.family).subscribe(data => {
        console.info(data);
        this.partnumLst = data.payload;
        this.katashiki = "";
        res(this.partnumLst)
      }, err => {
        this.partnumLst = []
        res(this.partnumLst)
        this.SpinnerService.hide()
      });
    })

  }

  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  //ngx-table
  onSelect({ selected }) {
    this.selected = selected
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);

    // this.selected.splice(0, this.selected.length);
    // this.selected.push(...selected);
  }

  onActivate(event) {
    // console.info('Activate Event', event);
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  singleSelectCheck(row: any) {
    return this.selected.indexOf(row) === -1;
  }

  resetAll(data): void {
    this.selected = [];
    this.majPartType = '';
    this.productType = '';
    this.status = 'Active';
    this.comments = '';
    this.family = '';
    this.namc = '';
    this.katashiki = '';
    this.katashikiLst = [];
    this.errorMessage = "";
    this.successMessage = '';
    // this.getGridData(data);
    this.onloadfn();
  }
  openAddDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(MajorPartlstAddDialogComponent, {
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        // this.selected = [];
        if (result==='success') {
          // this.resetAll("add");
          this.getGridData('add')
          this.prodtypefn();
          this.majprotypfn();

          this.successMessage = "Records Added Successfully"
          this.selected = []
        }
        else if (result==='AlreadyExists') {

          this.errorMessage = 'Data Entered already Exists!'
          this.globalrole.goToTop();
        }
        else {
          // this.errorMessage = 'Data not inserted/Valid'
        }
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(MajorPartlstEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');
          // this.selected = [];
          if (result==='success') {
            // this.resetAll("add");
            this.getGridData('add')
            this.successMessage = "Records Updated Successfully"
            // this.getGridData();

            this.selected = []
          }
          else if (result==='AlreadyExists') {

            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
            // this.errorMessage = 'Data not inserted/Valid'
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to edit";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openViewDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Read')) {
      if (this.selected.length===1) {
        this.errorMessage = '';
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(MajorPartlstViewDialogComponent, {
          disableClose: true,
          data: this.dialogData

        });

        dialogRef.afterClosed().subscribe(result => {
          console.info('The dialog was closed');

        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "please select only one row to view"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "please select a row to view"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }
  openUploadDialog(): void {
    this.successMessage = '';
    this.errorMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(MajorPartlstUploadDialogComponent, {
        disableClose: true,
        // width: '50%'

      });

      dialogRef.afterClosed().subscribe(result => {
        console.info('The dialog was closed');
        if (result==='success') {
          // this.resetAll("add");
          this.getGridData('add')
          this.successMessage = 'Upload Process Started, Uploaded Information will be share via Mail'
        }
        else if (result==='cancel') {
          this.successMessage = ''
          this.errorMessage = ''
        }
        else {
          this.errorMessage = result
          this.globalrole.goToTop();
        }
      })
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  exportFile(): void {
    this.successMessage = '';
    this.errorMessage = '';
    this.rows.forEach(row => {
      let obj = {
        "Namc": row.namc_cd,
        "Family": row.fam_cd,
        "Katashiki": row.katashiki_cd,
        "Part Number": row.part_no,
        "Major Part Type": row.maj_part_typ_nm,
        "Product Type": row.prod_typ_desc,
        "Expected Majore Part Type Quantity": row.expct_part_qty,
        "Status": row.inactv_flg,
        "Part Code": row.maj_part_prfx_cd,
        "Comments": row.rmrk_txt
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = [];
  }

  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  sendToDialog(selected): void {
    console.info(selected)
    selected.forEach(row => {
      this.dialogData.majorPartFamily = row.fam_cd;
      this.dialogData.majorPartType = row.maj_part_typ_nm;
      this.dialogData.expectedMajorPartTypeQty = row.expct_part_qty;
      this.dialogData.majorPartProdType = row.prod_typ_desc;
      this.dialogData.majorPartPCode = row.maj_part_prfx_cd;
      this.dialogData.majorPartComments = row.rmrk_txt;
      this.dialogData.majorPartStatus = row.inactv_flg;
      this.dialogData.maj_part_lst_id = row.maj_part_lst_id;
      this.dialogData.majorPartEditNAMC = row.namc_cd;
      this.dialogData.majorPartEditKatashiki = row.katashiki_cd;
      this.dialogData.majorPartpartno = row.part_no;
    });
    console.info(this.dialogData.monthlyRep)
  }
  //onChangeMethod
  getProdByMajPartNM(): void {
    if (this.majPartType===undefined) {
      this.majPartType = "";

    }
    this.majPartLstService.getProdTypByMajPartNm(this.majPartType).subscribe(data => {
      console.info(data);
      this.productTypeLst = data.payload;
      this.productType = ""
      // this.family = "";
    });
  }

  getFamByProdTyp(): void {
    if (this.productType===undefined) {
      this.productType = "";
    }
    this.majPartLstService.getFamByMajProdTyp(this.productType, this.majPartType).subscribe(data => {
      console.info(data);
      this.familyLst = data.payload;
      this.family = ""
    });
  }

  search() {
    this.successMessage = '';
    this.errorMessage = '';
    this.getGridData("initial");
  }

  getGridData(data) {
    this.selected = [];
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      let sts = ""
      let parentpartValue = "";
      let katashikivalue = ""
      if (this.majPartType===undefined) {
        this.majPartType = ""
      }
      if (this.productType===undefined) {
        this.productType = ""
      }
      if (this.family===undefined) {
        this.family = ""
      }
      if (this.status===undefined) {
        this.status = ""
      }
      if (this.namc===undefined) {
        this.namc = ""
      }
      if (this.katashiki===undefined) {
        this.katashiki = ""
      }
      if (this.status==="Active") {
        sts = 'N'
      } else if (this.status==="Inactive") {
        sts = 'Y'
      }
      console.info(this.katashiki.length,"lengtjhggyudfguy")
      if (this.katashiki.length==='10') {
        parentpartValue = this.katashiki
      } else {
        katashikivalue = this.katashiki
      }
      let cmts;
      if (this.comments !==undefined) {
        cmts = this.comments.replaceAll("'", "''");
      } else {
        cmts = this.comments
      }
      let majPartObj
      if (data==='initial') {
        majPartObj = {
          mjPrtTypNm: this.majPartType,
          prodTypCd: this.productType,
          family: this.family,
          status: sts,
          comments: cmts,
          namc: this.namc,
          katashiki: katashikivalue,
          prodPartNo: parentpartValue,
          option: data
        }
      } else {
        majPartObj = {
          mjPrtTypNm: "",
          prodTypCd: "",
          family: "",
          status: 'N',
          comments: "",
          namc: "",
          katashiki: "",
          prodPartNo: "",
          option: data
        }
      }
      this.SpinnerService.show();
      this.majPartLstService.getGridData(majPartObj).subscribe(data => {
        console.info(data);
        this.showdata = true;
        this.recordcount = data.pagination.totalRecords;
        console.info(this.showdata);
        this.SpinnerService.hide();
        const datarow = data.payload;
        this.rows = datarow.map(row => ({
          fam_cd: row['fam_cd'],
          maj_part_typ_nm: row['maj_part_typ_nm'],
          expct_part_qty: (row['expct_part_qty']===null) ? '' : row['expct_part_qty'].split('.')[0],
          prod_typ_desc: row['prod_typ_desc'],
          inactv_flg: row['inactv_flg']==="N" ? "Active" : "Inactive",
          maj_part_prfx_cd: row['maj_part_prfx_cd'],
          rmrk_txt: row['rmrk_txt'],
          maj_part_lst_id: row['maj_part_lst_id'],
          katashiki_cd: row['katashiki_cd'],
          part_no: row['prod_part_no'],
          namc_cd: row['namc_cd']
        }))
      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }


  errorExport(data) {
    console.info(data)
    this.exportData = [];
    data.forEach(row => {
      let obj = {
        "NAMC": row.namc,
        "FAMILY": row.family,
        "KATASHIKI": row.katashiki_cd,
        "PART NUMBER": row.partNum,
        "MAJOR PART TYPE": row.partNo,
        "PRODUCT TYPE": row.prodTyp,
        "EXPECTED MAJOR PART TYPE QUANTITY": row.expMajPartTyQty,
        "STATUS": row.status,
        "PART CODE": row.partCd,
        "COMMENTS": row.comments,
        "ERROR": row.errorMessage
      }
      this.exportData.push(obj);
    });
    console.info(this.exportData)
    this.exportService.exportAsExcelFile(this.exportData, this.errorFileName, this.errorSheetName);
  }


  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_2220') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
          this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
          this.userroleAccessfn();
          this.accessEnable = true;
          resolve(true);
        }else{
        console.info(this.roleAccess)
        this.roleAccess = []
        this.errorMessage = "User does not have permission to perform 'read' operation";
        this.accessEnable = false;
        this.SpinnerService.hide();
      };
      }
      console.info(this.roleAccess);
    })
  }

}
