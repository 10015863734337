import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DialogData } from '../dialog-data/dialog-data';
import { RepVehicleEditDialogService } from './rep-vehicle-edit-dialog.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-rep-vehicle-edit-dialog',
  templateUrl: './rep-vehicle-edit-dialog.component.html',
  styleUrls: ['./rep-vehicle-edit-dialog.component.css']
})
export class RepVehicleEditDialogComponent implements OnInit {

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');


  //errormessage
  errorMessage: string = "";


  //ngModelValues
  eMonthlyRep: any;
  eCompBom: any;
  eModelNumber: any;
  eOption: any;
  eSapMatId: any;
  eNamc: any;
  eFamily: any;
  eKatashiki: any;
  eCustomerId: any;
  eDest: any;
  eHikiate: any;
  eSP: any;
  ePcSpec: any;
  eCompPcSpec: any;
  eNetSpec: any;
  eHundPercentSpec: any;
  eCompHundPercentSpec: any;
  eExtractionTimeIn: any;
  eExtractionTimeOut: any;
  eComments: any;
  eRepProdDefId: any;
  //response from backend after insert/update records
  response: any;

  //to main page
  message = "Record Updated Successfully";

  spinnerMessage = "Please Wait...";


  constructor(public dialogRef: MatDialogRef<RepVehicleEditDialogComponent>, private readonly SpinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private repEditService: RepVehicleEditDialogService) { }

  ngOnInit(): void {
    this.eMonthlyRep = this.data.monthlyRep;
    this.eCompBom = this.data.compBom;
    this.eModelNumber = this.data.modelNumber;
    this.eOption = this.data.option;
    this.eSapMatId = this.data.sapMatId;
    this.eNamc = this.data.namc;
    this.eFamily = this.data.family;
    this.eKatashiki = this.data.katashiki;
    this.eCustomerId = this.data.customerId;
    this.eDest = this.data.dest;
    this.eHikiate = this.data.hikiate;
    this.eSP = this.data.sp;
    this.ePcSpec = this.data.pcSpec;
    this.eCompPcSpec = this.data.compPCSpec;
    this.eNetSpec = this.data.netSpec;
    this.eHundPercentSpec = this.data.hundrentPerSpec;
    this.eCompHundPercentSpec = this.data.compHundPerSpec;
    this.eExtractionTimeIn = this.data.extractionTimeIn;
    this.eExtractionTimeOut = this.data.extractionTimeOut;
    this.eComments = this.data.comments;
    this.eRepProdDefId = this.data.repProdDefId;
  }


  updateRepVehicle() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId');
    if (this.eModelNumber !=="" && this.eModelNumber !==null) {
      this.eModelNumber = this.eModelNumber.replace(/[^a-zA-Z0-9]/g, '')
    }
    let indateFormat = ''; let outdateFormat = '';
    if (this.eExtractionTimeIn !=="") {
      const d1 = moment(this.eExtractionTimeIn).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      indateFormat = moment(date).format('YYYY-MM-DD')
      this.eExtractionTimeIn = date
      // const date = this.pipe.transform(this.effDateIn, 'yyyy-MM-dd')
      // this.effDateIn = date
      // console.info("this.effDateIn",date,this.effDateIn);

    }
    if (this.eExtractionTimeOut !=="") {
      const d1 = moment(this.eExtractionTimeOut).format('YYYY-MM-DD')
      const b = d1.split('T')[0]
      let parts = []
      parts = b.split('-');
      const date = new Date(parts[0], parts[1] - 1, parts[2]);
      outdateFormat = moment(date).format('YYYY-MM-DD')
      this.eExtractionTimeOut = date
      // this.effDateOut = this.pipe.transform(this.effDateOut, 'yyyy-MM-dd')
    }
    let cmts;

    if (this.eComments !==undefined) {

      cmts = this.eComments.replaceAll("'", "''");

    } else {

      cmts = this.eComments

    }
    const body = {
      "monthlyRep": this.eMonthlyRep,
      "compBom": this.eCompBom,
      "mdlNo": this.eModelNumber,
      "optnCd": this.eOption,
      "sapMatId": this.eSapMatId,
      "effDate": indateFormat,
      "exprDate": outdateFormat,
      "comments":cmts ,
      "screenNo":"NBPC_9350",
      "fileName" :fileName
      
    }
    this.repEditService.updateRepVehicle(body).subscribe(data => {
      console.info(data);
    });
  }

  //save
  updateRepVeh(): void {
    const fileName= localStorage.getItem('Filename')
    console.info(this.eModelNumber);
    const user = localStorage.getItem('workDayId');
    console.info(this.eExtractionTimeIn);

    if (!this.eExtractionTimeIn) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eExtractionTimeIn && this.eExtractionTimeOut &&
      this.pipe.transform(this.eExtractionTimeIn, 'yyyy-MM-dd') > this.pipe.transform(this.eExtractionTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      this.errorMessage = "";
      if (this.eExtractionTimeOut==="") {
        this.eExtractionTimeOut = "9999-12-31"
      }
      if (this.eMonthlyRep===true) {
        this.eMonthlyRep = 'Y';
      } else if (this.eMonthlyRep===false) {
        this.eMonthlyRep = 'N';
      }
      if (this.eCompBom===true) {
        this.eCompBom = 'Y';
      } else if (this.eCompBom===false) {
        this.eCompBom = 'N';
      }
      if (this.eModelNumber !=="" && this.eModelNumber !==null) {
        this.eModelNumber = this.eModelNumber.replace(/[^a-zA-Z0-9]/g, '')
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.eExtractionTimeIn !=="") {
        const d1 = moment(this.eExtractionTimeIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eExtractionTimeIn = date
      }
      if (this.eExtractionTimeOut !=="") {
        const d1 = moment(this.eExtractionTimeOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eExtractionTimeOut = date
      }
      let cmts;

    if (this.eComments !==undefined) {

      cmts = this.eComments.replaceAll("'", "''");

    } else {

      cmts = this.eComments

    }
      const body =
      {
        'monRepFlg': this.eMonthlyRep,
        'compBomFlg': this.eCompBom,
        'mdlNo': this.eModelNumber,
        'optCd': this.eOption,
        'sapMtrlId': this.eSapMatId,
        'extTimeIn': indateFormat,
        'extTimeOut': outdateFormat,
        'comment': cmts,
        'repProdDefid': this.eRepProdDefId,
        "screenNo":"NBPC_9350",
        "fileName" :fileName
        
      }
      this.SpinnerService.show();
      this.repEditService.updateRepVehicle(body).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Data Entered already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }

  }

  cancel(): void {
    this.message = "";
    this.dialogRef.close({ event: 'Cancel', data: this.message });
  }
  closeAlert(): void {
    this.errorMessage = '';
  }
  clearDate(event, name) {
    event.stopPropagation();
    if (name==='eExtractionTimeIn') {
      this.eExtractionTimeIn = "";
    } else if (name==='eExtractionTimeOut') {
      this.eExtractionTimeOut = "";
    }
  }

}
