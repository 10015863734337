import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogData } from '../dialog-data/dialog-data';
import { SapDataEditDialogService } from './sap-data-edit-dialog.service';

@Component({
  selector: 'app-sap-data-edit-dialog',
  templateUrl: './sap-data-edit-dialog.component.html',
  styleUrls: ['./sap-data-edit-dialog.component.css']
})
export class SapDataEditDialogComponent implements OnInit {
  [x: string]: any;


  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //to main page
  message = "records updated successfully";

  errorMessage = "";

  //hikiate
  hikiateLstFromDB: any = [];
  selectedHikiateLst: any = [];
  dummyHikiateLst: any;
  dummyHikiateLstToDB: any = [];
  familyLst: any = []
  namcLst: any = [];
  customerLst: any = [];
  //ngModelValues
  eMonthlyRep: any = "";
  eCompBom: any = "";
  eNamc: any = "";
  eModelNumber: any = "";
  eOption: any = "";
  eSapMaterialId: any = "";
  eFamily: any = "";
  epartNumber: any = "";
  eproductType: any = "";

  eCustomerId: any = "";
  eDest: any = "";
  eSP: any = "";
  eHikiateStatus: any = "";
  ePcSpec: any = "";
  eCompPcSpec: any = "";
  eNetSpec: any = "";
  eHundPercentSpec: any = "";
  eCompHundPercentSpec: any = "";
  eExtractionTimeIn: any = "";
  eExtractionTimeOut: any = "";
  eComments: any = "";


  //respone after inserting a record
  response: any;

  constructor(private SapDataEditDialogService: SapDataEditDialogService, public dialogRef: MatDialogRef<SapDataEditDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public SpinnerService: NgxSpinnerService) { }

  index: any;
  namc_id: any
  async ngOnInit() {
    this.SpinnerService.show();
    const a = await this.getNamc();
    const b = await this.getCustData();
    if (a && b) {
      console.info(this.data);
      let namc_id; let cust_id
      for (let i = 0; i < this.namcLst.length; i++) {
        if (this.data.namc===this.namcLst[i].namc_cd) {
          namc_id = this.namcLst[i].namc_lgl_entity_id
        }
      }

      for (let i = 0; i < this.customerLst.length; i++) {
        if (this.data.customerId===this.customerLst[i].buyoff_cust_nm) {
          cust_id = this.customerLst[i].buyoff_cust_id
        }
      }
      console.info("data", this.data);

      this.eMonthlyRep = this.data.monthlyRep;
      this.eCompBom = this.data.compBom;
      this.eModelNumber = this.data.modelNumber;
      this.eOption = this.data.option;
      this.eSapMaterialId = this.data.sapMaterialId;
      this.ePartNumber = this.data.partNumber;
      this.ePartDesc = this.partDescription;
      this.eProdTyp = this.productType;
      this.eNamc = this.data.namc;
      this.namcId = namc_id;
      this.eFamily = this.data.family;
      this.eKatashiki = this.data.katashiki;
      this.eCustomer = this.data.customer;
      this.ecustId = cust_id;
      this.eDest = this.data.dest;
      if (this.data.hikiate !=='') {
        this.dummyHikiateLstToDB = this.data.hikiate;
        this.selectedHikiateLst = this.data.hikiate;
      }
      this.eSp = this.data.sp;
      this.ePcSpec = this.data.pcSpec;
      this.eCompPcSpec = this.data.compPCSpec;
      this.eNetSpec = this.data.netSpec;
      this.eHundPercentSpec = this.data.hundrentPerSpec;
      this.eCompHundPercentSpec = this.data.compHundPerSpec;
      this.eExtractionTimeIn = this.data.extractionTimeIn;
      this.eExtractionTimeOut = this.data.extractionTimeOut;
      this.eComments = this.data.comments;
      this.eErrorMessage = this.data.errorMessage;
      this.eSapStatus = this.data.sapStatus;
      this.erepproddefid = this.data.repProdDefId;
      this.getFamCdByNamc()
      this.getHikiateByDest();
    }
    let d = await this.getHikiateByDest();
    if (d) {
      const hikiatelst = this.hikiateLstFromDB
      this.dummyHikiateLstToDB.forEach(hiki => {
        for (let i = 0; i < hikiatelst.length; i++) {
          if (hiki===hikiatelst[i].hikiate) {
            hikiatelst.splice(i, 1);
          }
        }
        this.hikiateLstFromDB = hikiatelst;
        this.dummyHikiateLstToDB = []
      });
      console.info(this.hikiateLstFromDB)
    }
    this.SpinnerService.hide();

  }

  getNamc() {
    // to get namc
    return new Promise(res => {
      this.SapDataEditDialogService.getNamcSap().subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.namcLst = data.payload;
        res(true);
      });
    })
  }

  //onChangeMethod
  getFamCdByNamc(): void {
    if (this.eNamc===undefined) {
      this.eNamc = ""
    }
    this.SpinnerService.show();
    this.SapDataEditDialogService.getFamilySap(this.namcId).subscribe(data => {
      console.info(data);
      this.SpinnerService.hide();
      this.familyLst = data.payload;
      console.info(this.familyLst)
      // this.family = ""
    });
  }
  getCustData() {
    return new Promise(res => {
      if (this.eKatashiki===undefined) {
        this.eKatashiki = ""
      }
      if (this.eFamily===undefined) {
        this.eFamily = ""
      }
      if (this.namcId===undefined) {
        this.namcId = ""
      }
      this.SpinnerService.show();
      this.SapDataEditDialogService.getCustByKatashikiSap(this.namcId, this.eFamily, this.eKatashiki).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.customerLst = data.payload;
        res(true)
      });
    })
  }
  getHikiateByDest() {
    if (this.eFamily===undefined) {
      this.eFamily = ""
    }
    if (this.eNamc===undefined) {
      this.eNamc = ""
    }
    this.SpinnerService.show();
    return new Promise(res => {
      this.SapDataEditDialogService.getHikiate(this.eFamily, this.namcId).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload;
        this.eHikiateStatus = ""
        res(true)
      })
    });
  }
  getHikiatechange() {
    if (this.eFamily===undefined) {
      this.eFamily = ""
    }
    if (this.eNamc===undefined) {
      this.eNamc = ""
    }
    this.SpinnerService.show();
    return new Promise(res => {
      this.SapDataEditDialogService.getHikiate(this.eFamily, this.namcId).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.hikiateLstFromDB = data.payload;
        this.eHikiateStatus = ""
        this.selectedHikiateLst = []
        res(true)
      })
    });
  }

  //hikiate
  moveHikiForward(): void {
    const hikiatelst = this.hikiateLstFromDB
    console.info(this.dummyHikiateLst);
    this.dummyHikiateLst.forEach(hiki => {
      this.selectedHikiateLst.push(hiki);
      for (let i = 0; i < hikiatelst.length; i++) {
        console.info(i);
        console.info(hiki, hikiatelst[i].hikiate);
        console.info(hiki === hikiatelst[i].hikiate);
        console.info(i);
        if (hiki===hikiatelst[i].hikiate) {
          hikiatelst.splice(i, 1);
        }
      }
    });
    this.hikiateLstFromDB = hikiatelst;
    console.info(this.hikiateLstFromDB);
    console.info(this.selectedHikiateLst);
    this.dummyHikiateLst = ""
  }
  moveHikiBackward(): void {
    this.dummyHikiateLstToDB.forEach(hiki => {
      const body = {
        "hikiate": hiki
      }
      this.hikiateLstFromDB.push(body);
      this.index = this.selectedHikiateLst.indexOf(hiki);
      this.selectedHikiateLst.splice(this.index, 1);
    });
    console.info(this.dummyHikiateLstToDB.length, this.dummyHikiateLstToDB)
    if (this.dummyHikiateLstToDB.length >= 1) {
      console.info(this.hikiateLstFromDB)
      console.info(this.selectedHikiateLst);
      this.hikiateLstFromDB = this.hikiateLstFromDB.sort((a, b) => a.hikiate > b.hikiate ? 1 : -1);
      console.info(this.hikiateLstFromDB)
      this.dummyHikiateLstToDB = [];
    }
  }

  //closealert
  closeAlert() {
    this.errorMessage = "";
  }
  updateSapDataLoad() {
    const fileName= localStorage.getItem('Filename')
    const user = localStorage.getItem('workDayId');
    console.info(this.eExtractionTimeIn);
    if (!this.eExtractionTimeIn) {
      this.errorMessage = "Please enter all mandatory field";
    }
    else if (this.eExtractionTimeIn && this.eExtractionTimeOut &&
      this.pipe.transform(this.eExtractionTimeIn, 'yyyy-MM-dd') >= this.pipe.transform(this.eExtractionTimeOut, 'yyyy-MM-dd')) {
      this.errorMessage = "Extraction Time Out should be greater than or equal to Extraction Time In";
    }
    else {
      let hikiatelstArray: any;
      hikiatelstArray = this.selectedHikiateLst;
      console.info(hikiatelstArray.length, hikiatelstArray);
      let str = '';
      for (let i = 0; i < hikiatelstArray.length; i++) {
        if (str==='') {
          str = hikiatelstArray[i]
        } else {
          str += ',' + hikiatelstArray[i]
        }
        this.hikiatevalue = str;
      }
      this.errorMessage = "";
      if (this.eExtractionTimeOut==="") {
        this.eExtractionTimeOut = "9999-12-31"
      }
      let monthlyrep = ""
      if (this.eMonthlyRep===true) {
        monthlyrep = "Y"
      } else if (this.eMonthlyRep===false) {
        monthlyrep = "N"
      }
      let indateFormat = ''; let outdateFormat = '';
      if (this.eExtractionTimeIn !=="") {
        const d1 = moment(this.eExtractionTimeIn).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        indateFormat = moment(date).format('YYYY-MM-DD')
        this.eExtractionTimeIn = date
      }
      if (this.eExtractionTimeOut !=="") {
        const d1 = moment(this.eExtractionTimeOut).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        outdateFormat = moment(date).format('YYYY-MM-DD')
        this.eExtractionTimeOut = date
      }
      let cmts;
      if (this.eComments !==undefined) {
        cmts = this.eComments.replaceAll("'", "''");
      } else {
        cmts = this.eComments
      }
      const body = {
        'famCd': this.eFamily,
        'hikiateStatCombCd': this.hikiatevalue,
        'effDt': indateFormat,
        'exprDt': outdateFormat,
        'rmrkTxt': cmts,
        'repProdDefSapId': this.erepproddefid,
        'monthlyRepFlg': monthlyrep,
        "screenNo":"NBPC_9998",
        "fileName" :fileName

        
      }
      this.SpinnerService.show();
      this.SapDataEditDialogService.editSap(body).subscribe(data => {
        console.info(data);
        this.SpinnerService.hide();
        this.response = data.payload;
        if (this.response[0].result==='success') {
          this.dialogRef.close(this.response[0].result);
        } else if (this.response[0].result==='AlreadyExists') {
          this.errorMessage = 'Record already Exists!';
        }
      }, err => {
        this.SpinnerService.hide();
      });

    }

  }
  cancel() {

    this.message = "";

    this.dialogRef.close({ event: 'Cancel', data: this.message });

  }
  clearDate(event, name) {
    event.stopPropagation();
    if (name==='eExtractionTimeIn') {
      this.eExtractionTimeIn = "";
    } else if (name==='eExtractionTimeOut') {
      this.eExtractionTimeOut = "";
    }
  }
}
