import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import 'rxjs/Rx';
import {environment} from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
const cntType = 'application/json';
const exp = 'Sat, 01 Dec 2001 00:00:00 GMT';

var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
const fileName= localStorage.getItem('Filename')
var httpOptions;
if (isIEOrEdge) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': exp,
    })
  };
} else {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': cntType,
      'Authorization': localStorage.getItem('Ebom_accessToken')
    })
  };
}
@Injectable({
  providedIn: 'root'
})
export class VersionsService {
  searchdropdown1UrlApi = environment.Mastersearchdropdown1apiUrl;
  Bussinessgridapi = environment.BussinessgridapiUrl;
  BussinessDropdownApi = environment.BusinesssearchdropdownapiUrl;
  version = 'getVersionType';
  versionQual = '/getVersionQualifierByType';
  gridData = '/getVersionsGrid';
  Approvalsts = 'getApprovalStatus';
  versionRevision = '/getVersionRevision';
  priority = 'getPriority'
  VolumeCountStatus = 'getVolumeCountStatus';
  closeVer = '/closeVersions';
  


  constructor(private http: HttpClient) { }

  //grid data
  getGridData(gridData): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.Bussinessgridapi + this.gridData + '?approvalSts=' + gridData.approvalStatus
      + '&verTyp=' + gridData.versionType + '&verQual=' + gridData.versionQual + '&verRevsn=' + gridData.versionRev
      + '&priority=' + gridData.priority + '&volCntSts=' + gridData.volCntStatus + '&bomEffDt=' + gridData.bomEffDate
      + '&cstEffDt=' + gridData.costEffDate + '&comments=' + gridData.comments + '&option=' + gridData.option+'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //ApprovalSts
 

  getapprovalsts(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.BussinessDropdownApi + '/' + this.Approvalsts + '?repBomVerId=' + ''+'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Version Revision
  getversionRevision(bomVerQualId): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }

    const API_URL = this.BussinessDropdownApi + this.versionRevision + '?bomVerQualId=' + bomVerQualId
    + '&option=' + 'Search'+'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL, httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //Priority
  getpriority(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.BussinessDropdownApi}/${this.priority}`+'?screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //VolumeCountStatus
  getVolumeCountStatus(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.BussinessDropdownApi}/${this.VolumeCountStatus}`+'?screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  //versionType
  getVersionType(): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = `${this.searchdropdown1UrlApi}/${this.version}`+'?screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }

  //versionQual
  getQualifier(verTypCd): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    const API_URL = this.searchdropdown1UrlApi + this.versionQual + '?bomVerTypeId=' + verTypCd+'&screenNo=NBPC_10105'+'&fileName=' +fileName;
    return this.http.get(API_URL,httpOptions)
      .pipe(
        catchError(this.error)
      )
  }
  closeVersion(body): Observable<any> {
    var isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const fileName= localStorage.getItem('Filename')
    var httpOptions;
    if (isIEOrEdge) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': exp,
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': cntType,
          'Authorization': localStorage.getItem('Ebom_accessToken'),
          'x-api-key':localStorage.getItem('Filename')
        })
      };
    }
    let url = environment.BusinessActionRestapiUrl + this.closeVer
    return this.http.post(url, body, httpOptions)
      .map((res: any) => res).catch(this.error)
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage:any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = error.status;
    }
    console.info(errorMessage);
    return throwError(errorMessage);
  }
  // ===================================== Exception Handler ===========================================

}
