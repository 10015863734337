import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ExportToExcelService } from '../common/export-to-excel.service';
import { VersionDialogData } from '../dialog-data/version-dialog-data';
import { VersionAddDialogComponent } from '../version-add-dialog/version-add-dialog.component';
import { VersionDeleteDialogComponent } from '../version-delete-dialog/version-delete-dialog.component';
import { VersionEditDialogComponent } from '../version-edit-dialog/version-edit-dialog.component';
import { VersionsService } from './versions.service';
import { NgxSpinnerService } from "ngx-spinner";

import { GlobalService } from '../global.service';

@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.css']
})
export class VersionsComponent implements OnInit {
  [x: string]: any;

  //Date transform
  pipe = new DatePipe('en-US');
  currentDate = moment().format('DD/MM/YYYY');

  //export to excel
  fileName = 'Versions';
  exportData = [];
  sheetName = [""];

  //alertMessage
  errorMessage: string = '';

  //messagefromDialog
  successMessage: string = '';

  spinnerMessage = "Please Wait...";

  //list
  approvalStatusLst = [];
  versionTypeLst = [];
  versionQualLst = [];
  versionRevLst = [];
  priorityLst = [];
  volCntStatusLst = [];

  //ngModel values for search
  step = 0;
  approvalStatus: any = "";
  versionType: any = "";
  versionQual: any = "";
  versionRev: any = "";
  priority: any = "";
  volCntStatus: any = "";
  bomEffDate: any = "";
  costEffDate: any = "";
  comments: any = "";
  effInDate: any = "";
  effOutDate: any = "";
  //ngx-datatable
  rows = [];
  SelectionType = SelectionType;
  selected = [];
  isLoading: boolean = false;//loadingIndicator = false;
  reorderable = true;
  @ViewChild('myTable') table;
  limitSelected: any = 50;
  sameLengthRows: boolean = false;
  showdata: boolean = true;
  columns = [
    { name: 'approvalStatus', prop: 'bom_ver_apprvl_stat_typ' }, { name: 'versionType', prop: 'bom_ver_typ_cd' },
    { name: 'versionQualifier', prop: 'bom_ver_qual_cd' }, { name: 'versionRevision', prop: 'version_revision' },
    { name: 'priority', prop: 'ver_priority_typ_cd' }, { name: 'bomEffDate', prop: 'bom_eff_dt' },
    { name: 'costEffDate', prop: 'cost_eff_dt' }, { name: 'effInDate', prop: 'eff_dt' },
    { name: 'effOutDate', prop: 'expr_dt' }, { name: 'volCntStatus', prop: 'vol_cnt_calc_stat_typ_nm' },
    { name: 'volLastProcDate', prop: 'vol_cnt_last_update_ts' }, { name: 'comments', prop: 'rmrk_txt' },
    { name: 'volProcToReports', prop: 'dm_load_ts' }
  ];
  ColumnMode = ColumnMode;
  roleAccess: any = [];
  Roledata: any;
  accessEnable: boolean;

  constructor(public dialog: MatDialog, private exportService: ExportToExcelService,
    private dialogData: VersionDialogData, private versionService: VersionsService,
    private readonly SpinnerService: NgxSpinnerService, public globalrole: GlobalService, ) { }

  async ngOnInit(): Promise<void> {
    this.SpinnerService.show();
    const a = await this.userroleAccessfn();
    if (a) {
      console.info('a', a)
      //grid
      this.getGridData("initial");
    }

   this.onloadfn();

  }

onloadfn(){
   //approval
   this.versionService.getapprovalsts().subscribe(data => {
    console.info(data);
    this.approvalStatusLst = data.payload;
  });


    //Priority
    this.versionService.getpriority().subscribe(data => {
      console.info(data);
      this.priorityLst = data.payload;
    });

    //VolumeCountStatus
    this.versionService.getVolumeCountStatus().subscribe(data => {
      console.info(data);
      this.volCntStatusLst = data.payload;
    });

    //vertype
    this.versionService.getVersionType().subscribe(data => {
      console.info(data);
      this.versionTypeLst = data.payload;
    });

    //verQual
    this.versionService.getQualifier(this.versionType).subscribe(data => {
      console.info(data);
      this.versionQualLst = data.payload;
    });

  }



  //onChangeMethod

  getVerQualByType(): void {
    if (this.versionType===undefined) {
      this.versionType = ""
    }
    this.versionService.getQualifier(this.versionType).subscribe(data => {
      console.info(data);
      this.versionQualLst = data.payload;
      this.versionQual = ""
    });

  }

  getversionRevision() {
    if (this.versionQual===undefined) {
      this.versionQual = ""
    }
    if (this.versionQual !=="") {
      this.versionService.getversionRevision(this.versionQual).subscribe(data => {
        console.info(data);
        this.versionRevLst = data.payload;
        this.versionRev = ""
      });
    } else {
      this.versionRevLst = [];
      this.versionRev = ""
    }
  }


  //step for expansion panel
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  //ngx-table
  onSelect({ selected }) {
    this.selected = selected;
    if (this.selected.length===selected.length) {
      this.sameLengthRows = true
    }
    if (this.selected.length===0) {
      this.sameLengthRows = false
    }
    console.info('Select Event', selected, this.selected);
  }

  onActivate(event) {
  }
  displayCheck(row) {
    return row.name !== 'Ethel Price';
  }
  openAddDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Create')) {
      const dialogRef = this.dialog.open(VersionAddDialogComponent, {
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result.event==='Cancel'){
          this.globalrole.goToTop();
          this.errorMessage = ''
        }
        else{
          if (result==='success') {
            this.getGridData("add");
            this.getversionRevision()
            this.successMessage = "Record Added Successfully"
            this.selected = []
          }
          else if (result==='AlreadyExists') {
            this.errorMessage = 'Data Entered already Exists!'
            this.globalrole.goToTop();
          }
          else {
           this.errorMessage = result;
           this.globalrole.goToTop();
          }
        }
        
      });
    } else {
      this.errorMessage = "User does not have permission to perform 'create' operation";
      this.globalrole.goToTop();
    }
  }
  openEditDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(VersionEditDialogComponent, {
          disableClose: true,
          data: this.dialogData
        });
        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          if (result==='success') {
            this.getGridData("add");
            this.successMessage = "Record Updated Successfully"
            this.selected = []
          }
          else {
            console.info("Data nt updated");
          }

        });
      }
      else {
        if (this.sameLengthRows===true) {
          this.errorMessage = "please select only one row to edit"
          this.globalrole.goToTop();
        }
        if (this.sameLengthRows===false) {
          this.errorMessage = "please select a row to edit"
          this.globalrole.goToTop();
        }
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }
  }
  openDeleteDialog(): void {
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Delete')) {
      if (this.selected.length===1) {
        this.sendToDialog(this.selected)
        const dialogRef = this.dialog.open(VersionDeleteDialogComponent, {
          disableClose: true,
          data: this.dialogData,
          width: '50%',
        });

        dialogRef.afterClosed().subscribe(result => {
          this.selected = [];
          if (result==='success') {
            this.getGridData("add");
            this.successMessage = " Representative Product Version deleted successfully"
            this.selected = []
          }
          else if (result==="cancel") {
            this.errorMessage = ""
            this.selected = []
          }
          else {
            this.errorMessage = "Representative product version cannot be deleted"
            this.selected = []
          }
        });
      }
      else {
        this.errorMessage = "Please select a row to delete";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'delete' operation";
      this.globalrole.goToTop();
    }
  }
  close() {
    console.info("selected", this.selected);
    const user = localStorage.getItem('workDayId')
    const fileName= localStorage.getItem('Filename')
    this.errorMessage = '';
    this.successMessage = '';
    if (this.roleAccess.includes('Update')) {
      if (this.selected.length===1) {
        if (this.selected[0].bom_ver_apprvl_stat_typ !=="Closed") {
          let text = "Are you sure to Close ?";
          if (confirm(text)===true) {
            let data = {
              "rep_bom_ver_id": this.selected[0].rep_bom_ver_id,
              "bom_ver_typ_id": this.selected[0].bom_ver_typ_id,
              "scenarioId": this.selected[0].adhoc_proc_scenario_id,
              "screenNo":"NBPC_10105",
              "fileName": fileName
            }
            this.SpinnerService.show()
            this.versionService.closeVersion(data).subscribe(res => {
              console.info("res from close ver", res);
              let response = res.payload
              if (response[0].result==='success') {
                this.getGridData("add");
                this.successMessage = "Version closed Successfully";
                this.selected = [];
              }
              else {
                this.errorMessage = "Version cannot be closed. All products must be Approved or removed."
                this.SpinnerService.hide()
                this.globalrole.goToTop();
              }
            }, err => {
              this.errorMessage = "Version cannot be closed. All products must be Approved or removed."
              this.SpinnerService.hide()
              this.globalrole.goToTop();
            })
          } else {
            console.info('user Cancel')
          }
        } else {
          this.errorMessage = "Version is already Closed";
          this.globalrole.goToTop();
        }
      } else {
        this.errorMessage = "Please select a row to Close";
        this.globalrole.goToTop();
      }
    } else {
      this.errorMessage = "User does not have permission to perform 'update' operation";
      this.globalrole.goToTop();
    }

  }
  startVolumeCnt(): void {

  }
  exportFile(): void {

    this.rows.forEach(row => {
      let obj = {
        "Approval Status": row.bom_ver_apprvl_stat_typ,
        "Version Type": row.bom_ver_typ_cd,
        "Version Qualifier": row.bom_ver_qual_cd,
        "Version Revision": row.version_revision,
        "Priority": row.ver_priority_typ_cd,
        "BoM Effectivity Date": row.bom_eff_dt,
        "Cost Effectivity Date": row.cost_eff_dt,
        "Effective In Date": row.eff_dt,
        "Effective Out Date": row.expr_dt,
        "Volume Count Status": row.vol_cnt_calc_stat_typ_nm,
        "Volume Last Processed Date": row.vol_cnt_last_update_ts,
        "Comments": row.rmrk_txt,
        "Volume Processed to Reports": row.dm_load_ts
      }
      this.exportData.push(obj);
    });

    this.exportService.exportAsExcelFile(this.exportData, this.fileName, this.sheetName);
    this.exportData = []
  }
  closeAlert(): void {
    this.errorMessage = '';
    this.successMessage = '';
  }

  sendToDialog(selected): void {
    selected.forEach(row => {
      this.dialogData.approvalStat = row.bom_ver_apprvl_stat_typ;
      this.dialogData.versionType = row.bom_ver_typ_cd;
      this.dialogData.repBomVerId = row.rep_bom_ver_id;
      this.dialogData.versionQual = row.bom_ver_qual_cd;
      this.dialogData.versionRev = row.version_revision;
      this.dialogData.priorityId = row.ver_priority_typ_id;
      this.dialogData.priority = row.ver_priority_typ_cd;
      this.dialogData.volCntStatus = row.vol_cnt_calc_stat_typ_nm;
      this.dialogData.bomEffDate = this.pipe.transform(row.bom_eff_dt, 'yyyy-MM-dd');
      this.dialogData.costEffDate = this.pipe.transform(row.cost_eff_dt, 'yyyy-MM-dd');
      this.dialogData.effInDate = this.pipe.transform(row.eff_dt, 'yyyy-MM-dd');
      this.dialogData.effOutDate = this.pipe.transform(row.expr_dt, 'yyyy-MM-dd');
      this.dialogData.comments = row.rmrk_txt;

    });
  }

  resetAll(data): void {
    this.selected=[]
    this.approvalStatus = '';
    this.versionType = '';
    this.versionQual = '';
    this.versionRev = '';
    this.priority = '';
    this.volCntStatus = '';
    this.bomEffDate = '';
    this.costEffDate = '';
    this.comments = '';
    this.versionRevLst = [];
    this.onloadfn();
  }

  search() {
    this.errorMessage = ""
    this.successMessage = "";
    this.getGridData('initial');
  }

  getGridData(data) {
    this.selected = []
    console.info(this.roleAccess.includes('Read'));
    if (this.roleAccess.includes('Read')) {
      if (this.approvalStatus===undefined) {
        this.approvalStatus = ""
      }
      if (this.versionType===undefined) {
        this.versionType = ""
      }
      if (this.versionQual===undefined) {
        this.versionQual = ""
      }
      if (this.versionRev===undefined) {
        this.versionRev = ""
      }
      if (this.priority===undefined) {
        this.priority = ""
      }
      if (this.volCntStatus===undefined) {
        this.volCntStatus = ""
      }
      let costEffFormat = ''; let bomEffFormat = '';
      if (this.costEffDate !=="") {
        const d1 = moment(this.costEffDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        costEffFormat = moment(date).format('YYYY-MM-DD')
        this.costEffDate = date
      }
      if (this.bomEffDate !=="") {
        const d1 = moment(this.bomEffDate).format('YYYY-MM-DD')
        const b = d1.split('T')[0]
        let parts = []
        parts = b.split('-');
        const date = new Date(parts[0], parts[1] - 1, parts[2]);
        bomEffFormat = moment(date).format('YYYY-MM-DD')
        this.bomEffDate = date
      }
      if (this.comments===undefined) {
        this.comments = ""
      }
      let cmts;
      if (this.comments!==undefined) {
        cmts = this.comments.replaceAll("'", "''");
      } else {
        cmts = this.comments
      }
      let gridData
      if (data==='initial') {
        gridData =
        {
          approvalStatus: this.approvalStatus,
          versionType: this.versionType,
          versionQual: this.versionQual,
          versionRev: this.versionRev,
          priority: this.priority,
          volCntStatus: this.volCntStatus,
          costEffDate: costEffFormat,
          bomEffDate: bomEffFormat,
          comments: cmts,
          option: data
        }
      } else {
        gridData =
        {
          approvalStatus: "",
          versionType: "",
          versionQual: "",
          versionRev: "",
          priority: "",
          volCntStatus: "",
          costEffDate: "",
          bomEffDate: "",
          comments: "",
          option: data
        }
      }
      console.info(gridData);
      this.SpinnerService.show();
      this.versionService.getGridData(gridData).subscribe(data => {
        console.info(data)
        this.SpinnerService.hide();
        this.showdata = true;
        const datarow = data.payload;

        this.rows = datarow.map(row => ({
          bom_ver_apprvl_stat_typ: row['bom_ver_apprvl_stat_typ'],
          bom_ver_typ_cd: row['bom_ver_typ_cd'],
          bom_ver_typ_id: row['bom_ver_typ_id'],
          rep_bom_ver_id: row['rep_bom_ver_id'],
          bom_ver_qual_cd: row['bom_ver_qual_cd'],
          bom_ver_qual_id: row['bom_ver_qual_id'],
          version_revision: row['version_revision'],
          ver_priority_typ_cd: row['ver_priority_typ_cd'],
          ver_priority_typ_id: row['ver_priority_typ_id'],
          bom_eff_dt: (row['bom_eff_dt']===null) ? '' : row['bom_eff_dt'].split('T')[0],
          cost_eff_dt:  (row['cost_eff_dt']===null) ? '' : row['cost_eff_dt'].split('T')[0],
          eff_dt: (row['eff_dt']===null) ? '' : row['eff_dt'].split('T')[0],
          expr_dt: (row['expr_dt']===null) ? '' : row['expr_dt'].split('T')[0],
          vol_cnt_calc_stat_typ_nm: row['vol_cnt_calc_stat_typ_nm'],
          vol_cnt_calc_stat_typ_id: row['vol_cnt_calc_stat_typ_id'],
          vol_cnt_last_update_ts: (row['vol_cnt_last_update_ts']===null) ? '' : row['vol_cnt_last_update_ts'].replace('Z', '').replace('T', ' '),
          rmrk_txt: row['rmrk_txt'],
          dm_load_ts: (row['dm_load_ts']===null) ? '' : row['dm_load_ts'].replace('Z', '').replace('T', ' '),
          adhoc_proc_scenario_id:row['adhoc_proc_scenario_id']
        }));
        console.info(this.rows);

      }, err => {
        console.info(err);
        this.showdata = false;
        this.successMessage = "";
        console.info(this.showdata);
        this.rows = [];
        if (err===0) {
          this.errorMessage = "Request timed out. Please try again.";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        } else {
          this.errorMessage = "No Records";
          this.SpinnerService.hide();
          this.globalrole.goToTop();
        }
      });
    } else {
      this.SpinnerService.hide();
      this.showdata = false;
      console.info(this.showdata);
      this.rows = [];
      this.errorMessage = "User does not have permission to perform 'read' operation";
      this.globalrole.goToTop();
    }
  }

  clearDate(event, name) {
    event.stopPropagation();
    if (name==='bomEffDate') {
      this.bomEffDate = "";
    } else if (name==='costEffDate') {
      this.costEffDate = "";
    }
  }

  userroleAccessfn() {
    return new Promise(resolve => {
      this.globalrole.Roledetails.subscribe(res => {
        console.info(res)
        this.Roledata = res;
      })
      console.info(this.Roledata);
      if (this.Roledata.length !==0) {
        this.Roledata.filter((res) => {
          if (res.function_nm==='NBPC_10105') {
            this.roleAccess.push(res.access_desc);
          }
        })
        this.accessEnable = true;
        resolve(true);
      } else if (this.Roledata.length===0) {
         let roleList: any;
        let data = localStorage.getItem('role');
        const arrayvalue = data.split(',')
        console.info(arrayvalue.length, arrayvalue);
        let str = '';
        for (let i = 0; i < arrayvalue.length; i++) {
          if (str==='') {
            str = "'" + arrayvalue[i] + "'"
          } else {
            str += ',' + "'" + arrayvalue[i] + "'";
          }
          roleList = str;
        }
        const body = {
          security_grp_nm: roleList
        }
        const userpreviliagedata=localStorage.getItem('userpreviliagedata')

        if (userpreviliagedata && userpreviliagedata.length!=0 && userpreviliagedata!="") {
        this.globalrole.changeMessage(JSON.parse(userpreviliagedata))
            this.userroleAccessfn();
            this.accessEnable = true;
            resolve(true);
          }
        else {
          console.info(this.roleAccess)
          this.roleAccess = []
          this.errorMessage = "User does not have permission to perform 'read' operation";
          this.accessEnable = false;
          this.SpinnerService.hide();
        }
      }
      console.info(this.roleAccess);
    })
  }

}
